import React from 'react'
import styled from 'styled-components'
import ICase from 'types/ICase'
import { ISumpSlide } from 'types/ISumpSlide'

import SumpActions from './SumpActions'
import SumpNavigation from './SumpNavigation'

const StyledTableTopActions = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  & > * {
    margin-right: 8px;
  }
`

type Props = {
  /** Активный тип выбранного сладйа */
  activeTab: SUMP_TAB
  /** Массив выделленых строк */
  selectedRows: ISumpSlide[]
  /** Обновление массива выделенных строк для проброса наверх */
  setSelectedRows: (rows: ISumpSlide[]) => void
  /** Обновление массива выделенных ключей строк для проброса наверх */
  setSelectedRowKeys: (Keys: React.Key[]) => void
  /** Состояние общего поиска в отстоянике */
  isSearching: boolean
  /** Состояние отображения дубликатов в отстоянике */
  isDuplicateShow: boolean
  /** Функция для проброса стейта наверх. После выделения строки пробрасываем тип выделенной ячейки для отображения экшенов */
  updateSelectedTypeTab: (type?: SUMP_TAB) => void
}

export type SUMP_TAB =
  | 'NOT_ATTACHED_TO_CASE'
  | 'ATTACHED_TO_CASE'
  | 'DELETED'
  | 'AWAITING_CASE'
  | 'SEARCH'
  | 'DUPLICATE'

export type AttachSubmitDataType = {
  slides: number[]
  case?: ICase
}

/**
 * Компонент для отображения навигации и экшенов для слайдов остойника
 */
const SumpTopActions = ({
  activeTab,
  isDuplicateShow,
  isSearching,
  selectedRows,
  setSelectedRowKeys,
  setSelectedRows,
  updateSelectedTypeTab,
}: Props) => (
  <StyledTableTopActions>
    {selectedRows.length > 0 ? (
      <SumpActions
        activeTab={activeTab}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setSelectedRowKeys={setSelectedRowKeys}
        updateSelectedTypeTab={updateSelectedTypeTab}
      />
    ) : (
      !(isSearching || isDuplicateShow) && <SumpNavigation />
    )}
  </StyledTableTopActions>
)

export default SumpTopActions
