import { useSlideQuery } from 'entities/slide'
import { View } from 'ol'
import { getWidth } from 'ol/extent'
import TileLayer from 'ol/layer/Tile'
import { useViewerMapParams } from 'pages/viewer/lib/common/MapsProvider'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useEventBusProvided } from 'shared/lib/EventBus'
import { getSlideMppx } from 'shared/lib/metadata'
import styled from 'styled-components/macro'
import { IMapOl } from 'types/IMapOl'
import ISlideLayerConfig from 'types/ISlideLayerConfig'
import TViewerId from 'types/TViewerId'
import { slideMapViewSlice } from 'viewer/map'
import {
  INITIAL_SIZES,
  MAX_HEIGHT_MINIMAP_RATIO,
  MAX_MINIMAP_SIZE,
  MIN_MINIMAP_SIZE,
  MINIMAP_LONG_SIDE_STORAGE_KEY,
} from 'viewer/tools/ui/lib/constans'

import OverviewExt from './Overview'

type Props = {
  /** map - объект карты */
  map: IMapOl
  /** slideLayerConfig - параметры слайда в текущем вьювере */
  slideLayerConfig: ISlideLayerConfig
  /** viewerId - id вьювера */
  viewerId: TViewerId
  /** slideId - id слайда в текущем вьювере */
  slideId: number
  /** initialViewedFeatures - фичи поля зрения */
  initialViewedFeatures?: any
  /** controllViewCallback - колбэк для управления фичей поля зрения */
  controllViewCallback?: (data: any) => void
  /** setBackGroundSizes - установка параметров блока подложки миникарты */
  setBackGroundSizes: (a: number[]) => void
  /** mapSizes - параметры миникарты */
  mapSizes: number[]
  /** setMapSizes - установка параметров миникарты */
  setMapSizes: (a: number[]) => void
}

const OverviewMapWrapper = styled.div`
  width: 100%;
  height: 100%;

  /* TODO: Если добавим новые слои, добавить id canvas */
  .ol-layer:last-child canvas {
    filter: url(#minimap) !important;
  }
`

const OverviewMap = ({
  controllViewCallback,
  initialViewedFeatures,
  map,
  mapSizes,
  setBackGroundSizes,
  setMapSizes,
  slideLayerConfig,
  viewerId,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const overviewRef = useRef<OverviewExt>()
  const dispatch = useDispatch()
  const bus = useEventBusProvided()
  const { caseId, slideId, source } = useViewerIdSlideState(viewerId)
  const { data: slide } = useSlideQuery({ caseId, slideId, source })
  const origViewParams = useViewerMapParams(viewerId)
  const mppx = getSlideMppx(slide)
  const updateOverviewMapSize = () => overviewRef.current?.getOverviewMap().updateSize()

  useEffect(() => {
    if (!slideLayerConfig) return
    // контейнер рабочей области
    const workSpaceContainer =
      containerRef?.current?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement
    const { height: workSpaceHeight = 0 } = workSpaceContainer?.getBoundingClientRect() || {}
    // ширина большого слайда
    const slideWidth = slideLayerConfig.options.size[0]
    // высота большого слайда
    const slideHeight = slideLayerConfig.options.size[1]
    // высота миникарты из локалсторейджа для вертикального слайда
    let localLongSideSize = Number(localStorage.getItem(MINIMAP_LONG_SIDE_STORAGE_KEY))
    // отношения высоты к ширене большого слайда (для вертикальных всегда больше 1)
    const xyFactor = slideHeight / slideWidth
    // свойство для вертикального слайда
    const isVertical = xyFactor > 1
    // свойство для горизонтального слайда
    const isHorizontal = xyFactor < 1
    // свойство для квадратного слайда
    const isSquare = xyFactor === 1
    // расчитанная высота миникарты для вертикального слайда, когда нет запомененых параметров в локалсторейдже
    const xyFactorHeight = isVertical && !!localLongSideSize ? localLongSideSize : mapSizes[0] * xyFactor
    // признак для применения ширины из локлсторейджа
    const hasLocalHorizontalWidth = (isHorizontal || isSquare) && !!localLongSideSize
    // вертикальная высота миникарты с учетом локалсторейджа
    let height =
      (isVertical || isSquare) && localLongSideSize
        ? localLongSideSize
        : workSpaceHeight && xyFactorHeight > workSpaceHeight / MAX_HEIGHT_MINIMAP_RATIO
        ? workSpaceHeight / MAX_HEIGHT_MINIMAP_RATIO
        : xyFactorHeight

    // если горизонтальный слайд с сохарненной сторонной
    if (hasLocalHorizontalWidth) {
      // пересчитаем высоту для сохраненной ширины горизонтального слайда
      height = xyFactor * localLongSideSize
    }
    // модифицироанная ширина миникарты с учетом пропорций для вертикальных слайдов
    let modifiedMapWith = height ? height / xyFactor : INITIAL_SIZES[0]
    // модифицироанная ширина бекграунда с учетом пропорций для вертикальных слайдов
    let modifiedBackGroundWith = height ? height / xyFactor : mapSizes[0]

    // обработка для подгона под минимальные размеры и перерасчета размеров
    if (modifiedMapWith < MIN_MINIMAP_SIZE) {
      modifiedMapWith = MIN_MINIMAP_SIZE
      modifiedBackGroundWith = MIN_MINIMAP_SIZE
      height = MIN_MINIMAP_SIZE * xyFactor
    } else if (height < MIN_MINIMAP_SIZE) {
      height = MIN_MINIMAP_SIZE
      if (hasLocalHorizontalWidth && localLongSideSize < MAX_MINIMAP_SIZE) {
        localLongSideSize = MIN_MINIMAP_SIZE / xyFactor
      } else {
        modifiedMapWith = MIN_MINIMAP_SIZE / xyFactor
        modifiedBackGroundWith = MIN_MINIMAP_SIZE / xyFactor
      }
    }

    setMapSizes([hasLocalHorizontalWidth ? localLongSideSize : modifiedMapWith, height])
    setBackGroundSizes([hasLocalHorizontalWidth ? localLongSideSize : modifiedBackGroundWith, height])
  }, [slideLayerConfig])

  const updateViewSize = (control: OverviewExt) => {
    const overviewMap = control.getOverviewMap()
    const mapView = overviewMap.getView()

    overviewMap.setSize(mapSizes as ol.Size)

    const maxRes = getWidth(slideLayerConfig.source.getTileGrid().getExtent()) / overviewMap.getSize()[0]
    const mapRes = mapView.getZoomForResolution(maxRes)
    mapView.setMinZoom(mapRes)
    mapView.setMaxZoom(mapRes)

    control.setMap(map as any)
    const { size } = slideLayerConfig.options
    overviewMap.getView().setCenter([size[0] / 2, size[1] / -2])
  }

  useEffect(() => {
    if (containerRef.current === null || !map) {
      return
    }

    const tileLayer = new TileLayer({ source: slideLayerConfig.source })

    const newControl = new OverviewExt({
      controllViewCallback,
      id: viewerId,
      layers: [tileLayer],
      mppx: mppx,
      slide,
      target: containerRef.current,
      view: new View({ projection: 'EPSG:3857' }),
      viewInitialZoom: origViewParams?.origZoom,
    })

    const overviewMap = newControl?.getOverviewMap()

    dispatch(
      slideMapViewSlice.actions.setRotation({
        rotation: map.getView().getRotation(),
        slideId,
        viewerId,
      }),
    )

    updateViewSize(newControl)

    overviewRef.current = newControl
    newControl.afterChangeCenter = () => bus.$emit('afterMapCenter', viewerId)

    if (overviewRef.current !== null) {
      overviewMap.un('postrender', updateOverviewMapSize)
    }

    // фиксим баг с неотображением миникарты при первой загрузке (обновляем layout)
    overviewMap.on('postrender', updateOverviewMapSize)

    return () => {
      // @ts-ignore
      newControl.setMap(null)
      if (overviewRef.current !== null) {
        newControl.afterChangeCenter = null
        map.removeControl(overviewRef.current as any)
      }
      overviewMap.un('postrender', updateOverviewMapSize)
    }
  }, [map, slideLayerConfig, containerRef.current, mapSizes])

  useEffect(() => {
    if (overviewRef.current && initialViewedFeatures) {
      overviewRef.current?.setViewedFeatures(initialViewedFeatures)
    }
  }, [initialViewedFeatures, overviewRef.current])

  return (
    <OverviewMapWrapper ref={containerRef} id="non-context">
      <svg xmlns="http://www.w3.org/2000/svg">
        <filter id={`minimap`}>
          <feColorMatrix
            type="matrix"
            values={`
                1 0 0 0 0
                0 1 0 0 0
                0 0 1 0 0
                0 0 0 1 0`}
          />
        </filter>
      </svg>
    </OverviewMapWrapper>
  )
}

export default OverviewMap
