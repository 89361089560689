import ICase, { ICaseRelation } from 'types/ICase'

import ct_1 from './images/ivanova-ct-1.png'
import ct_2 from './images/ivanova-ct-2.png'

const KT_CASE_IVANOVA: ICase = {
  caseId: 999999902,
  casePathologicalInfo: {
    casePathologicalInfoId: 1,
    diagnosticProcedureType: { code: '2', id: 1, name: 'CT' },
    sites: [
      {
        container: null,
        site: {
          code: '50',
          id: 113,
          name: 'МОЛОЧНАЯ ЖЕЛЕЗА',
        },
      },
    ],
  },
  caseRadiologicalInfo: {
    description: '',
    site: '',
  },
  caseType: 'PATHOLOGICAL',
  commentInfo: {
    lastReadCommentId: 0,
    unreadCount: 0,
  },
  createdAt: '2021-01-29T11:21:39.791',
  deleted: false,
  deletedAt: null,
  description:
    'МРТ 29.12.2020 - Состояние после секторальной билатеральной резекции по поводу кистозного ФАМ от 1996г. ',
  favoriteLists: [],
  materialTakeoutDate: '2021-01-29T11:00:00',
  modalities: [],
  name: 'КТ00875768',
  ohivPath: '/ohiv/viewer/1.3.12.2.1107.5.6.1.69110.30000021012906445414000000010',
  patient: {
    birthDate: '1976-05-27',
    fullname: 'Иванова Марина Владимировна',
    sex: 'FEMALE',
  },
  relation: ICaseRelation.OWNER,
  siteSpecificMetadata: {
    gleasonGroupCalculated: 5,
    type: 'PROSTATE_CANCER_METADATA',
  },
  slideStats: {
    referencesCount: 0,
    slidesCount: 0,
  },
  slides: [
    {
      caseId: 999999902,
      deleted: false,
      deletedAt: null,
      groupType: 'MICRO',
      iiif2Url:
        'https://cantaloupe-hdd.test.onecell.ru/iiif/2/ca85bfa13b89eef062f59f0867ee5657%2F4329%2F216%2Ftest-stage-slides%2F61%2F2%2Fcase%2F04a93be6-1695-4faf-a95c-b3a5a318bd30.vips.tiff/info.json',
      searchModels: [],
      slideId: 9999999021,
      slideMetadata: {
        caseSpecificMetadata: {
          gleasonGroup: 5,
          gleasonPatternPrimary: 5,
          gleasonPatternSecondary: 4,
          pattern2Area: {
            GLEASON4: 155.0,
            GLEASON5: 682.0,
            OTHER: 1.988905e7,
          },
          result: {
            GLEASON4: {
              classId: 3,
              relativeValue: 0.1852,
            },
            GLEASON5: {
              classId: 4,
              relativeValue: 0.8148,
            },
          },
          type: 'PROSTATE_CANCER_METADATA',
        },
        commonMetadata: {
          caption: 'OHIV-1.3.6.1.4.1.14519-1',
          mppX: 0.2498,
          objectivePower: 40,
          segmentationStatus: 'AVAILABLE',
        },
      },
      state: 'AVAILABLE',
      thumbnails: {
        large: ct_1,
        medium: ct_1,
        small: ct_1,
      },
    },
    {
      caseId: 999999902,
      deleted: false,
      deletedAt: null,
      groupType: 'MICRO',
      iiif2Url:
        'https://cantaloupe-hdd.test.onecell.ru/iiif/2/ca85bfa13b89eef062f59f0867ee5657%2F4329%2F216%2Ftest-stage-slides%2F61%2F2%2Fcase%2F04a93be6-1695-4faf-a95c-b3a5a318bd30.vips.tiff/info.json',
      searchModels: [],
      slideId: 9999999022,
      slideMetadata: {
        caseSpecificMetadata: {
          gleasonGroup: 5,
          gleasonPatternPrimary: 5,
          gleasonPatternSecondary: 4,
          pattern2Area: {
            GLEASON4: 155.0,
            GLEASON5: 682.0,
            OTHER: 1.988905e7,
          },
          result: {
            GLEASON4: {
              classId: 3,
              relativeValue: 0.1852,
            },
            GLEASON5: {
              classId: 4,
              relativeValue: 0.8148,
            },
          },
          type: 'PROSTATE_CANCER_METADATA',
        },
        commonMetadata: {
          caption: 'OHIV-1.3.6.1.4.1.14519-2',
          mppX: 0.2498,
          objectivePower: 40,
          segmentationStatus: 'AVAILABLE',
        },
      },
      state: 'AVAILABLE',
      thumbnails: {
        large: ct_2,
        medium: ct_2,
        small: ct_2,
      },
    },
    // {
    //   caseId: 999999902,
    //   slideId: 99999993,
    //   state: 'AVAILABLE',
    //   slideMetadata: {
    //     commonMetadata: {
    //       mppX: 0.2498,
    //       objectivePower: 40,
    //       caption: 'OHIV-1.3.6.1.4.1.14519-3',
    //       isSegmentationAvailable: 'AVAILABLE',
    //       isSearchAvailable: 'AVAILABLE',
    //     },
    //     caseSpecificMetadata: {
    //       result: {
    //         GLEASON4: {
    //           relativeValue: 0.1852,
    //           classId: 3,
    //         },
    //         GLEASON5: {
    //           relativeValue: 0.8148,
    //           classId: 4,
    //         },
    //       },
    //       gleasonPatternPrimary: 5,
    //       gleasonPatternSecondary: 4,
    //       gleasonGroup: 5,
    //       pattern2Area: {
    //         OTHER: 1.988905e7,
    //         GLEASON4: 155.0,
    //         GLEASON5: 682.0,
    //       },
    //       type: 'PROSTATE_CANCER_METADATA',
    //     },
    //   },
    //   thumbnails: {
    //     large: ohiv_3,
    //     small: ohiv_3,
    //     medium: ohiv_3,
    //   },
    //   deletedAt: null,
    //   iiif2Url:
    //     'https://cantaloupe-hdd.test.onecell.ru/iiif/2/ca85bfa13b89eef062f59f0867ee5657%2F4329%2F216%2Ftest-stage-slides%2F61%2F2%2Fcase%2F04a93be6-1695-4faf-a95c-b3a5a318bd30.vips.tiff/info.json',
    //   deleted: false,
    // },
    // {
    //   caseId: 999999902,
    //   slideId: 99999994,
    //   state: 'AVAILABLE',
    //   slideMetadata: {
    //     commonMetadata: {
    //       mppX: 0.2498,
    //       objectivePower: 40,
    //       caption: 'OHIV-1.3.6.1.4.1.14519-4',
    //       isSegmentationAvailable: 'AVAILABLE',
    //       isSearchAvailable: 'AVAILABLE',
    //     },
    //     caseSpecificMetadata: {
    //       result: {
    //         GLEASON4: {
    //           relativeValue: 0.1852,
    //           classId: 3,
    //         },
    //         GLEASON5: {
    //           relativeValue: 0.8148,
    //           classId: 4,
    //         },
    //       },
    //       gleasonPatternPrimary: 5,
    //       gleasonPatternSecondary: 4,
    //       gleasonGroup: 5,
    //       pattern2Area: {
    //         OTHER: 1.988905e7,
    //         GLEASON4: 155.0,
    //         GLEASON5: 682.0,
    //       },
    //       type: 'PROSTATE_CANCER_METADATA',
    //     },
    //   },
    //   thumbnails: {
    //     large: ohiv_4,
    //     small: ohiv_4,
    //     medium: ohiv_4,
    //   },
    //   deletedAt: null,
    //   iiif2Url:
    //     'https://cantaloupe-hdd.test.onecell.ru/iiif/2/ca85bfa13b89eef062f59f0867ee5657%2F4329%2F216%2Ftest-stage-slides%2F61%2F2%2Fcase%2F04a93be6-1695-4faf-a95c-b3a5a318bd30.vips.tiff/info.json',
    //   deleted: false,
    // },
  ],
  source: 'PLATFORM',
  status: 'OPEN',
  updatedAt: '2021-01-29T21:42:39.736',
  user: {
    email: 'test+spectre@onecell.ai',
    fullname: 'Кирилл Кошелев',
    userId: 51,
  },
}

export default KT_CASE_IVANOVA
