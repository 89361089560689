import db from 'app/indexedDB'
import { useLiveQuery } from 'dexie-react-hooks'
import { TDict } from 'features/dictionaries'
import { DictionaryNS } from 'features/dictionaries/api/service'
import { useCallback, useMemo } from 'react'
import { localizeDictionary } from 'shared/lib/common'
import i18next from 'shared/lib/i18n/i18n'
import { SelectElement } from 'shared/ui/kit'
import { DictionaryItem } from 'types/IDictionary'

const t = i18next.t

type Props = {
  onChange?: (value?: DictionaryItem) => void
  value?: DictionaryItem
  placeholder?: string
}

const DiagnosticProcedureTypeSelectElement = ({ onChange, placeholder = t('Не выбран'), value, ...props }: Props) => {
  const dictionary = useLiveQuery(async () => {
    //@ts-ignore
    const dpt = await db.dictionaries.where('id').equals('DIAGNOSTIC_PROCEDURE_TYPE').toArray()
    if (!dpt.length) return null
    return dpt[0].data
  }) as TDict

  const { data, updatedAt } = { ...dictionary }
  const dictionaryLocales: DictionaryItem[] = localizeDictionary(data, DictionaryNS.DIAGNOSTIC_PROCEDURE_TYPE)

  const createOptions = useMemo(
    () =>
      dictionaryLocales?.map((it) => ({
        label: it.name,
        value: it.id,
      })),
    [updatedAt, dictionaryLocales],
  )

  const onSelect = useCallback(
    (id: number) => {
      if (dictionaryLocales) {
        const procedureType = dictionaryLocales.find((it) => it.id === id)
        if (onChange && procedureType) {
          onChange(procedureType)
        }
      }
    },
    [updatedAt, dictionaryLocales],
  )

  return (
    <SelectElement value={value?.id} onChange={onSelect} options={createOptions} placeholder={placeholder} {...props} />
  )
}

export default DiagnosticProcedureTypeSelectElement
