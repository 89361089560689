import { Menu } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox'
import { EAllCasesTabType, EDistributionTabType, EMyCasesTabType } from 'features/cases-management/types/ECaseTableType'
import { ECaseStage } from 'features/cases-management/ui/cases-table/modal/StatusModal'
import i18next from 'i18next'
import { FilterQueryKey, FilterType, ISubMenu, ISubMenuItem } from 'pages/cases-new/lib/common'
import React from 'react'
import { CheckboxElement } from 'shared/ui/kit'
import { ECaseStageQuery } from 'types/ICase'

type TFilterProps = {
  handleFilterChange: (item: ISubMenuItem, subMenu: ISubMenu) => (e: CheckboxChangeEvent) => void
  isFilterActive: (itemKey: string) => boolean
  currentTab: EAllCasesTabType | EDistributionTabType | EMyCasesTabType
  /** Доступно ли распределние */
  isCaseRouting?: boolean
}

// eslint-disable-next-line import/no-named-as-default-member
const t = i18next.t

/** Фильтры статусов кейсов */
export const caseStagesFilters = ({ currentTab, handleFilterChange, isCaseRouting, isFilterActive }: TFilterProps) => {
  const subMenu: ISubMenu = {
    filterType: FilterType.CHECKBOX,
    items: [],
    key: FilterQueryKey.STAGE,
    title: t('Статус'),
  }
  /** Массив для которых доступен статус для фильтрации*/
  /** Открыт */
  const open = [
    EDistributionTabType.NOT_COMPLETED,
    EMyCasesTabType.INCOMING,
    EMyCasesTabType.FAVORITES,
    EAllCasesTabType.ALL_STATUSES,
    EAllCasesTabType.OPENED,
    EAllCasesTabType.DELETED,
  ]
  /** Для caseRouting=false статус open доступен на доп вкладке */
  !isCaseRouting && open.push(EMyCasesTabType.ASSIGNED_TO_ME)
  /** Ожидает слайды  */
  const waitingSlides = [
    EDistributionTabType.NOT_COMPLETED,
    EDistributionTabType.NOT_ASSIGNED,
    EMyCasesTabType.INCOMING,
    EMyCasesTabType.FAVORITES,
    EAllCasesTabType.DELETED,
  ]
  /** На распределении */
  const onDistribution = [
    EDistributionTabType.NOT_COMPLETED,
    EDistributionTabType.NOT_ASSIGNED,
    EMyCasesTabType.INCOMING,
    EMyCasesTabType.FAVORITES,
    EAllCasesTabType.DELETED,
  ]
  /** Перерасп-е */
  const onDistributionDeclined = [
    EDistributionTabType.NOT_COMPLETED,
    EDistributionTabType.NOT_ASSIGNED,
    EDistributionTabType.DECLINED,
    EMyCasesTabType.INCOMING,
    EMyCasesTabType.FAVORITES,
    EAllCasesTabType.DELETED,
  ]
  /** Распределен */
  const assigned = [
    EDistributionTabType.NOT_COMPLETED,
    EDistributionTabType.ASSIGNED,
    EMyCasesTabType.ASSIGNED_TO_ME,
    EMyCasesTabType.INCOMING,
    EMyCasesTabType.FAVORITES,
    EAllCasesTabType.ALL_STATUSES,
    EAllCasesTabType.OPENED,
    EAllCasesTabType.DELETED,
  ]
  /** Завершен/подписан */
  const completed = [
    EMyCasesTabType.INCOMING,
    EMyCasesTabType.FAVORITES,
    EAllCasesTabType.ALL_STATUSES,
    EAllCasesTabType.COMPLETED,
    EAllCasesTabType.DELETED,
  ]

  open.includes(currentTab) &&
    subMenu.items.push({
      key: `${FilterQueryKey.STAGE}:${ECaseStage.OPEN}`,
      label: ECaseStage.OPEN,
      queryValue: ECaseStageQuery.OPEN,
    })

  waitingSlides.includes(currentTab as EDistributionTabType | EMyCasesTabType) &&
    subMenu.items.push({
      key: `${FilterQueryKey.STAGE}:${ECaseStage.WAITING_SLIDES}`,
      label: ECaseStage.WAITING_SLIDES,
      queryValue: ECaseStageQuery.WAITING_SLIDES,
    })

  onDistribution.includes(currentTab as EDistributionTabType | EMyCasesTabType) &&
    subMenu.items.push({
      key: `${FilterQueryKey.STAGE}:${ECaseStage.ON_DISTRIBUTION}`,
      label: ECaseStage.ON_DISTRIBUTION,
      queryValue: ECaseStageQuery.ON_DISTRIBUTION,
    })

  onDistributionDeclined.includes(currentTab as EDistributionTabType | EMyCasesTabType) &&
    subMenu.items.push({
      key: `${FilterQueryKey.STAGE}:${ECaseStage.ON_DISTRIBUTION_DECLINED}`,
      label: ECaseStage.ON_DISTRIBUTION_DECLINED,
      queryValue: ECaseStageQuery.ON_DISTRIBUTION_DECLINED,
    })

  assigned.includes(currentTab) &&
    subMenu.items.push({
      key: `${FilterQueryKey.STAGE}:${ECaseStage.ASSIGNED}`,
      label: ECaseStage.ASSIGNED,
      queryValue: ECaseStageQuery.ASSIGNED,
    })

  completed.includes(currentTab as EAllCasesTabType | EMyCasesTabType) &&
    subMenu.items.push({
      key: `${FilterQueryKey.STAGE}:${ECaseStage.COMPLETED}`,
      label: ECaseStage.COMPLETED,
      queryValue: ECaseStageQuery.COMPLETED,
    })

  return subMenu.items.map((item) => (
    <Menu.Item key={item.key} className="cases-filter-submenu-item">
      {/** Обёртка для предотвращения закрытия саб меню при клике */}
      <div onClick={(e) => e.stopPropagation()}>
        <CheckboxElement
          style={{ alignItems: 'center' }}
          onChange={handleFilterChange(item, subMenu)}
          checked={isFilterActive(item.key)}
        >
          {t(item.label)}
        </CheckboxElement>
      </div>
    </Menu.Item>
  ))
}
