import { PaginatedSampFilters } from 'features/sump'
import {
  ATTACHED_TO_CASE_TAB,
  AWAITING_CASE_TAB,
  DELETED_TAB,
  NOT_ATTACHED_TO_CASE_TAB,
  SEARCH_KEY,
} from 'pages/sump/lib/constants'
import { SUMP_TAB } from 'pages/sump/ui/SumpTopActions'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { useDispatch } from 'react-redux'

type args = {
  prevFilters: PaginatedSampFilters
  listType: 'AWAITING' | 'ERRORS' | 'FIXED' | 'BASKET' | 'SEARCH' | 'DUPLICATE'
}

const KEYS = {
  AWAITING: AWAITING_CASE_TAB,
  BASKET: DELETED_TAB,
  ERRORS: NOT_ATTACHED_TO_CASE_TAB,
  FIXED: ATTACHED_TO_CASE_TAB,
  SEARCH: SEARCH_KEY,
}

export const useListOnChange = ({ listType, prevFilters }: args) => {
  const dispatch = useDispatch()
  if (listType === 'DUPLICATE') return
  const KEY = KEYS[listType]

  const onChange = (pagination: any, filters: any, sorter: any) => {
    const awaitingPayload = {
      attachedToCaseByUserIds: filters.attached_to_case_by_user
        ? filters.attached_to_case_by_user.join()
        : filters.attached_to_case_by_user,
      attachedToCaseDateFrom:
        filters.attached_to_case_at && filters.attached_to_case_at[0] ? filters.attached_to_case_at[0] : undefined,
      attachedToCaseDateTo:
        filters.attached_to_case_at && filters.attached_to_case_at[1] ? filters.attached_to_case_at[1] : undefined,
    }
    const deletedPayload = {
      deletedByUserIds: filters.deleted_by_user ? filters.deleted_by_user.join() : filters.deleted_by_user,
      deletedDateFrom: filters.deleted_at && filters.deleted_at[0] ? filters.deleted_at[0] : undefined,
      deletedDateTo: filters.deleted_at && filters.deleted_at[1] ? filters.deleted_at[1] : undefined,
    }

    const payloads = {
      AWAITING: awaitingPayload,
      BASKET: deletedPayload,
      ERRORS: {},
      FIXED: awaitingPayload,
      SEARCH: {},
    }

    dispatch(
      viewerPageSlice.actions.setSumpFilters({
        filters: {
          ...prevFilters,
          ascDirection: sorter.order === 'ascend' ? true : sorter.order === 'descend' ? false : null,
          createdDateFrom: filters.created_at && filters.created_at[0] ? filters.created_at[0] : undefined,
          createdDateTo: filters.created_at && filters.created_at[1] ? filters.created_at[1] : undefined,
          sortByType: sorter.column ? sorter.columnKey.toUpperCase() : null,
          uploadedFileAttachmentStates: filters.state ? filters.state.join() : null,
          uploadedFileTypes: filters.file_type ? filters.file_type.join() : null,
          ...(payloads[listType] || {}),
        },
        key: KEY as SUMP_TAB,
      }),
    )
  }

  return onChange
}
