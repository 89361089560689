import { ThemeContext } from 'app/styled/ThemeProvider'
import { FC, memo, useContext } from 'react'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { IconElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components'

type TCaseNameProps = {
  /** Архивный случай? */
  isArchive: boolean
  caseName: string
  countDone: number
  countTotal: number
  key: React.Key
}

export const CaseName: FC<TCaseNameProps> = memo(({ caseName, countDone, countTotal, isArchive, key }) => {
  const { isLisMode } = useSettingsAndUserRoles()
  const colorTheme = useContext(ThemeContext)

  return (
    <CaseNameContainer key={key} data-testid={'management-table-row'}>
      <TextElement ellipsis>{caseName}</TextElement>
      <CaseCountWrapper done={!isLisMode || countDone === countTotal} theme={colorTheme.theme}>
        {countDone} {isLisMode ? `/ ${countTotal}` : ''}
      </CaseCountWrapper>

      {isArchive && (
        <IconElement
          name={'archiveSmall2'}
          style={{ color: 'var(--color-text-3)', flexShrink: 0, height: '11px', marginLeft: '1px', width: '14px' }}
        />
      )}
    </CaseNameContainer>
  )
})

const CaseNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const CaseCountWrapper = styled.div<{ done: boolean; theme: string }>`
  padding: 2px 4px;
  display: flex;
  align-items: center;
  border: 1px solid var(--color-bg-3);
  border-radius: 2px;
  background: ${(props) => (props.done ? 'var(--color-bg-3)' : 'var(--color-red)')};
  font-size: 10px;
  font-weight: ${(props) => (props.theme === 'dark' ? '600' : '500')};
  line-height: 12px;
  letter-spacing: 0;
  color: ${(props) =>
    props.done ? 'var(--color-text-1)' : props.theme === 'dark' ? 'var(--color-black)' : 'var(--color-white)'};
  white-space: nowrap;
`
