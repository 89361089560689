import { chatOpen } from 'features/support-chat/lib/helper'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconElement, TooltipElement } from 'shared/ui/kit'
import { MenuItem } from 'shared/ui/navigation/MenuItem'
import styled from 'styled-components/macro'

export const SupportChat = () => {
  const { t } = useTranslation()
  return <MenuItem icon={'support'} path={chatOpen} title={t('Чат с поддержкой')} active={false} />
}

const SupportChatWrapper = styled.div<{ isActive: boolean }>`
  position: absolute;
  bottom: 8px;
  right: 8px;
  cursor: pointer;

  .support-chat {
    color: ${({ isActive }) => (isActive ? 'var(--color-text-1)' : 'var(--color-text-3)')};

    rect {
      fill: var(--color-bg-2);
      stroke: var(--color-border-1);
    }

    &:hover {
      color: var(--color-text-1);
    }
  }
`

export const SupportChatNew = () => {
  const { t } = useTranslation()
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false)

  const handleChatToggle = () => {
    setIsChatOpen((prev) => {
      window?.ReplainAPI?.(isChatOpen ? 'close' : 'open')
      return !prev
    })
  }

  /** Колбек для отслеживания чата replain */
  /** Клиент закрыл чат */
  window.replainSettings.onClientClosedChat = useCallback(() => setIsChatOpen(false), [])

  return (
    <TooltipElement title={t('Чат с поддержкой')} mouseEnterDelay={0.8} placement={'left'}>
      <SupportChatWrapper onClick={handleChatToggle} isActive={isChatOpen}>
        <IconElement className={'support-chat'} name={'supportChat'} size={'xm'} />
      </SupportChatWrapper>
    </TooltipElement>
  )
}
