import { Row } from 'antd'
import { ThemeContext, TMode } from 'app/styled/ThemeProvider'
import { AdaptiveThumbnail } from 'features/thumbnails'
import { memo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageElement, TextElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { Morphology } from 'types/IDictionary'
import { ISelectedBbox, ISimilarSlide } from 'types/ISimilarRegion'
import TViewerId from 'types/TViewerId'

type Props = {
  similar: ISimilarSlide
  activeViewerId: TViewerId
  bbox: ISelectedBbox
  selectedByViewer?: TViewerId
  clickHandler: (similar: ISimilarSlide, bbox: ISelectedBbox) => void
}

const SourceTitle = styled(TextElement)<{ theme: TMode }>`
  position: absolute;
  bottom: 2px;
  right: 2px;
  background: ${({ theme }) => (theme === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)')};
  border-radius: 3px;
  padding: 0px 4px;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`

const Morphologies = ({ morphs }: { morphs?: Morphology[] }) => {
  const { t } = useTranslation()
  if (!morphs) {
    return <TextElement>{t('Здоровая ткань')}</TextElement>
  }
  if (morphs.length === 0) {
    return <TextElement>{t('Опухоль не уточнена')}</TextElement>
  }
  if (morphs.length === 1) {
    return <TextElement>{morphs[0].description}</TextElement>
  }

  const title = (
    <>
      {morphs.map((m) => (
        <TextElement key={m.code}>
          {m.description}
          <br />
        </TextElement>
      ))}
    </>
  )

  return (
    <TooltipElement title={title}>
      <TextElement>{morphs[0].description} ...</TextElement>
    </TooltipElement>
  )
}

const SimilarThumbnail = memo(({ activeViewerId, bbox, clickHandler, selectedByViewer, similar }: Props) => {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const onClick = () => {
    clickHandler(similar, bbox)
  }
  return (
    <>
      <AdaptiveThumbnail
        activeCursor={activeViewerId}
        selectedByViewer={selectedByViewer}
        onClick={onClick}
        footer={
          <div>
            <Morphologies morphs={similar.morphologies} />
            <Row justify="space-between">
              <TextElement type="secondary">{t('Сходство')}</TextElement>
              <TextElement type="secondary">{Math.floor(bbox.score * 100)}%</TextElement>
            </Row>
          </div>
        }
      >
        <SourceTitle theme={theme.theme}>{similar.source === 'ATLAS' ? t('Атлас') : t('Платформа')}</SourceTitle>
        <ImageElement src={bbox.thumbnailUrl} />
      </AdaptiveThumbnail>
    </>
  )
})

export default SimilarThumbnail
