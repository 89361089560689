import { Select } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useState } from 'react'
import { SelectElement, SelectElementProps, SpinElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { DictionaryItem } from 'types/IDictionary'

const { Option } = Select

const SiteLabel = styled.div`
  display: flex;
  column-gap: 4px;
  width: 100%;
  white-space: normal;
  text-overflow: initial;
`

const LoadingContainer = styled.div`
  color: var(--color-text-1);
  width: 100%;
  display: flex;
  gap: 8px;
`

const StyledSpineElement = styled(SpinElement)`
  display: flex;
  margin: auto;
`

type Props = {
  morph?: DictionaryItem
  morphs: DictionaryItem[]
} & Omit<SelectElementProps, 'options' & 'groups'>

const TumorSelectElement = ({ morph, morphs, ...rest }: Props) => {
  const [searchValue, onSearch] = useState('')
  const isLoading = useTypedSelector((state) => state.reports.isDictionaryLoading)

  const value = morph ? `${morph?.code}: ${morph?.name}` : ''

  return (
    <SelectElement
      {...rest}
      onSearch={onSearch}
      searchValue={searchValue}
      showSearch
      filterOption={false}
      onSelect={() => onSearch('')}
      value={value}
    >
      {isLoading && !morphs ? (
        <LoadingContainer>
          <StyledSpineElement />
        </LoadingContainer>
      ) : (
        morphs?.map((morph) => createOption(morph, searchValue))
      )}
    </SelectElement>
  )
}

const createOption = (morph: DictionaryItem, searchValue: string) => {
  if (searchValue && !findQueryInSite(searchValue, morph)) {
    return null
  }
  return (
    <Option value={morph.code} key={morph.code}>
      <SiteLabel>
        <TextElement type="secondary">{morph.code}</TextElement>
        <TextElement ellipsis>{morph.name}</TextElement>
      </SiteLabel>
    </Option>
  )
}

const findQueryInSite = (query: string, site: DictionaryItem) =>
  site.name.toLowerCase().includes(query.toString().toLowerCase()) ||
  site.code.toLowerCase().includes(query.toString().toLowerCase())

export default TumorSelectElement
