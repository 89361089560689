import { useTypedSelector } from 'app/redux/lib/selector'
import { useChangeAnnotationMutation } from 'features/annotations/api/query'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { ISizeInfo } from 'features/annotations/ui/tasks/AnnotationsTasksContainer'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import React, { memo, useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { useQueryClient } from 'react-query'
import AutoSizer from 'react-virtualized-auto-sizer'
import { areEqual, VariableSizeList as List } from 'react-window'
import { QUERY_TYPE } from 'shared/api'
import { useKeyPress, useOS } from 'shared/lib/hooks'
import { getSlideBarcode } from 'shared/lib/metadata'
import { IconElement, SpinElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { AnnotationType, IAnnotation } from 'types/IAnnotations'
import ISlide from 'types/ISlide'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'
import { customTruncateStringWithEllipsis, reduceElementsHeight } from 'viewer/tools'

import PlatformAnnotationListItemContainer from './PlatformAnnotationListItemContainer'

/** Кол-во пикселей для левого паддинга */
const LEFT_PADDING_PIXELS = 6
/** Кол-во пикселей для иконки тоггла и спейса */
const TOGGLE_ICON = 24
const PLATFORM_ANNOTATION_HEIGHT = 56
const PLATFORM_SLIDE_TITLE_HEIGHT = 48
// минимальный размер (применим для скрытых заголовков), чтобы скролл не прыгал
const MIN_PLATFORM_SLIDE_TITLE_HEIGHT = 0.1

const AnnotationListContainer = styled.div`
  padding: 0 8px;
  height: 100%;
`

type Props = {
  annotations: Record<number, IAnnotation[]>
  caseId: number
  slideId: number
}

const DraggableWrapper = styled.div`
  outline: none;
  height: 100%;
`

const StyledCollapseTitle = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 18px 0 14px ${LEFT_PADDING_PIXELS}px;

  & > div {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
`

const reorder = (list: Array<IAnnotation | string>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const useWatchKeyPress = (viewerId: TViewerId) => {
  const viewerDispatch = useViewerDispatch(viewerId)
  const pressed = useKeyPress([`${useOS() === 'MacOS' ? 'Meta' : 'Control'}`, 'Shift'])
  const annotationType = useTypedSelector((state) => state.annotations.annotationType)
  useEffect(() => {
    viewerDispatch(viewerSlice.actions.setAnnotationMultiSelect(annotationType === AnnotationType.POINT || pressed))
  }, [pressed])
}

export const PlatformAnnotationsList = memo(({ annotations, caseId, slideId }: Props) => {
  const queryClient = useQueryClient()
  const { activeViewerId: viewerId } = useViewerPageProvided()
  const viewerDispatch = useViewerDispatch(viewerId)
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const { mutateAsync: editAnnotation } = useChangeAnnotationMutation({ caseId, slideId })

  useWatchKeyPress(viewerId)

  const [isLoading, setIsLoading] = useState(false)
  const [scrollOffset, setScrollOffset] = useState(0)
  type CollapsedSlides = Record<number, boolean>
  const [collapsedSlides, setCollapsedSlides] = useState<CollapsedSlides>(() => {
    const initCollapsedSlides: CollapsedSlides = {}
    Object.keys(annotations).forEach((key: string) => (initCollapsedSlides[+key] = true))
    return initCollapsedSlides
  })

  useEffect(() => {
    setCollapsedSlides((prev) => ({
      ...prev,
      [slideId]: !annotations[slideId] ? !prev[slideId] : true,
    }))
  }, [Object.keys(annotations).length])

  const reversedAnnotations = Object.entries(annotations).reduce((list: Array<IAnnotation | string>, entry) => {
    if (!collapsedSlides[+entry[0]]) return [...list, entry[0]]
    return [...list, entry[0], ...entry[1]]
  }, [])

  useEffect(() => {
    listRef.current?.resetAfterIndex(0)
  }, [reversedAnnotations])

  const ref = useRef<List | null>(null)

  const onDragEnd = async (result: DropResult, provider: ResponderProvided) => {
    const sourceIndex = result.source.index
    const destinationIndex = result.destination?.index
    // dropped outside the list
    if (
      !destinationIndex ||
      !reversedAnnotations ||
      typeof reversedAnnotations[sourceIndex] === 'string' ||
      typeof reversedAnnotations[destinationIndex] === 'string'
    )
      return

    const sourceAnnotation = reversedAnnotations[sourceIndex] as IAnnotation
    const destinationAnnotation = reversedAnnotations[destinationIndex] as IAnnotation
    if (
      sourceAnnotation.slideId !== destinationAnnotation.slideId ||
      slideId !== sourceAnnotation.slideId ||
      slideId !== destinationAnnotation.slideId
    )
      return

    const slideAnnotations = reversedAnnotations
      .filter((el) => typeof el !== 'string' && el.slideId === slideId)
      .reverse()

    const newSourceIndex = slideAnnotations.findIndex(
      (el) => typeof el !== 'string' && el.slideAnnotationId === sourceAnnotation.slideAnnotationId,
    )
    const newDestinationIndex = slideAnnotations.findIndex(
      (el) => typeof el !== 'string' && el.slideAnnotationId === destinationAnnotation.slideAnnotationId,
    )
    const items = reorder(slideAnnotations, newSourceIndex, newDestinationIndex).reverse()
    // @ts-ignore
    ref?.current?.children?.length &&
      // @ts-ignore
      ref?.current?.children[0] &&
      // @ts-ignore
      setScrollOffset(ref?.current?.children[0]?.offsetTop + 82)
    //"smart" update
    await Promise.all(
      items.map(async (item, index) => {
        if (typeof item === 'string') return
        const annotationZindex = item.zindex
        const reorderedZindex = index + 1
        if (annotationZindex !== reorderedZindex) {
          const data = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, item.slideAnnotationId])
          if (data?.data) {
            await editAnnotation({
              ...item,
              data: {
                ...data.data,
              },
              zindex: reorderedZindex,
            })
          }
        }
      }),
    )
  }

  const getItemSize = (index: number) => {
    const item = reversedAnnotations[index]
    if (typeof item === 'string') {
      return annotations && annotations[+item]?.length ? PLATFORM_SLIDE_TITLE_HEIGHT : MIN_PLATFORM_SLIDE_TITLE_HEIGHT
    }
    return PLATFORM_ANNOTATION_HEIGHT
  }

  // ref for scroll
  const listRef = useRef<List<any>>(null)

  useEffect(() => {
    if (!selectedAnnotationsIds || selectedAnnotationsIds.length !== 1) return
    const index = reversedAnnotations?.findIndex((it: any) => it?.slideAnnotationId === selectedAnnotationsIds[0])
    if (index === undefined || !ref.current) return
    listRef.current?.scrollToItem(index, 'smart')
  }, [selectedAnnotationsIds])

  /** Props for CollapseTitle */
  type CollapseHeadProps = {
    /** slideId - id текущего слайда */
    slideId: number
    /** collapsedSlides - "хэш-таблица" с состояниями сверто/развернуто по id слайда */
    collapsedSlides: CollapsedSlides
    /** setCollapsedSlides - колбэк для установки состояния сверто/развернуто по id слайдов */
    setCollapsedSlides: (value: CollapsedSlides) => void
    /** style - стили */
    style: React.CSSProperties
  }
  const CollapseTitle = ({ collapsedSlides, setCollapsedSlides, slideId, style }: CollapseHeadProps) => {
    const queryClient = useQueryClient()
    const slideData = queryClient.getQueryData<ISlide>([QUERY_TYPE.SLIDE, slideId])
    const toggleIsOpen = () => {
      setCollapsedSlides({ ...collapsedSlides, [slideId]: !collapsedSlides[slideId] })
    }
    /** Селектор правой панели */
    const rightPanelEl = document.querySelector('.rightPanel') as HTMLElement
    /** Селектор блока со списком аннотаций */
    const annotationListContent = (document.querySelector('.annotation-list > div') as HTMLDivElement) || document.body
    /** Суммарная высота элементов правой панели без блока с аннотациями */
    const rightPanelHeightWithoutAnnotationContainer = reduceElementsHeight<HTMLDivElement>([
      '#right-panel-top-divider',
      '#right-panel-bottom-elements',
      '#viewer-right-panel-title',
    ])

    /** Флаг на видимость скроллбара */
    const [hasScrollbar, setHasScrollbar] = useState<boolean>(
      annotationListContent.clientHeight > rightPanelEl.clientHeight - rightPanelHeightWithoutAnnotationContainer,
    )

    useEffect(() => {
      /** Если высота контента с аннотациями больше высоты правой панели, считаем что скроллбар появился*/
      setHasScrollbar(
        annotationListContent.clientHeight > rightPanelEl.clientHeight - rightPanelHeightWithoutAnnotationContainer,
      )
    }, [annotationListContent.clientHeight])

    const filename = getSlideBarcode(slideData)
    const colorCode = slideData?.stain?.code
    const truncatedFilename =
      filename &&
      customTruncateStringWithEllipsis(
        colorCode ? `${colorCode} ${filename}` : filename,
        hasScrollbar,
        rightPanelEl,
        TOGGLE_ICON + LEFT_PADDING_PIXELS,
        LEFT_PADDING_PIXELS,
      )

    return (
      <TooltipElement placement={'left'} title={filename} mouseEnterDelay={0.8}>
        <StyledCollapseTitle style={style} onClick={toggleIsOpen}>
          <IconElement
            name={collapsedSlides[slideId] ? 'sectionIsOpen' : 'sectionIsClose'}
            fill={'#99989F'}
            style={{ minHeight: `${TOGGLE_ICON}px`, minWidth: `${TOGGLE_ICON}px` }}
          />

          <div
            id="slidename"
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {truncatedFilename}
          </div>
        </StyledCollapseTitle>
      </TooltipElement>
    )
  }

  type ListChildComponentProps = {
    index: number
    data: { reversedAnnotations: Array<IAnnotation | string> | undefined }
    style: React.CSSProperties
  }
  const Row = memo((props: ListChildComponentProps) => {
    const { data, index, style } = props
    const annotation = data.reversedAnnotations ? data.reversedAnnotations[index] : undefined
    if (!annotation) {
      return null
    }

    if (typeof annotation === 'string') {
      const slideId = +annotation

      return annotations && annotations[slideId]?.length ? (
        <CollapseTitle
          slideId={slideId}
          collapsedSlides={collapsedSlides}
          setCollapsedSlides={setCollapsedSlides}
          style={style}
        />
      ) : null
    }

    const hoverHandler = (annotationId: number) => {
      viewerDispatch(annotationsSlice.actions.setHoveredAnnotationId(annotationId))
    }
    const leaveHandler = () => {
      viewerDispatch(annotationsSlice.actions.setHoveredAnnotationId())
    }
    return (
      <Draggable draggableId={`${annotation.slideAnnotationId}`} index={index} key={annotation.slideAnnotationId}>
        {(provided) => (
          <DraggableWrapper
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={style}
            onMouseLeave={() => leaveHandler()}
            onMouseEnter={() => hoverHandler(annotation.slideAnnotationId)}
          >
            <AnnotationListContainer>
              <PlatformAnnotationListItemContainer key={annotation.slideAnnotationId} annotation={annotation} />
            </AnnotationListContainer>
          </DraggableWrapper>
        )}
      </Draggable>
    )
  }, areEqual)

  return (
    <DragDropContext
      onDragEnd={(result, provided) => {
        setIsLoading(true)
        onDragEnd(result, provided).finally(() => setIsLoading(false))
      }}
    >
      <Droppable
        droppableId="droppable"
        mode="virtual"
        renderClone={(provided, snapshot, rubric) => {
          const annotation = reversedAnnotations ? reversedAnnotations[rubric.source.index] : undefined
          if (!annotation || typeof annotation === 'string') return <div>no item</div>
          return (
            <DraggableWrapper
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={provided.draggableProps.style}
            >
              <PlatformAnnotationListItemContainer key={annotation.slideAnnotationId} annotation={annotation} />
            </DraggableWrapper>
          )
        }}
      >
        {(droppableProvided, droppableSnapshot) => {
          const itemCount: number = droppableSnapshot.isUsingPlaceholder
            ? (reversedAnnotations?.length || 0) + 1
            : reversedAnnotations?.length || 0

          return (
            <>
              {isLoading && (
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '64px',
                    justifyContent: 'center',
                    padding: '16px 0',
                    position: 'absolute',
                    right: '0px',
                    top: '-58px',
                    width: '64px',
                    zIndex: '2',
                  }}
                >
                  <SpinElement />
                </div>
              )}
              <AutoSizer defaultHeight={100} defaultWidth={1}>
                {({ height, width }: Required<ISizeInfo>) => (
                  <List
                    className="annotation-list"
                    height={height}
                    itemCount={itemCount}
                    itemSize={getItemSize}
                    width={width}
                    ref={listRef}
                    outerRef={ref}
                    innerRef={droppableProvided.innerRef}
                    itemData={{ reversedAnnotations }}
                    initialScrollOffset={scrollOffset}
                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
            </>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
})
