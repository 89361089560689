import { useTypedSelector } from 'app/redux/lib/selector'
import { useTaskSlideResult } from 'entities/tasks/api/query'
import { useAnnotationsByCaseQuery, useAnnotationsQuery } from 'features/annotations/api/query'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { Polygon } from 'ol/geom'
import { selectTasksViewerUrlTaskId } from 'pages/viewer'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useSubscription } from 'processes/stomp'
import { WsResponseAnnotation } from 'processes/stomp/types'
import { useEffect, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { QUERY_TYPE } from 'shared/api'
import { usePrevious } from 'shared/lib/hooks'
import { IAnnotation } from 'types/IAnnotations'
import { IMarkupSlide, IMarkupSlideResult } from 'types/IMarkupSlide'
import { IMarkupTask } from 'types/IMarkupTask'
import TViewerId from 'types/TViewerId'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { getCoordinatesLength, getFeaturesFromGeoJson, isPolygonAnnotation } from 'viewer/map/lib/utils'

import AnnotationsLayer from './AnnotationsLayer'
import { MIN_POLYGON_COORDINATES } from './lib/annotationsDrawHelpers'
import { isObjectsCounting } from './lib/helpers'

type Props = {
  viewerId: TViewerId
  mppX: number
}

const AnnotationsLayerContainer = ({ mppX, viewerId }: Props) => {
  const dispatch = useDispatch()
  const { caseId, slideId } = useViewerIdSlideState(viewerId)
  const workspaceId = useCurrentWorkspaceId()
  const queryClient = useQueryClient()
  const slideResults = queryClient.getQueryData<IMarkupSlideResult[]>([QUERY_TYPE.TASKS_SLIDE, slideId])
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const currentSlidesArr = queryClient.getQueryData<IMarkupSlide[]>([QUERY_TYPE.TASKS_SLIDES, taskId])
  const currSlide = currentSlidesArr?.find((item) => item.slideId === slideId)
  const { data: useTasksSlideResult } = useTaskSlideResult(
    currSlide?.markupTaskId,
    currSlide?.markupSlideId,
    currSlide?.slideId,
  )
  const { data: annotationsIds, refetch } = useAnnotationsQuery(caseId, slideId, slideResults, useTasksSlideResult)
  const { data: annotationsBySlide } = useAnnotationsByCaseQuery(caseId)
  const { annotationsIsVisible, currentAnnotationByClass, currentAnnotationUsers } = useTypedSelector(
    (state) => state.annotations,
  )
  const pathname = useLocation().pathname
  const disableSubscription = pathname.includes('atlas') || pathname.includes('tasks')
  const task = queryClient.getQueryData<IMarkupTask>([QUERY_TYPE.TASKS, taskId])
  const ids = annotationsIds?.ids || []
  const prevIds = usePrevious(ids) || []
  const localStorageAllHiddenName = `isAllAnnotationHidden-${caseId}`
  const isAllAnnotationHidden = !!Number(localStorage.getItem(localStorageAllHiddenName))

  useDeepCompareEffect(() => {
    //  при переключении слайда ids меняются
    const diffIds = ids.filter((id) => !prevIds.includes(id))

    if (!isAllAnnotationHidden) {
      dispatch(annotationsSlice.actions.setAnnotationsIsVisible([...(annotationsIsVisible || []), ...diffIds]))

      ids.forEach((id) => {
        const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, id])
        isObjectsCounting(annotation?.type) && dispatch(annotationsSlice.actions.addAnnotationsToVisible(id))
      })
    }
  }, [ids])

  useEffect(() => {
    if (taskId) {
      refetch().then((res) => {
        dispatch(annotationsSlice.actions.setAnnotationsIsVisible(res.data?.ids || null))
      })
    } else {
      const annotationsBySlideIds = Object.entries(annotationsBySlide || {})
        .flatMap((val) => val[1])
        .map((el) => el?.slideAnnotationId)
      dispatch(
        annotationsSlice.actions.setAnnotationsIsVisible(
          isAllAnnotationHidden ? null : annotationsIsVisible || annotationsBySlideIds,
        ),
      )
    }
  }, [slideId])

  !disableSubscription
    ? useSubscription<WsResponseAnnotation>(
        `/queue/slide/${slideId}/annotation`,
        () => {
          refetch().then((res) => {
            if (
              annotationsIsVisible !== null &&
              annotationsIsVisible?.length &&
              annotationsIsVisible.length === annotationsIds?.ids.length
            ) {
              dispatch(annotationsSlice.actions.setAnnotationsIsVisible(res.data?.ids || null))
            }
          })
          queryClient.invalidateQueries([QUERY_TYPE.ANNOTATION, { caseId }])
        },
        [slideId, annotationsIsVisible],
        workspaceId ? { 'x-oc-workspace-id': String(workspaceId) } : undefined,
      )
    : null

  const annotations = useMemo(
    () =>
      (annotationsIds?.ids
        ?.map((id) => queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, id]))
        .filter((annotation) => {
          if (!annotation?.data?.formattedFeature) return
          const feature = getFeaturesFromGeoJson(annotation.data?.formattedFeature)[0]
          const aClass = feature?.get('class')
          const user = task
            ? task.participants?.find((item) => item.userId === annotation.userId)?.user?.fullname
            : annotation.user
          const isVisibleById = annotationsIsVisible?.includes(annotation.slideAnnotationId)
          const isVisibleByClass = currentAnnotationByClass?.includes(aClass) || !currentAnnotationByClass || !taskId
          const isVisibleByUser =
            !currentAnnotationUsers ||
            !user ||
            currentAnnotationUsers?.includes(user) ||
            currentAnnotationUsers.includes(user + slideId)

          const isPolygon = isPolygonAnnotation(annotation.type)
          const geometry: Polygon = feature.getGeometry()

          if (isPolygon && getCoordinatesLength(geometry) <= MIN_POLYGON_COORDINATES) return false
          return isVisibleById && isVisibleByClass && isVisibleByUser
        }) as IAnnotation[]) || [],
    [annotationsIds?.date, annotationsIsVisible, currentAnnotationByClass, currentAnnotationUsers],
  )
  return <AnnotationsLayer annotations={annotations} mppX={mppX} />
}

export default AnnotationsLayerContainer
