import { Space } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { useCaseQuery } from 'features/cases/api/query'
import { AdaptiveThumbnailContainer } from 'features/thumbnails'
import { useWorkspacesQuery } from 'features/workspace'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Scroll, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'

const Thumbnails = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0 16px 16px;
  background-color: var(--color-bg-2);
  border-left: 1px solid var(--color-border-1);

  & > * {
    padding-right: 16px;
  }
`

const ImagesTitle = styled(TitleElement)`
  padding: 24px 8px 16px;
`

type DashboardThumbnailsProps = {
  caseRecord: ICase
  workspaceId?: string
}

const DashboardThumbnails = ({ caseRecord, workspaceId }: DashboardThumbnailsProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const onClick = (caseId: number, slideId: number) => {
    if (caseRecord.ohivPath) {
      dispatch(push(caseRecord.ohivPath))
    } else {
      dispatch(
        push(`/workspace/${workspaceId}/viewer/${caseId}?slideId=${slideId}`, {
          fromDashboard: true,
        }),
      )
    }
  }
  return (
    <Thumbnails>
      <ImagesTitle level={2}>{t('Изображения')}</ImagesTitle>
      <Scroll>
        <Space direction="vertical" style={{ width: '100%' }}>
          {caseRecord.slides.map((slide) => (
            <AdaptiveThumbnailContainer
              onClick={onClick}
              slideId={slide.slideId}
              caseId={caseRecord.caseId}
              key={slide.slideId}
              splitButton={false}
            />
          ))}
        </Space>
      </Scroll>
    </Thumbnails>
  )
}

const DashboardThumbnailsListContainer = () => {
  const selectedCase = useTypedSelector((state) => state.dashboard.selectedCase)
  const { data: caseRecord } = useCaseQuery({ caseId: selectedCase, source: 'PLATFORM' })
  const { data: workspaces } = useWorkspacesQuery()
  const workspaceId = useCurrentWorkspaceId() + '' || workspaces?.find((workspace) => workspace.personal)?.workspaceId
  return caseRecord ? <DashboardThumbnails caseRecord={caseRecord} workspaceId={workspaceId} /> : null
}

export default DashboardThumbnailsListContainer
