import { TableProps, Tag } from 'antd'
import ResTable from 'pages/atlas/ui/ResTable'
import React, { memo } from 'react'
import styled from 'styled-components'
import { ICaseDTO } from 'types/ICase'

export interface IOverflowWrapper {
  isPanelPreviewVisible: boolean
}

interface ICaseTableProps extends TableProps<ICaseDTO> {
  /** высота панели превью */
  panelPreviewHeight?: number
}

const rowHeight = 44

export const StyledTableWrapper = memo(styled.div<{ panelPreviewHeight?: number; isPanelPreviewVisible?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-dropdown-trigger {
    width: 100%;
    height: ${({ isPanelPreviewVisible, panelPreviewHeight }) =>
      isPanelPreviewVisible ? `calc(100% - ${panelPreviewHeight}px)` : '100%'};
  }

  .ant-modal-body {
    width: 350px !important;
    padding: 10px;
  }

  .ant-spin-nested-loading {
    height: 100%;
    .ant-spin-container {
      height: 100%;
      .ant-table {
        height: 100%;
        .ant-table-container {
          height: 100%;
          .ant-table-body {
            height: calc(100% - ${rowHeight}px);
          }
        }
      }
    }
  }
`)

export const StyledTable = styled<React.FC<ICaseTableProps>>(ResTable)`
  height: 100%;
  width: 100%;
  border: 1px solid var(--color-border-1);
  border-bottom: none;

  table {
    border-radius: 0px;
    border-collapse: collapse;
  }
  tr {
    cursor: pointer;
  }
  th {
    text-transform: uppercase;
  }

  .ant-table-thead > tr > th {
    color: var(--color-text-1);
    background: var(--color-bg-2) !important;
    border-bottom: 1px solid var(--color-border-1);
    padding: 12px 16px;
  }
  .ant-table-thead > tr > th.has-divider {
    position: relative;
  }
  .ant-table-thead > tr > th.has-divider::after {
    // Разделитель колонок, в шапке
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 40%;
    width: 1px;
    background-color: var(--color-bg-4);
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent;
  }

  .ant-table-body {
    transition: max-height 0.25s ease-out;
    overflow-x: hidden;
  }
  .ant-table-tbody > tr.ant-table-row > td {
    border-bottom: 0px solid;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: var(--color-bg-3);
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: var(--color-bg-3);
  }
  .ant-table-tbody > tr.selected-row > td {
    background-color: var(--color-border-1);
  }

  .ant-table-cell {
    padding-left: 16px;
    background: var(--color-bg-2);
    white-space: nowrap;
    overflow: hidden;

    & p {
      margin: 0;
    }
  }

  .ant-table-row {
    height: ${rowHeight}px;
    width: 100% !important;
  }

  .ant-checkbox-inner {
    box-shadow: none;
  }

  .twoRows p {
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .ant-checkbox-wrapper {
    width: 16px;
    height: 16px;
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    color: var(--color-border-1);
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: var(--color-text-1);
  }
`
export const StyledTag = styled(Tag)<{ theme: string }>`
  border-radius: 2px;
  color: ${({ theme }) => (theme === 'dark' ? 'var(--color-black)' : 'var(--color-white)')};
  font-size: 10px;
  font-weight: ${({ theme }) => (theme === 'dark' ? '600' : '500')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const StyledDateItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
