import { useTypedSelector } from 'app/redux/lib/selector'
import { useAvailableFiltersQuery } from 'features/sump'
import ResTable from 'pages/atlas/ui/ResTable'
import { getAvailableFilter, getFileMimeType, getFileTypeText } from 'pages/sump/lib/renderHandlers'
import { FileToPreview } from 'pages/sump/lib/types'
import BarcodeContainer from 'pages/sump/ui/BarcodeContainer'
import DateFilterDropdown from 'pages/sump/ui/DateFilterDropdown'
import RowActions from 'pages/sump/ui/RowActions'
import { SUMP_TAB } from 'pages/sump/ui/SumpTopActions'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FullScreenModalElement, TextElement } from 'shared/ui/kit'
import { PdfViewer } from 'shared/ui/pdf'
import { Column, dateTimeRenderer } from 'shared/ui/table'
import styled from 'styled-components'
import IListOfItems from 'types/api/IListOfItems'
import { IBarcode, ISumpSlide } from 'types/ISumpSlide'
import IUser from 'types/IUser'

import { ColumnProblemText } from './ui/column/ColumnRender'
import { useListFilterRange } from './useListFilterRange'
import { useListOnChange } from './useListOnChange'
import { useSortState } from './useSortState'

const StyledTable = styled(ResTable)`
  .ant-table-container {
    height: 86vh;
    overflow: auto;
  }
`

const FileItem = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 5px 8px 5px 0;
  white-space: nowrap;
  cursor: pointer;

  & > svg {
    color: white;
    flex-shrink: 0;
  }
`

const User: React.FC<{ user: IUser | null }> = ({ user }) => {
  const { t } = useTranslation()
  return <>{user?.fullname ?? t('Автоматически')}</>
}

type Props = {
  selectedRowKeys: React.Key[]
  rowSelection: {
    selectedRows: ISumpSlide[]
    onChange: (newSelectedRowKeys: React.Key[], newSelectedRows: ISumpSlide[]) => void
  }
  filesList: IListOfItems<ISumpSlide> | undefined
  isFetching: boolean
  listType: 'AWAITING' | 'ERRORS' | 'FIXED' | 'BASKET'
  prevFiltersKey: SUMP_TAB
}

const FileList = ({ filesList, isFetching, listType, prevFiltersKey, rowSelection, selectedRowKeys }: Props) => {
  const getDataSource = useCallback(
    () => filesList?.content?.map((item) => ({ ...item, key: item.uploadedFileId })),
    [filesList],
  )
  const prevFilters = useTypedSelector((state) => state.viewerPage.sumpFilters[prevFiltersKey])

  const { data: availableFilters } = useAvailableFiltersQuery(prevFiltersKey)

  const filtersRange = useListFilterRange({ prevFilters, prevFiltersKey })
  const sortOrderState = useSortState(prevFilters)
  const onChange = useListOnChange({ listType, prevFilters })

  const [fileToPreview, setFileToPreview] = useState<FileToPreview>()

  const { t } = useTranslation()

  return (
    <>
      <StyledTable
        tableLayout={'fixed'}
        dataSource={getDataSource()}
        loading={isFetching}
        rowSelection={{
          preserveSelectedRowKeys: true,
          selectedRowKeys,
          type: 'checkbox',
          ...rowSelection,
        }}
        onChange={onChange}
        pagination={false}
      >
        <Column
          title={t('Имя файла')}
          dataIndex="fileName"
          key="fileName"
          render={(value, record: ISumpSlide) => {
            if (record.fileType === 'DOCUMENT') {
              return (
                <FileItem
                  onClick={() => {
                    const mimeType = getFileMimeType(record.fileName)
                    if (record.documentUrl) setFileToPreview({ mimeType, url: record.documentUrl })
                  }}
                >
                  <TextElement style={{ color: 'var(--color-purple-light)' }} ellipsis>
                    {value}
                  </TextElement>
                </FileItem>
              )
            }
            return value
          }}
        />
        {listType === 'FIXED' && <Column title={t('Имя случая')} dataIndex="caseName" key="caseName" />}
        <Column
          title={t('Время загрузки')}
          dataIndex="createdAt"
          key="created_at"
          render={dateTimeRenderer}
          showSorterTooltip={false}
          sorter={true}
          sortOrder={prevFilters.sortByType === 'CREATED_AT' ? sortOrderState : null}
          filterDropdown={({ clearFilters, confirm, selectedKeys, setSelectedKeys }) => (
            <DateFilterDropdown
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              filtersRange={filtersRange}
              filtersRangeColumn={'created_at'}
              clearFilters={clearFilters}
              confirm={confirm}
            />
          )}
          filteredValue={
            prevFilters?.createdDateFrom || prevFilters?.createdDateTo
              ? [prevFilters?.createdDateFrom || false, prevFilters.createdDateTo || false]
              : undefined
          }
        />
        <Column
          title={t('Путь')}
          dataIndex="path"
          key="path"
          showSorterTooltip={false}
          sorter={true}
          sortOrder={prevFilters.sortByType === 'PATH' ? sortOrderState : null}
        />
        {listType === 'BASKET' && (
          <>
            <Column
              title={t('Время переноса')}
              dataIndex="deletedAt"
              key="deleted_at"
              render={dateTimeRenderer}
              width={130}
              showSorterTooltip={false}
              sorter={true}
              sortOrder={prevFilters.sortByType === 'DELETED_AT' ? sortOrderState : null}
              filterDropdown={({ clearFilters, confirm, selectedKeys, setSelectedKeys }) => (
                <DateFilterDropdown
                  setSelectedKeys={setSelectedKeys}
                  selectedKeys={selectedKeys}
                  filtersRange={filtersRange}
                  filtersRangeColumn={'deleted_at'}
                  clearFilters={clearFilters}
                  confirm={confirm}
                />
              )}
              filteredValue={
                prevFilters?.deletedDateFrom || prevFilters?.deletedDateTo
                  ? [prevFilters?.deletedDateFrom || false, prevFilters.deletedDateTo || false]
                  : undefined
              }
            />
            <Column
              title={t('Перенесено')}
              dataIndex="deletedByUser"
              key="deleted_by_user"
              showSorterTooltip={false}
              filters={getAvailableFilter('users', availableFilters)}
              filteredValue={prevFilters.deletedByUserIds ? [prevFilters.deletedByUserIds].join().split(',') : null}
              render={(user: IUser) => <User user={user} />}
              sorter={true}
              sortOrder={prevFilters.sortByType === 'DELETED_BY_USER' ? sortOrderState : null}
            />
          </>
        )}
        <Column
          title={t('Штрихкод')}
          dataIndex="barcodes"
          render={(barcodes: IBarcode[]) =>
            barcodes ? barcodes.map((item) => <BarcodeContainer key={item.barcode} barcode={item} />) : '-'
          }
        />

        {listType === 'FIXED' && (
          <>
            <Column
              title={t('Время разбора')}
              dataIndex="attachedToCaseAt"
              key="attached_to_case_at"
              render={dateTimeRenderer}
              width="8%"
              showSorterTooltip={false}
              sorter={true}
              sortOrder={prevFilters.sortByType === 'ATTACHED_TO_CASE_AT' ? sortOrderState : null}
              filterDropdown={({ clearFilters, confirm, selectedKeys, setSelectedKeys }) => (
                <DateFilterDropdown
                  setSelectedKeys={setSelectedKeys}
                  selectedKeys={selectedKeys}
                  filtersRange={filtersRange}
                  filtersRangeColumn={'attached_at'}
                  clearFilters={clearFilters}
                  confirm={confirm}
                />
              )}
              filteredValue={
                prevFilters?.attachedToCaseDateFrom || prevFilters?.attachedToCaseDateTo
                  ? [prevFilters?.attachedToCaseDateFrom || false, prevFilters.attachedToCaseDateTo || false]
                  : undefined
              }
            />
            <Column
              title={t('Разобрано')}
              dataIndex="attachedToCaseByUser"
              key="attached_to_case_by_user"
              filters={getAvailableFilter('users', availableFilters)}
              filteredValue={
                prevFilters.attachedToCaseByUserIds ? [prevFilters.attachedToCaseByUserIds].join().split(',') : null
              }
              showSorterTooltip={false}
              sorter={true}
              sortOrder={prevFilters.sortByType === 'ATTACHED_TO_CASE_BY_USER' ? sortOrderState : null}
              render={(user: IUser) => <User user={user} />}
            />
          </>
        )}

        {listType !== 'AWAITING' && (
          <Column
            title={t('Проблема')}
            dataIndex={'states'}
            key="state"
            filters={getAvailableFilter('states', availableFilters)}
            showSorterTooltip={false}
            filteredValue={
              prevFilters.uploadedFileAttachmentStates
                ? [prevFilters.uploadedFileAttachmentStates].join().split(',')
                : null
            }
            sorter={true}
            sortOrder={prevFilters.sortByType === 'STATE' ? sortOrderState : null}
            render={(
              barcodeFailureReason,
              {
                conversionFailureCodeDzi,
                conversionFailureCodeTiff,
                duplicateOfSlide,
                duplicatesCount,
                state,
              }: ISumpSlide,
            ) => (
              <ColumnProblemText
                conversionFailureCodeDzi={conversionFailureCodeDzi}
                conversionFailureCodeTiff={conversionFailureCodeTiff}
                duplicateOfSlide={duplicateOfSlide}
                duplicatesCount={duplicatesCount}
                state={state}
              />
            )}
          />
        )}
        <Column
          key="file_type"
          title={t('Тип')}
          dataIndex="fileType"
          render={(value) => getFileTypeText(value)}
          filters={getAvailableFilter('fileTypes', availableFilters)}
          filteredValue={prevFilters.uploadedFileTypes ? [prevFilters.uploadedFileTypes].join().split(',') : null}
          sorter={true}
          sortOrder={prevFilters.sortByType === 'FILE_TYPE' ? sortOrderState : null}
          showSorterTooltip={false}
        />
        <Column
          title=""
          dataIndex="labelUrl"
          key="labelUrl"
          width={90}
          render={(labelUrl, record: ISumpSlide) => <RowActions labelUrl={labelUrl} record={record} />}
        />
      </StyledTable>
      <FullScreenModalElement
        visible={Boolean(fileToPreview)}
        onCancel={() => setFileToPreview(undefined)}
        footer={null}
      >
        {fileToPreview?.mimeType === 'application/pdf' ? (
          <PdfViewer file={fileToPreview?.url} />
        ) : (
          <div style={{ alignItems: 'center', display: 'flex', height: '100%', justifyContent: 'center' }}>
            <img src={fileToPreview?.url} style={{ maxHeight: '100%', maxWidth: '100%' }} />
          </div>
        )}
      </FullScreenModalElement>
    </>
  )
}

export default FileList
