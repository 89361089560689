import TileLayer from 'ol/layer/Tile'
import { IIIF } from 'ol/source'
import ISlideLayerConfig from 'types/ISlideLayerConfig'

/**
 * минимальный размер вьювера
 */
const MIN_VIEWER_SIZE = 256
const CACHE_SIZE = 512

export const dzi = (function () {
  function loadUrl(
    opt_options: any, // attributions (defaults to undefined), crossOrigin (defaults to 'anonymous')
    setConfig: (config: ISlideLayerConfig) => void,
    dziErrorHandler: () => void,
    url?: string | string[] | URL,
  ) {
    if (!url) return
    const options = opt_options || {}
    // @ts-ignore
    const crossOrigin = options.crossOrigin === undefined ? 'anonymous' : options.crossOrigin

    //const layer = new ol.layer.Tile()
    const layer = new TileLayer()

    // @ts-ignore
    const last = url.lastIndexOf('.')
    // @ts-ignore
    const path = url.slice(0, last)

    const xhr = new XMLHttpRequest()
    // @ts-ignore
    xhr.open('GET', url)
    xhr.onload = function () {
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xhr.responseText, 'text/xml')
      const elements = xmlDoc.getElementsByTagName('Image')
      const tileSize = Number(elements[0].getAttribute('TileSize'))
      const format = elements[0].getAttribute('Format')
      const width = Number(elements[0].getElementsByTagName('Size')[0].getAttribute('Width'))
      const height = Number(elements[0].getElementsByTagName('Size')[0].getAttribute('Height'))
      const url = path + '_files/{z}/{x}_{y}.' + format

      const newOptions = {
        attributions: options.attributions,
        cacheSize: CACHE_SIZE,
        crossOrigin: crossOrigin,
        interpolate: false,
        reprojectionErrorThreshold: 1,
        size:
          width >= MIN_VIEWER_SIZE || height >= MIN_VIEWER_SIZE ? [width, height] : [MIN_VIEWER_SIZE, MIN_VIEWER_SIZE],
        tileSize: width >= MIN_VIEWER_SIZE ? tileSize : MIN_VIEWER_SIZE,
        url: url,
        zDirection: -1,
      }
      const source = new IIIF(newOptions)

      source.setTileUrlFunction((tileCoord) =>
        url
          // @ts-ignore
          .replace('{z}', tileCoord[0])
          // @ts-ignore
          .replace('{x}', tileCoord[1])
          // @ts-ignore
          .replace('{y}', tileCoord[2]),
      )
      layer.setExtent([0, -height, width, 0])
      layer.set('type', 'main')
      layer.setSource(source)
      setConfig({
        layer,
        options: newOptions,
        source,
      })
    }
    xhr.onerror = function () {
      dziErrorHandler()
    }
    xhr.send()

    return layer
  }

  return {
    loadUrl: loadUrl,
  }
})()
