import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'app/redux'
import IReport from 'types/IReport'

type State = {
  selectedReportIndexByCase: Record<number, number>
  /** флаг, который отслеживает загрузку справочников */
  isDictionaryLoading: boolean
  creationModalInitialReport?: Partial<IReport>
}

const initialState: State = {
  isDictionaryLoading: false,
  selectedReportIndexByCase: {},
}

export const reportsSlice = createSlice({
  initialState,
  name: 'reportsSlice',
  reducers: {
    setInitialReport(state, { payload }: PayloadAction<Partial<IReport> | undefined>) {
      state.creationModalInitialReport = payload
    },
    setSelectedReportIndex(state, { payload: { caseId, index } }: PayloadAction<{ caseId: number; index: number }>) {
      state.selectedReportIndexByCase[caseId] = index
    },
    toggleDictionaryLoading(state, { payload }: PayloadAction<boolean>) {
      state.isDictionaryLoading = payload
    },
  },
})

export const selectedReportByCaseSelector = (caseId: number) =>
  createSelector(
    (state: RootState) => state.reports,
    (reports) => reports.selectedReportIndexByCase[caseId] || 0,
  )
