import { useUserStatusContext } from 'features/multiplayer/lib'
import { FollowedUserLabel } from 'features/multiplayer/ui'
import { useOpenViewers, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { Fragment, RefObject, useCallback, useMemo } from 'react'
import { getUserColorBorderStyles } from 'shared/ui/kit'
import styled, { css } from 'styled-components/macro'
import TViewerId from 'types/TViewerId'
import Viewer from 'viewer/container/ui/Viewer'

const ThreeStyles = css`
  & > div:nth-child(1) {
    width: 33%;
    grid-row: 1 / 3;
    grid-column: 1 / 2;
  }
  & > div:nth-child(2) {
    width: 33%;
    grid-row: 1 / 2;
    grid-column: 2 / 3;
  }
  & > div:nth-child(3) {
    width: 33%;
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }
`
const TwoStyles = css`
  grid-template-rows: 100%;
`
const OneStyles = css`
  grid-template-rows: 100%;
  grid-template-columns: 100%;
`

const styles = {
  1: OneStyles,
  2: TwoStyles,
  3: ThreeStyles,
}

const Grid = styled.div<{ count: keyof typeof styles }>`
  position: relative;
  height: 100%;
  display: ${({ count }) => (count === 3 ? 'flex' : 'grid')};
  grid-gap: 3px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  background-color: var(--color-bg-1);
  ${({ count }) => styles[count]}
`

type Props = {
  viewerGridRef: RefObject<HTMLDivElement>
}
const ViewerGridContainer = ({ viewerGridRef }: Props) => {
  const { activeViewerId, viewingState } = useViewerPageProvided()
  const { openViewerIds } = useOpenViewers()

  const getSlideStateById = useCallback((id: TViewerId) => viewingState[id]?.slide, [viewingState])
  const getMapById = useCallback((id: TViewerId) => viewingState[id]?.map, [viewingState])

  const { targetUserId } = useUserStatusContext()

  const followedUserBorderStyle = useMemo(
    () =>
      targetUserId
        ? {
            ...getUserColorBorderStyles(targetUserId),
            borderStyle: 'solid',
            borderWidth: '5px',
          }
        : {},
    [targetUserId],
  )

  return (
    <Grid count={openViewerIds.length as any} style={followedUserBorderStyle}>
      {openViewerIds.map((id: string) => {
        const vid = id as TViewerId
        const slideState = getSlideStateById(vid)
        const map = getMapById(vid)
        if (slideState === undefined || map === undefined) return <Fragment key={id} />
        return (
          <Viewer
            key={id}
            viewerGridRef={viewerGridRef}
            slideState={slideState}
            map={map}
            active={vid === activeViewerId && openViewerIds.length > 1}
            viewerId={vid}
          />
        )
      })}
      <FollowedUserLabel />
    </Grid>
  )
}

export default ViewerGridContainer
