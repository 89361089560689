import { Divider } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { useChangeAnnotationMutation } from 'features/annotations'
import AnnotationsTypePanelContainer from 'features/annotations/ui/AnnotationsTypePanelContainer'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { InputElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { IAnnotation } from 'types/IAnnotations'
import TViewerId from 'types/TViewerId'
import { getMitosisData } from 'viewer/map/layers/annotations/lib/helpers'
import { useKeyUp } from 'viewer/map/layers/annotations/lib/hooks/useKeyUp'
import { useOnClickOutside } from 'viewer/map/layers/annotations/lib/hooks/useOnClickOutside'

const StyledContextMenu = styled.div`
  min-width: 300px;
  position: fixed;
  ${() => modalBackground}
  ${() => modalShadow}
  border-radius: 5px;
  z-index: 25;
`

const InputRow = styled.div`
  padding: 16px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  column-gap: 8px;

  & > *:last-child {
    white-space: nowrap;
  }
`

const StyledToolPanel = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
`

const StyledDivider = styled(Divider)`
  margin: 0px !important;
`

const INPUT_TIMEOUT = 500

/** Props for ObjectsAnnotationContextMenu component */
type Props = {
  /** Идентификатор текущего вьювера */
  viewerId: TViewerId
  /** Положение окна контекстного меню */
  openContext: { x: number; y: number } | null
  /** Колбэк для установки положения окна контекстного меню */
  setOpenContext: (position: { x: number; y: number } | null, hoveredAnnotationId?: number) => void
  /** Флаг видимости панели быстрого выбора аннотации */
  isTypePanelVisible: boolean
  /** Аннотация, для которой открывается контекстное меню */
  annotation: IAnnotation
  /** Возможность редактировать description и доступность инструмента аннотаций*/
  editDisable: boolean
}

export const ObjectsAnnotationContextMenu = ({
  annotation,
  editDisable,
  isTypePanelVisible,
  openContext,
  setOpenContext,
  viewerId,
}: Props) => {
  const dispatch = useDispatch()
  const { hoveredAnnotationId } = useTypedSelector((state) => state.annotations)
  const { caseId, slideId } = useViewerIdSlideState(viewerId)
  const { t } = useTranslation()
  const { mutate: editAnnotation } = useChangeAnnotationMutation({
    caseId,
    slideId,
  })
  const root = useRef<HTMLDivElement | null>(null)
  useKeyUp(13, () => setOpenContext(null, hoveredAnnotationId))
  useOnClickOutside(root, () => setOpenContext(null, hoveredAnnotationId))

  const mitosisData = getMitosisData(annotation)

  const description = annotation?.caption || ''
  const handleChangeDescription = (value: string) => {
    if (annotation) {
      editAnnotation({
        ...annotation,
        caption: value,
      })
    }
  }
  const [tmpDesc, setTmpDesc] = useState(description)

  useEffect(() => {
    setTmpDesc(description)
  }, [description])

  const isNewDescriptionValueValid = (value: string) =>
    /^[a-zA-ZА-Яа-я0-9\s.,?!":\/()%*^<>#\[\]~`+=\${}]*$/.test(value) && value.length <= 30

  //@ts-ignore
  const applyDescriptionTimeout = useRef<Timeout>(null)

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (applyDescriptionTimeout.current) clearTimeout(applyDescriptionTimeout.current)
    const { value } = e.target
    if (!isNewDescriptionValueValid(value)) return
    setTmpDesc(value)
    applyDescriptionTimeout.current = setTimeout(() => {
      handleChangeDescription(value || '')
    }, INPUT_TIMEOUT)
  }

  const onFocusCapture = (evt: React.FocusEvent<HTMLInputElement>) => evt.target.select()
  const setIsAnyInputFocusing = (value: boolean) => {
    dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(value))
  }
  return (
    <div ref={root}>
      <StyledContextMenu
        style={{
          left: openContext?.x,
          position: 'fixed',
          top: openContext?.y,
        }}
      >
        <InputRow>
          <InputElement
            placeholder={t('Подпись к клеткам')}
            autoFocus
            onFocus={() => setIsAnyInputFocusing(true)}
            onBlur={() => setIsAnyInputFocusing(false)}
            value={tmpDesc}
            onChange={onChangeHandler}
            onFocusCapture={onFocusCapture}
            disabled={editDisable}
          />
          {mitosisData && (
            <div>
              {mitosisData.mitosis} {t('на')} {mitosisData.targetArea} {t('мм²')}
            </div>
          )}
        </InputRow>
        {isTypePanelVisible && <StyledDivider />}
        {isTypePanelVisible && (
          <StyledToolPanel>
            <AnnotationsTypePanelContainer
              disabled={editDisable}
              onTypeSelect={() => setOpenContext(null, hoveredAnnotationId)}
              openContextMenu
            />
          </StyledToolPanel>
        )}
      </StyledContextMenu>
    </div>
  )
}
