import { checkIntegration } from 'entities/lis-integration'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import { useTranslation } from 'react-i18next'
import { formatDateStringForDisplay } from 'shared/lib/date'
import { Descriptions, DescriptionsItem } from 'shared/ui/description'
import { TitleElement } from 'shared/ui/kit'
import { IPatientDTO } from 'types/IPatient'

type Props = {
  patient: IPatientDTO
}

const PatientInfo = ({ patient }: Props) => {
  const lisMode = useLisMode()
  const { t } = useTranslation()
  return (
    <div>
      {patient ? (
        checkIntegration('patient', 'fullname', lisMode) && (
          <TitleElement level={3} style={{ margin: '16px 0 8px' }}>
            {patient?.fullname || t('Имя пациента скрыто')}
          </TitleElement>
        )
      ) : (
        <TitleElement level={3} style={{ margin: '16px 0 8px' }}>
          {t('Имя пациента не указано')}
        </TitleElement>
      )}
      <Descriptions>
        {/* ID пациента */}
        {checkIntegration('patient', 'externalId', lisMode) && patient?.externalId && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={t('ID пациента')} span={3}>
            {patient?.externalId}
          </DescriptionsItem>
        )}
        {/* Дата рождения */}
        {checkIntegration('patient', 'birthDate', lisMode) && patient?.birthDate && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Дата рождения')} span={3}>
            {formatDateStringForDisplay(patient.birthDate)}
          </DescriptionsItem>
        )}
        {/* Пол */}
        {checkIntegration('patient', 'sex', lisMode) && patient?.sex && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Пол')} span={3}>
            {patient?.sex === 'FEMALE' ? t('Женщина') : t('Мужчина')}
          </DescriptionsItem>
        )}
      </Descriptions>
    </div>
  )
}

export default PatientInfo
