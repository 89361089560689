import { PaginatedSampFilters } from 'features/sump'
import { SUMP_TAB } from 'pages/sump/ui/SumpTopActions'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { useDispatch } from 'react-redux'
import { formatDateTimeISO } from 'shared/lib/date'

type args = {
  prevFilters: PaginatedSampFilters
  prevFiltersKey: SUMP_TAB
}

export const useListFilterRange = ({ prevFilters, prevFiltersKey }: args) => {
  const dispatch = useDispatch()
  const fromKeys = {
    attached_at: 'attachedToCaseDateFrom',
    created_at: 'createdDateFrom',
    deleted_at: 'deletedDateFrom',
  }
  const toKeys = {
    attached_at: 'attachedToCaseDateTo',
    created_at: 'createdDateTo',
    deleted_at: 'deletedDateTo',
  }

  const filterRange = (selectedKeys: React.Key[], columnName?: string) => {
    const startOfDay = new Date(selectedKeys[0])
    const endOfDay = new Date(selectedKeys[1])

    if (columnName === 'created_at' || columnName === 'attached_at' || columnName === 'deleted_at') {
      const fromKey = fromKeys[columnName] || fromKeys.created_at
      const toKey = toKeys[columnName] || toKeys.created_at

      dispatch(
        viewerPageSlice.actions.setSumpFilters({
          filters: {
            ...prevFilters,
            [fromKey]: typeof selectedKeys[0] === 'string' ? formatDateTimeISO(startOfDay, 'start') : undefined,
            [toKey]: typeof selectedKeys[1] === 'string' ? formatDateTimeISO(endOfDay, 'end') : undefined,
          },
          key: prevFiltersKey,
        }),
      )
    }
  }

  return filterRange
}
