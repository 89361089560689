import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { useUserStatusContext } from 'features/multiplayer/lib'
import {
  useOpenViewers,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import { useCoregistrationProvided, useViewerCorState } from 'pages/viewer/lib/coregistration/Provider'
import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButtonElement, IconElement, SpinElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import TViewerId from 'types/TViewerId'

const Actions = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
`
const FloatingButton = styled(IconButtonElement)`
  &.ant-btn-icon-only {
    margin: 0px 2px;
    padding: 4px;
    width: 32px;
    height: 32px;
  }

  &.ant-btn-icon-only:not([disabled]) {
    ${() => modalBackground}
    ${() => modalShadow}
  }

  &.ant-btn-icon-only[disabled] {
    opacity: 0.5;
  }
`

type Props = {
  viewerId: TViewerId
}

const ViewerFloatingActions = ({ viewerId }: Props) => {
  /** Стейт видимости тултипа */
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)
  const { openViewerIds } = useOpenViewers()
  const { slideGroupType } = useViewerIdSlideState(viewerId)
  const { autoCoreg, handableCoreg, removeViewer } = useViewerPageProvided()
  const { slideGroupType: refSlideGroupType } = useViewerIdSlideState('A')
  const { postCorUpdate } = useCoregistrationProvided()
  const state = useViewerCorState(viewerId)
  const { t } = useTranslation()

  const { unsubscribeFromUser } = useUserStatusContext()

  if (state === undefined) return null

  const closeViewer = (e: any) => {
    e.stopPropagation()
    removeViewer(viewerId)
    unsubscribeFromUser()
  }
  /** При наведении курсора на кнопку показываем тултип */
  const handleMouseEnter = () => {
    setTooltipVisible(true)
  }

  /** Если уводим курсор от кнопки, убираем тултип */
  const handleMouseLeave = () => {
    setTooltipVisible(false)
  }

  const onCoregUpdate = (e: MouseEvent<HTMLSpanElement>) => {
    /** При клике "Обновить привязку" убираем тултип */
    setTooltipVisible(false)
    e.stopPropagation()
    postCorUpdate(viewerId)
  }

  return (
    <Actions id="FLOATING_ACTION">
      {openViewerIds.length > 1 && (
        <>
          {viewerId !== 'A' && slideGroupType === 'MICRO' && (
            <>
              {(autoCoreg === true || handableCoreg === true) && (
                <TooltipElement title={t('Обновить привязку')} placement="bottom" visible={tooltipVisible}>
                  <FloatingButton
                    onClick={onCoregUpdate}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    shape="circle"
                    icon={state.loading ? <SpinElement /> : <IconElement name={'relink'} />}
                    color="red"
                    disabled={state.loading || refSlideGroupType === 'MACRO'}
                  />
                </TooltipElement>
              )}
            </>
          )}
          <TooltipElement title={t('Закрыть изображение')} placement={'bottomRight'}>
            <FloatingButton onClick={closeViewer} shape="circle" icon={<IconElement name="cross" />} color="red" />
          </TooltipElement>
        </>
      )}
    </Actions>
  )
}

export default ViewerFloatingActions
