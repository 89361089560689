import { useTypedSelector } from 'app/redux/lib/selector'
import { rotate } from 'ol/coordinate'
import { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useDeepCompareEffect from 'use-deep-compare-effect'

type EditProps = {
  /**  rotation - обновленный угол поворота */
  rotation?: number
  /** mapSizes - параметры миникарты */
  mapSizes?: number[]
  /** setBackGroundSizes - установка параметров блока подложки миникарты */
  setBackGroundSizes: (a: number[]) => void
  /** backGroundSizes - параметры блока подложки миникарты */
  backGroundSizes: number[]
  /** resizeIsActive - стейт операции onResize */
  resizeIsActive: boolean
  type?: string
}

const StyledEditBox = styled.div<{ type: string | undefined }>`
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 12px), calc(100% - 12px) 100%, 0 100%);
  position: ${({ type }) => (type === 'SLIDE_INFO' || type === 'SLIDE_LABEL' ? undefined : 'absolute')};
`

const DEFAULT_SCALE = 1
// 100%
const FULL_PERCENT = 100

type Style = {
  /** rotation - текущий угол поворота */
  rotation: number
  /** size - параметры миникарты */
  size: [number, number]
  /** scale - масштаб объекта */
  scale: number
}

const EditBox: FC<EditProps> = ({
  backGroundSizes,
  children,
  mapSizes,
  resizeIsActive,
  rotation,
  setBackGroundSizes,
  type,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [style, setStyle] = useState<Style>()

  const isFirstRender = useRef(true)
  const overview = useTypedSelector((state) => state.viewerPage.tools?.OVERVIEW)

  const updateStyle = (rotation?: number) => {
    if (!mapSizes || rotation === undefined) return
    const mapWidth = mapSizes[0]
    const mapHeight = mapSizes[1]
    const corners = [[0, 0], [mapWidth, 0], [0, mapHeight], [...mapSizes]]
    const rot_corners = corners.map((corner) => rotate(corner, rotation))
    const x = rot_corners.map((rot_corner) => rot_corner[0])
    const y = rot_corners.map((rot_corner) => rot_corner[1])
    const new_width = Math.max(...x) - Math.min(...x)
    const new_height = Math.max(...y) - Math.min(...y)

    const getRelativeScale = () => {
      const editBoxElement = document.getElementsByClassName('edit-box')[0]
      const toolElement = editBoxElement?.parentElement

      if (!toolElement || isFirstRender.current) {
        return DEFAULT_SCALE
      }
      // @ts-ignore
      const toolElementSize = toolElement.getBoundingClientRect()
      const toolElementWidth = toolElementSize.width
      const toolElementHeight = toolElementSize.height
      const deltaX = new_width - toolElementWidth
      const deltaY = new_height - toolElementHeight
      const maxDelta = Math.max(deltaY, deltaX)
      if (deltaX < 0 && deltaY < 0) {
        return DEFAULT_SCALE
      }

      // получаем процент разности в велечинах между телом модалки и канвасом, для изменения масштаба
      const scaleDeltaPercent = maxDelta / (maxDelta === deltaX ? new_width / FULL_PERCENT : new_height / FULL_PERCENT)
      return 1 - scaleDeltaPercent / FULL_PERCENT
    }

    // установка размеров модального окна информации о слайде
    setBackGroundSizes([new_width, new_height])
    const resizer = document.getElementById('MINIMAP')?.children[1] as HTMLDivElement
    if (!resizer || !ref.current) return

    setStyle({ rotation, scale: getRelativeScale(), size: [new_width, new_height] })
  }

  useEffect(() => {
    // @ts-ignore
    window.addEventListener('resize', updateStyle)
    // @ts-ignore
    return () => window.removeEventListener('resize', updateStyle)
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      !resizeIsActive && updateStyle(rotation)
    }
  }, [rotation])

  useDeepCompareEffect(() => {
    !resizeIsActive && updateStyle(rotation)
  }, [mapSizes, backGroundSizes, overview.isVisible])

  return (
    <StyledEditBox
      style={
        style && {
          height: mapSizes && mapSizes[1],
          transform: `rotateZ(${style.rotation}rad) scale(${style.scale})`,
          width: mapSizes && mapSizes[0],
        }
      }
      type={type}
      ref={ref}
      className="edit-box"
    >
      {children}
    </StyledEditBox>
  )
}

export default EditBox
