import { useSlideQuery } from 'entities/slide'
import { useCaseQuery } from 'features/cases/api/query'
import { memo, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ICaseRelation } from 'types/ICase'
import ISource from 'types/ISource'
import { INITIAL_BACKGROUND_SIZES, RIGHT_ANGLE, RIGHT_ANGLE_X_3 } from 'viewer/tools/ui/lib/constans'

export type LabelInfoProps = {
  /** rotation - обновленный угол поворота */
  rotation?: number
  /** setRotation - обновление угла поворота */
  setRotation: (rotate: number) => void
  /** slideId - идентификатор слайда в текущем вьювере */
  slideId: number
  /** caseId - идентификатор случая */
  caseId: number
  /** source - источник слайда */
  source: ISource
  /** labelSrc - изображение этикетки */
  labelSrc: string
  /** backGroundSizes - параметры блока подложки */
  backGroundSizes: number[]
  /** setBackGroundSizes - установка параметров блока подложки */
  setBackGroundSizes: (a: number[]) => void
  /** maxHeight - максимальная допустимая высота блока */
  maxHeight: number
}

const StyledImage = styled.img`
  object-fit: contain;
`

export const LabelInfoContainer = memo(
  ({
    backGroundSizes,
    caseId,
    labelSrc,
    maxHeight,
    rotation,
    setBackGroundSizes,
    setRotation,
    slideId,
    source,
  }: LabelInfoProps) => {
    const { data: slide } = useSlideQuery({ slideId, source })
    const rotationCache = slide?.slideMetadata?.commonMetadata?.labelRotation || 0
    const { data: caseRecord } = useCaseQuery({ caseId, source })
    const imageRef = useRef<HTMLImageElement>(null)
    const isOwner = caseRecord?.relation === ICaseRelation.OWNER
    const [isLoading, setIsLoading] = useState(false)
    const [aspectRatio, setAspectRatio] = useState<number>()

    useEffect(() => {
      /** Сбрасываем значения при смене слайда */
      if (isOwner) {
        setRotation(rotationCache)
      }
      setBackGroundSizes(INITIAL_BACKGROUND_SIZES)
      setAspectRatio(undefined)
      setIsLoading(true)
    }, [slideId])

    useEffect(() => {
      /** Получаем соотношение сторон */
      if (imageRef.current?.naturalWidth && imageRef.current?.naturalHeight)
        setAspectRatio(imageRef.current.naturalWidth / imageRef.current.naturalHeight)
    }, [slideId, isLoading])

    const isRightAngle = (rotation?: number) => rotation === RIGHT_ANGLE || rotation === RIGHT_ANGLE_X_3

    useEffect(() => {
      if (aspectRatio && imageRef.current?.naturalWidth && imageRef.current?.naturalHeight) {
        /** Width окна при вращении этикетки неизменно */
        if (isRightAngle(rotation)) {
          const overHeightFactor = backGroundSizes[0] * aspectRatio > maxHeight ? 2 : 1
          /** Если превышена максимальная высота, уменьшаем размеры блока на 2 */
          setBackGroundSizes([
            backGroundSizes[0] / overHeightFactor,
            (backGroundSizes[0] * aspectRatio) / overHeightFactor,
          ])
        } else {
          setBackGroundSizes([backGroundSizes[0], backGroundSizes[0] / aspectRatio])
        }
      }
    }, [rotation, aspectRatio, slideId])

    return (
      <StyledImage
        ref={imageRef}
        src={labelSrc}
        id={labelSrc}
        onLoad={() => setIsLoading(false)}
        loading={'eager'}
        style={{
          display: isLoading ? 'none' : 'block',
          height: aspectRatio && isRightAngle(rotation) ? backGroundSizes[0] : '100%',
          transform: `rotateZ(${rotation ? rotation : 0}deg)`,
          width: aspectRatio && isRightAngle(rotation) ? 'auto' : backGroundSizes[0],
        }}
      />
    )
  },
)
