import { useTypedSelector } from 'app/redux/lib/selector'
import { useCaseQuery } from 'features/cases/api/query'
import { useEffect, useMemo } from 'react'
import { usePrevious } from 'shared/lib/hooks'
import ISlide, { SlideState } from 'types/ISlide'

/**Номер группировки контейнеров и блоков*/
const NUMERIC_CONTAINER_BLOCK = 1
/**Номер группировки контейнеров*/
const NUMERIC_CONTAINER = 2
/**Номер группировки блоков*/
const NUMERIC_BLOCK = 4

/**
 * @typedef {Object} BlockSortJSDoc
 * @property {number} slideId - Id слайда.
 * @property {string | undefined} name - Название слайда.
 * @property {string | null | undefined} block - Блок слайда.
 * @property {string | null | undefined} container - Контейнер слайда.
 * @returns BlockSortJSDoc
 */
type BlockSort = {
  slideId: number
  name?: string
  block?: string | null
  container?: string | null
}
export const useSlidesList = (caseId: number) => {
  const { data: caseRecord, refetch } = useCaseQuery({ caseId, source: 'PLATFORM' })
  const filesList = useTypedSelector((state) => state.tusFiles.listByCase[caseId]) || []

  const prevFilesList = usePrevious(filesList) || []

  // refetch slides in case if loading file removed from process
  useEffect(() => {
    if (prevFilesList.length - 1 === filesList.length) {
      refetch()
    }
  }, [filesList])

  /** возвращает индентификаторы слайдов */
  const slidesIds = (caseRecord && caseRecord.slides.map((slide) => slide.slideId)) || []

  /** возвращает макро слайды */
  const macroSlidesIds = useMemo(
    () =>
      (
        caseRecord &&
        caseRecord.slides
          .filter((slide) => slide.groupType === 'MACRO')
          .map((slide) => ({
            barcode: slide.barcode,
            name: slide.slideMetadata?.commonMetadata?.caption,
            slideId: slide.slideId,
          }))
      )?.sort((a, b) => ('' + a.name).localeCompare(b.name || '')) || [],
    [caseRecord],
  )

  const blockSort = useMemo(
    () =>
      (
        caseRecord &&
        caseRecord.slides
          .filter((slide) => slide.groupType !== 'MACRO' && (slide.block || slide.container))
          .map((slide) => ({
            barcode: slide.barcode,
            block: slide.block,
            container: slide.container,
            name: slide.slideMetadata?.commonMetadata?.caption,
            slideId: slide.slideId,
          }))
      )?.sort((a, b) => ('' + a.barcode).localeCompare(b.barcode || '')) || [],
    [caseRecord],
  )
  const blockDictionary = useMemo(
    () =>
      blockSort.reduce((acc: Record<string, BlockSort[]>, item) => {
        const block = ((item.container || '') + '-' + (item.block || '')) as keyof typeof acc
        !acc[block] ? (acc[block] = [item]) : acc[block].push(item)
        return acc
      }, {}),
    [blockSort],
  )
  const emptyBlocksSuccess = useMemo(
    () => caseRecord?.slides.filter((slide) => !slide.block && !slide.container && slide.groupType !== 'MACRO'),
    [caseRecord],
  )
  const emptyBlocks = [...(emptyBlocksSuccess || [])]
  // Слайды на конвертации тоже не имеют блоков, поэтому разделяем
  const [processingSlides, onlyEmptyBlocks] = emptyBlocks.reduce(
    (acc, item) => {
      item.state === SlideState.PROCESSING ? acc[0].push(item) : acc[1].push(item)
      return acc
    },
    [[], []] as [ISlide[], ISlide[]],
  )

  const sortBlockDictionary = (dictionary: Record<string, BlockSort[]>) => {
    const groups: string[][] = []
    const keys: string[] = []
    Object.keys(dictionary).forEach((key) => {
      const item = dictionary[key]
      const hasContainerAndBlock = item.some((block) => block.container && block.block)
      const hasContainer = item.some((block) => block.container && !block.block)
      const hasBlock = item.some((block) => !block.container && block.block)

      const groupIndex =
        (hasContainerAndBlock ? NUMERIC_CONTAINER_BLOCK : 0) |
        (hasContainer ? NUMERIC_CONTAINER : 0) |
        (hasBlock ? NUMERIC_BLOCK : 0)

      groups[groupIndex] = groups[groupIndex] || []
      groups[groupIndex].push(key)
    })

    groups.forEach((group) => {
      if (group) {
        keys.push(
          ...group.sort((a, b) => {
            const [aFirst, aSecond] = a.split('-')
            const [bFirst, bSecond] = b.split('-')

            if (!aSecond && !bSecond) {
              const aNumber = parseInt(aFirst)
              const bNumber = parseInt(bFirst)
              if (aNumber === bNumber) {
                return aNumber ? aFirst.localeCompare(bFirst) : bFirst.localeCompare(aFirst)
              }
              return aNumber - bNumber
            }

            const aWithoutZeroBegining = parseInt(aSecond)
            const bWithoutZeroBegining = parseInt(bSecond)

            const aBeginigZeroCount = aSecond.length - aWithoutZeroBegining.toString().length
            const bBeginigZeroCount = bSecond.length - bWithoutZeroBegining.toString().length

            if (aFirst !== bFirst) {
              const aNumber = parseInt(aFirst)
              const bNumber = parseInt(bFirst)
              if (aNumber === bNumber) {
                return aNumber ? aFirst.localeCompare(bFirst) : bFirst.localeCompare(aFirst)
              }
              return aNumber - bNumber
            }
            if (aWithoutZeroBegining !== bWithoutZeroBegining) {
              return aWithoutZeroBegining - bWithoutZeroBegining
            }
            return bBeginigZeroCount - aBeginigZeroCount
          }),
        )
      }
    })

    return keys
  }

  const blocksSorts = useMemo(() => sortBlockDictionary(blockDictionary), [blockDictionary])

  const flatSlides = useMemo(
    () =>
      blocksSorts
        .map((key, i) => {
          const typedKey = key as keyof typeof blockDictionary
          const blocks = blockDictionary[typedKey]
          return blocks.map((item) => item.slideId)
        })
        .flat(),
    [blocksSorts],
  )
  const sortedSlides = [
    ...flatSlides,
    ...(emptyBlocks ? emptyBlocks?.map((item) => item.slideId) : []),
    ...(macroSlidesIds ? macroSlidesIds?.map((item) => item.slideId) : []),
  ]

  return {
    blockDictionary,
    blockSort,
    blocksSorts,
    emptyBlocks,
    filesList,
    macroSlidesIds,
    onlyEmptyBlocks,
    processingSlides,
    slidesIds,
    sortedSlides,
  }
}
