import Empty, { EmptyProps } from 'antd/es/empty'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

const StyledEmpty = styled(Empty)`
  .ant-empty-img-default-path-1 {
    fill: #989898;
  }
  .ant-empty-img-default-path-3 {
    fill: #eeeeee;
  }
  .ant-empty-img-default-path-4 {
    fill: #cbcad0;
  }
  .ant-empty-img-default-path-5 {
    fill: #eeeeee;
  }
  .ant-empty-img-default-ellipse {
    fill: #989898;
  }
  .ant-empty-img-default-g {
    fill: #989898;
  }
`

export const EmptyElement = (props: EmptyProps) => {
  const { t } = useTranslation()
  return <StyledEmpty description={<span>{t('Ничего нет')}</span>} {...props} />
}
