import { LabelType } from 'pages/viewer/model/viewerPageSlice'

type RefetchType = {
  /** Функция для повторного запроса аннотаций, связанных с конкретным кейсом. */
  refetchAnnotationsByCase: () => void
  /** Функция для повторного запроса данных по конкретному кейсу. */
  refetchCase: () => void
}

const ROTATION_DEGREES = {
  FULL_CIRCLE: 360,
  RIGHT_90: 90,
}

enum RotateAction {
  RotateLabel = 'rotateLabel',
  RotateAllLabels = 'rotateAllLabels',
}

export const refetch = ({ refetchAnnotationsByCase, refetchCase }: RefetchType) => {
  refetchCase()
  refetchAnnotationsByCase()
}

export const updateRotate = (objects: LabelType, action: string, slideId?: number) => {
  /** Создаем новый объект с копией старых значений */
  const updatedObjects = { ...objects }

  if (action === RotateAction.RotateLabel && slideId) {
    if (updatedObjects[slideId]) {
      const currentRotate = updatedObjects[slideId].rotate || 0
      const updatedRotate = (currentRotate + ROTATION_DEGREES.RIGHT_90) % ROTATION_DEGREES.FULL_CIRCLE

      updatedObjects[slideId] = {
        ...updatedObjects[slideId],
        rotate: updatedRotate >= 0 ? updatedRotate : ROTATION_DEGREES.FULL_CIRCLE + updatedRotate,
      }
    }
  }

  if (action === RotateAction.RotateAllLabels) {
    for (const id in updatedObjects) {
      const currentRotate = updatedObjects[id].rotate || 0
      const updatedRotate = (currentRotate + ROTATION_DEGREES.RIGHT_90) % ROTATION_DEGREES.FULL_CIRCLE

      updatedObjects[id] = {
        ...updatedObjects[id],
        rotate: updatedRotate >= 0 ? updatedRotate : ROTATION_DEGREES.FULL_CIRCLE + updatedRotate,
      }
    }
  }

  return updatedObjects
}

export const ACTION_SHOW_PREVIEW = 'isShowPreview'
export const ACTION_SHOW_LABELS = 'isShowLabels'
export const ACTION_SHOW_NAME_SLIDE = 'isShowNameSlide'
