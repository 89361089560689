import { useTranslation } from 'react-i18next'
import { ParagraphElement, SmallIconButtonElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { DictionaryItem } from 'types/IDictionary'

const TumorGrid = styled.div`
  width: 100%;
  display: grid;
  padding: 0 0 4px 8px;
  grid-template-areas: 'code actions' 'desc desc';
  grid-template-columns: 1fr auto;
  align-items: center;
  background: var(--color-bg-3);
  border-radius: 5px;
`

const Actions = styled.div`
  grid-area: actions;
  color: var(--color-text-3);
`

type Props = {
  morph: DictionaryItem
  onDeleteMorph: (code: string) => void
}

const SelectedTumorType = ({ morph, onDeleteMorph }: Props) => {
  const { t } = useTranslation()
  return (
    <TumorGrid>
      <TitleElement level={3} style={{ fontWeight: 400, gridArea: 'code' }} type="secondary">
        {morph.code}
      </TitleElement>
      <Actions>
        <SmallIconButtonElement
          icon="delete"
          title={t('Удалить')}
          placement="left"
          onClick={() => onDeleteMorph(morph.code)}
        />
      </Actions>
      <ParagraphElement style={{ gridArea: 'desc' }}>{morph.name}</ParagraphElement>
    </TumorGrid>
  )
}
export default SelectedTumorType
