import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'app/redux'
import { createMatchSelector } from 'connected-react-router'
import { PaginatedAtlasSlidesFilters } from 'entities/atlas'
import { PaginatedTasksFilters } from 'entities/tasks/api/query'
import { PaginatedSampFilters } from 'features/sump/api/query'
import { ACTION_SHOW_LABELS, ACTION_SHOW_NAME_SLIDE, ACTION_SHOW_PREVIEW } from 'features/thumbnails/common/utils'
import { difference } from 'lodash'
import { SUMP_TAB } from 'pages/sump/ui/SumpTopActions'
import { ConfigValues } from 'pages/viewer/ui/common/SetupMenu'
import queryString from 'query-string'
import { ISelectedBbox } from 'types/ISimilarRegion'
import ISlide from 'types/ISlide'
import TViewerId from 'types/TViewerId'
import { ML_TOOLS_MAP } from 'viewer/tools/ui/modal/MlToolsMap'

export const VIEWERS_SET = ['A', 'B', 'C', 'D']

export enum SideRightPanelType {
  CASE = 'CASE',
  COMMENTS = 'COMMENTS',
  ANNOTATIONS = 'ANNOTATIONS',
  NOTIFICATION = 'NOTIFICATION',
}

export type ViewerLeftPanelType = 'SLIDES' | 'SEARCH' | 'MENU' | undefined

export type ViewerRightPanelType =
  | SideRightPanelType.CASE
  | SideRightPanelType.COMMENTS
  | SideRightPanelType.ANNOTATIONS
  | SideRightPanelType.NOTIFICATION
  | undefined

export type ViewerType = 'ATLAS' | 'DEFAULT' | 'TASKS'

export type TGridInfoTool =
  | 'OVERVIEW'
  | 'SLIDE_INFO'
  | 'SLIDE_LABEL'
  | 'MITOSIS'
  | 'KI67'
  | 'SEGMENTATION'
  | 'HEAT_MAP'
  | 'VALIDATION'
  | 'SEARCH_MORPHOLOGY'
  | 'PV_PROSTATE'
  | 'ARTEFACTS'
  | 'MEDICALNEURONETS_CRC'
export type TGridInfoToolId =
  | 'MINIMAP'
  | 'IGNORE_IN_SCREENSHOT'
  | 'SLIDE_LABEL'
  | 'MITOSIS'
  | 'KI67'
  | 'SEGMENTATION'
  | 'HEAT_MAP'
  | 'VALIDATION'
  | 'SEARCH_MORPHOLOGY'
  | 'PV_PROSTATE'
  | 'ARTEFACTS'
  | 'MEDICALNEURONETS_CRC'
  | null

export type CountingObjectType = 'OBJECTS' | 'MITOSIS'
export type LabelType = { [key: number]: SlideInfo }

export type ToolOption = {
  isVisible: boolean
  position: number[]
}

type SumpFilters = {
  NOT_ATTACHED_TO_CASE: PaginatedSampFilters
  ATTACHED_TO_CASE: PaginatedSampFilters
  DELETED: PaginatedSampFilters
  AWAITING_CASE: PaginatedSampFilters
  /** Общий поиск */
  SEARCH: PaginatedSampFilters
  /** Дубли слайда */
  DUPLICATE: PaginatedSampFilters
}

const DEFAULT_SUMP_FILTERS: PaginatedSampFilters = {
  page: 0,
  size: 50,
}

export type SlideInfo = {
  /** Угол поворота этикетки. */
  rotate: number
  /** Идентификатор слайда. */
  slideId?: number
  /** Прокси URL этикетки. */
  proxiedLabelUrl?: string
  /** URL этикетки. */
  labelUrl?: string
}

type Tools = {
  OVERVIEW: ToolOption
  SLIDE_INFO: ToolOption
  SLIDE_LABEL: ToolOption
  MITOSIS: ToolOption
  KI67: ToolOption
  SEGMENTATION: ToolOption
  HEAT_MAP: ToolOption
  VALIDATION: ToolOption
  SEARCH_MORPHOLOGY: ToolOption
  /** AI инструмент Сегментация ткани предстательной железы */
  PV_PROSTATE: ToolOption
  /** AI инструмент Детекция артефактов пробоподготовки */
  ARTEFACTS: ToolOption
  /** AI инструмент Детекция метастазов в ЛУ */
  MEDICALNEURONETS_CRC: ToolOption
}

type State = {
  viewerType: ViewerType
  leftPanelType: ViewerLeftPanelType
  rightPanelType: ViewerRightPanelType
  openViewers: TViewerId[]
  coregActive?: boolean
  /** Viewer with reference slide */
  coregRefViewerId: TViewerId
  dragging: boolean
  /** Monitored viewer */
  coregActiveViewerIds: TViewerId[]
  cacheMessageShown: boolean
  selectWorkspaceModal: boolean
  atlasFilters: PaginatedAtlasSlidesFilters
  tasksFilters: PaginatedTasksFilters
  sumpFilters: SumpFilters
  /** Выбранный штрихкод слайда для отображения его дубликатов */
  sumpDuplicateBarcodeShowNumber: string

  tools: Tools
  isColorsModalOpen: boolean
  isHotkeysModalOpen: boolean
  isAnyInputFocusing: boolean
  /** Координаты открытия контекстного меню для превью. */
  thumbnailMenuPosition: number[]
  /** Слайд в левой панели, на который навели мышь. */
  slideThumbnailHover?: ISlide
  similarThumbnailHover?: ISelectedBbox
  isCacheMessage: boolean
  descriptionsVisibility: boolean
  liteContextMenuVisibility: boolean
  focusedInput: boolean
  /** Объект, представляющий значения конфигурации. */
  configPanels: ConfigValues
  overviewModalWidth?: number
  /** данные этикетки */
  label: LabelType
  zIndexPanel: {
    MINIMAP: number
    IGNORE_IN_SCREENSHOT: number
    SLIDE_LABEL: number
    MITOSIS: number
    KI67: number
    HEAT_MAP: number
    VALIDATION: number
    SEGMENTATION: number
    SEARCH_MORPHOLOGY: number
    PV_PROSTATE: number
    /** AI инструмент Детекция артефактов пробоподготовки */
    ARTEFACTS: number
    /** AI инструмент Детекция метастазов в ЛУ */
    MEDICALNEURONETS_CRC: number
  }
  panelInFlex: { x: number; y: number; position: number[] }[] | undefined
  leftPanelWidth?: number | string
  isMagnetize: {
    MINIMAP: boolean
    IGNORE_IN_SCREENSHOT: boolean
    SLIDE_LABEL: boolean
    MITOSIS: boolean
    KI67: boolean
    HEAT_MAP: boolean
    VALIDATION: boolean
    SEGMENTATION: boolean
    SEARCH_MORPHOLOGY: boolean
    PV_PROSTATE: boolean
    /** AI инструмент Детекция артефактов пробоподготовки */
    ARTEFACTS: boolean
    /** AI инструмент Детекция метастазов в ЛУ */
    MEDICALNEURONETS_CRC: boolean
  }
  mitosisLayerInProgress?: boolean
  countingObjectType?: CountingObjectType
  /** Индикатор процесса удаления аннотации подсчета */
  isObjectsDeleting?: boolean
  /** Индикатор необходимости открытия окна подписи для сохраненного подсчета */
  whetherToOpenObjectsContext?: boolean
  /** Вьювер, над которым находится курсор при зуме */
  viewerIdOnMouseZoom?: TViewerId
  geometryTolerance?: number
}

const initialState: State = {
  atlasFilters: {},

  cacheMessageShown: false,

  configPanels: {
    [ACTION_SHOW_LABELS]: true,
    [ACTION_SHOW_NAME_SLIDE]: true,
    [ACTION_SHOW_PREVIEW]: true,
  },

  /** Coregistration mode parameters */
  coregActive: false,

  coregActiveViewerIds: ['A'],
  coregRefViewerId: 'A',
  descriptionsVisibility: true,
  dragging: false,
  focusedInput: false,
  geometryTolerance: 0,
  isAnyInputFocusing: false,
  isCacheMessage: false,
  isColorsModalOpen: false,
  isHotkeysModalOpen: false,
  isMagnetize: {
    ARTEFACTS: false,
    HEAT_MAP: false,
    IGNORE_IN_SCREENSHOT: false,
    KI67: false,
    MEDICALNEURONETS_CRC: false,
    MINIMAP: false,
    MITOSIS: false,
    PV_PROSTATE: false,
    SEARCH_MORPHOLOGY: false,
    SEGMENTATION: false,
    SLIDE_LABEL: false,
    VALIDATION: false,
  },
  label: {},
  leftPanelType: 'SLIDES',
  leftPanelWidth: undefined,
  liteContextMenuVisibility: true,
  openViewers: ['A'],
  panelInFlex: undefined,
  rightPanelType: undefined,
  selectWorkspaceModal: false,
  slideThumbnailHover: undefined,
  sumpDuplicateBarcodeShowNumber: '',
  sumpFilters: {
    ATTACHED_TO_CASE: DEFAULT_SUMP_FILTERS,
    AWAITING_CASE: DEFAULT_SUMP_FILTERS,
    DELETED: DEFAULT_SUMP_FILTERS,
    DUPLICATE: DEFAULT_SUMP_FILTERS,
    NOT_ATTACHED_TO_CASE: DEFAULT_SUMP_FILTERS,
    SEARCH: DEFAULT_SUMP_FILTERS,
  },
  tasksFilters: {
    page: 0,
    size: 10,
  },
  thumbnailMenuPosition: [],
  tools: {
    ARTEFACTS: { isVisible: false, position: [0, 0] },
    HEAT_MAP: { isVisible: false, position: [0, 0] },
    KI67: { isVisible: false, position: [0, 0] },
    MEDICALNEURONETS_CRC: { isVisible: false, position: [0, 0] },
    MITOSIS: { isVisible: false, position: [0, 0] },
    OVERVIEW: { isVisible: true, position: [0, 0] },
    PV_PROSTATE: { isVisible: false, position: [0, 0] },
    SEARCH_MORPHOLOGY: { isVisible: false, position: [0, 0] },
    SEGMENTATION: { isVisible: false, position: [0, 0] },
    SLIDE_INFO: { isVisible: false, position: [0, 0] },
    SLIDE_LABEL: { isVisible: false, position: [0, 0] },
    VALIDATION: {
      isVisible: true,
      position: [0, 0],
    },
  },

  viewerType: 'DEFAULT',
  /** флаг, определяющий, открыто ли контекстное меню подписи "митоз" */
  whetherToOpenObjectsContext: true,
  zIndexPanel: {
    ARTEFACTS: 0,
    HEAT_MAP: 0,
    IGNORE_IN_SCREENSHOT: 0,
    KI67: 0,
    MEDICALNEURONETS_CRC: 0,
    MINIMAP: 0,
    MITOSIS: 0,
    PV_PROSTATE: 0,
    SEARCH_MORPHOLOGY: 0,
    SEGMENTATION: 0,
    SLIDE_LABEL: 0,
    VALIDATION: 0,
  },
}

const getQueryParams = (state: RootState) => queryString.parse(state.router.location.search)

export const viewerPageSlice = createSlice({
  initialState,
  name: 'viewerPageSlice',
  reducers: {
    addCoregActiveViewerId(state, { payload }: PayloadAction<TViewerId>) {
      if (state.coregActiveViewerIds.includes(payload)) return
      else state.coregActiveViewerIds.push(payload)
    },
    closeAllAITools(state) {
      ;(Object.keys(ML_TOOLS_MAP) as Array<keyof Tools>).forEach((tool) => {
        if (state.tools[tool] === undefined) return
        state.tools[tool].isVisible = false
      })
    },
    closeCacheMessage(state: State, { payload }: PayloadAction<boolean>) {
      state.isCacheMessage = !payload
    },
    closeTool(state, { payload }: PayloadAction<TGridInfoTool>) {
      if (state.tools[payload] === undefined) return
      state.tools[payload].isVisible = false
    },
    openObjectsCounting(state) {
      Object.keys(state.tools).forEach((tool) => {
        // Закрываем все панели, кроме миникарты
        if ((tool as TGridInfoTool) === 'OVERVIEW') return
        state.tools[tool as TGridInfoTool].isVisible = false
      })
      state.tools.MITOSIS.isVisible = true
    },
    openTool(state, { payload }: PayloadAction<TGridInfoTool>) {
      if (state.tools[payload] === undefined) return
      state.tools[payload].isVisible = true
    },

    openViewer(state) {
      const [_, nextSet] = getNextViewerIdAndSet(state.openViewers)
      state.openViewers = nextSet as any
    },

    removeCoregActiveViewerId(state, { payload }: PayloadAction<TViewerId>) {
      if (!state.coregActiveViewerIds.includes(payload)) return
      const filtered = state.coregActiveViewerIds.filter((item) => item !== payload)
      state.coregActiveViewerIds = filtered
    },
    resetAllViewersState(state) {
      return {
        ...initialState,
        atlasFilters: state.atlasFilters,
        descriptionsVisibility: state.descriptionsVisibility,
        isMagnetize: state.isMagnetize,
        liteContextMenuVisibility: state.liteContextMenuVisibility,
        sumpFilters: state.sumpFilters,
        tasksFilters: state.tasksFilters,
        tools: state.tools,
      }
    },
    resetAllViewersToInitialState(state) {
      return {
        ...initialState,
        isMagnetize: state.isMagnetize,
        tools: state.tools,
      }
    },
    resetSumpFilters(state, { payload }: PayloadAction<{ key: SUMP_TAB }>) {
      state.sumpFilters[payload.key] = {}
    },
    setAtlasFilters(state, { payload }: PayloadAction<PaginatedAtlasSlidesFilters>) {
      state.atlasFilters = payload
    },
    setCacheMessage(state: State, { payload }: PayloadAction<boolean>) {
      state.isCacheMessage = payload
    },
    setColorsModal(state, { payload }: PayloadAction<boolean>) {
      state.isColorsModalOpen = payload
    },
    setConfigPanel(state, { payload }: PayloadAction<ConfigValues>) {
      state.configPanels = payload
    },
    setCoregActive(state, { payload }: PayloadAction<boolean>) {
      state.coregActive = payload
    },
    setCountingObjectType(state, { payload }: PayloadAction<CountingObjectType | undefined>) {
      state.countingObjectType = payload
    },
    setDescriptionsVisibility(state, { payload }: PayloadAction<boolean>) {
      state.descriptionsVisibility = payload
    },
    setDragging(state, { payload }: PayloadAction<boolean>) {
      state.dragging = payload
    },
    setGeometryTolerance(state, { payload }: PayloadAction<number>) {
      state.geometryTolerance = payload
    },
    setHotkeysModal(state, { payload }: PayloadAction<boolean>) {
      state.isHotkeysModalOpen = payload
    },
    setIsAnyInputFocusing(state, { payload }: PayloadAction<boolean>) {
      state.isAnyInputFocusing = payload
    },
    setIsMagnetize(state, { payload }: PayloadAction<{ id: TGridInfoToolId; value: boolean }>) {
      if (payload.id === null) return
      state.isMagnetize[payload.id] = payload.value
    },
    setIsObjectsDeleting(state, { payload }: PayloadAction<boolean>) {
      state.isObjectsDeleting = payload
    },
    setLeftPanelWidth(state, { payload }: PayloadAction<number | string>) {
      state.leftPanelWidth = payload
    },
    setLiteContextMenuVisibility(state, { payload }: PayloadAction<boolean>) {
      state.liteContextMenuVisibility = payload
    },
    setMitosisLayerInProgress(state, { payload }: PayloadAction<boolean>) {
      state.mitosisLayerInProgress = payload
    },
    setNewSlides: (state, action: PayloadAction<{ [key: number]: SlideInfo }>) => {
      state.label = action.payload
    },
    setOverviewModalWidth(state, { payload }: PayloadAction<number | undefined>) {
      state.overviewModalWidth = payload
    },
    setPanelInFlex(state, { payload }: PayloadAction<{ x: number; y: number; position: number[] }[]>) {
      state.panelInFlex = payload
    },
    setSimilarThumbnailHover(state: State, { payload }: PayloadAction<ISelectedBbox | undefined>) {
      state.similarThumbnailHover = payload
    },
    setSlideThumbnailHover(state: State, { payload }: PayloadAction<ISlide | undefined>) {
      state.slideThumbnailHover = payload
    },
    setSumpDuplicateBarCodeShowNumber(state, { payload }: PayloadAction<string>) {
      state.sumpDuplicateBarcodeShowNumber = payload
    },
    setSumpFilters(state, { payload }: PayloadAction<{ key: SUMP_TAB; filters: PaginatedSampFilters }>) {
      state.sumpFilters[payload.key] = payload.filters
    },
    setTasksFilters(state, { payload }: PayloadAction<PaginatedTasksFilters>) {
      state.tasksFilters = payload
    },
    setThumbnailMenuPosition(state: State, { payload }: PayloadAction<number[]>) {
      state.thumbnailMenuPosition = payload
    },
    setToggleConfigPanel(state, { payload }: PayloadAction<string>) {
      state.configPanels[payload] = !state.configPanels[payload]
    },
    setTool(state, { payload }: PayloadAction<{ tool: TGridInfoTool; value: boolean }>) {
      state.tools[payload.tool].isVisible = payload.value
    },
    setToolPosition(state, { payload }: PayloadAction<{ tool: TGridInfoTool; position: number[] }>) {
      state.tools[payload.tool].position = payload.position
    },
    setViewerIdOnMouseZoom(state, { payload }: PayloadAction<TViewerId | undefined>) {
      if (payload !== state.viewerIdOnMouseZoom) {
        state.viewerIdOnMouseZoom = payload
      }
    },
    setViewerRightPanel(state, { payload }: PayloadAction<ViewerRightPanelType>) {
      state.rightPanelType = payload
    },
    setWhetherToOpenObjectsContext(state, { payload }: PayloadAction<boolean>) {
      state.whetherToOpenObjectsContext = payload
    },
    setZIndexPanel(state, { payload }: PayloadAction<TGridInfoToolId>) {
      const keys = Object.keys(state.zIndexPanel) as TGridInfoToolId[]
      if (payload === null) return
      for (const key of keys) {
        if (key === null) return
        state.zIndexPanel[key] = 0
      }
      state.zIndexPanel[payload] = 1
    },
    showViewerRightPanel(state, { payload }: PayloadAction<ViewerRightPanelType>) {
      state.rightPanelType = payload
    },
    toggleTool(state, { payload }: PayloadAction<TGridInfoTool>) {
      if (state.tools[payload] === undefined) return
      state.tools[payload].isVisible = !state.tools[payload].isVisible
    },
    toggleViewerLeftPanel(state, { payload }: PayloadAction<ViewerLeftPanelType>) {
      if (state.leftPanelType === payload) {
        state.leftPanelType = undefined
      } else {
        state.leftPanelType = payload
      }
    },
    toggleViewerRightPanel(state, { payload }: PayloadAction<ViewerRightPanelType>) {
      if (state.rightPanelType === payload) {
        state.rightPanelType = undefined
      } else {
        state.rightPanelType = payload
      }
    },
  },
})

export const getNextViewerIdAndSet = (openViewers: State['openViewers']) => {
  const diff = difference(VIEWERS_SET, openViewers)
  const next = diff[0]
  const nextSet = [...openViewers, next]
  return [next, nextSet]
}

export const selectUrlCaseId = createSelector(
  [createMatchSelector<any, { caseId: string }>(`/workspace/:workspaceId/viewer/:caseId`)],
  (match) => {
    const caseId = match?.params?.caseId
    return caseId ? Number(caseId) : undefined
  },
)
export const selectUrlWorkspaceId = createSelector(
  [createMatchSelector<any, { workspaceId: string }>(`/workspace/:workspaceId/`)],
  (match) => {
    const workspaceId = match?.params?.workspaceId
    return workspaceId ? Number(workspaceId) : undefined
  },
)
export const selectUrlSlideId = createSelector([(state: RootState) => getQueryParams(state)], (params) => {
  const slideId = params.slideId
  return slideId ? Number(slideId) : undefined
})

export const selectAtlasViewerUrlSlideId = createSelector(
  [createMatchSelector<any, { slideId: string }>('/atlas-viewer/:slideId')],
  (match) => {
    const slideId = match?.params?.slideId
    return slideId ? Number(slideId) : undefined
  },
)

export const selectTasksUrlTaskId = createSelector(
  [createMatchSelector<any, { taskId: string }>('/tasks/:taskId')],
  (match) => {
    const taskId = match?.params?.taskId
    return taskId ? Number(taskId) : undefined
  },
)

export const selectTasksViewerUrlTaskId = createSelector(
  [createMatchSelector<any, { taskId: string }>('/tasks-viewer/:taskId')],
  (match) => {
    const taskId = match?.params?.taskId
    return taskId ? Number(taskId) : undefined
  },
)
export const selectDeepLink = createSelector(
  [createMatchSelector<any, { linkParams: string }>('/deep-link/:linkParams')],
  (match) => {
    const linkParams = match?.params?.linkParams
    return !!linkParams
  },
)
