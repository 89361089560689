import { Dropdown, Menu } from 'antd'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { parseISO } from 'date-fns'
import { BubbleButton } from 'features/cases-management/ui/filters/BubbleButton'
import { useCaseManagementRouteParam } from 'pages/cases-new/CasesManagementRoutes'
import { CasesFiltersKeys, FilterBubbleProps, FilterType, menuConfig } from 'pages/cases-new/lib/common'
import React, { useState } from 'react'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { COLON_WITH_SPACE } from 'shared/text-constants'
import { DateRangePicker } from 'shared/ui/DatePicker'
import { CheckboxElement, IconElement } from 'shared/ui/kit'
import styled from 'styled-components'

import { caseStagesFilters } from './filters'

const DATA_RANGE_BOTTOM = 30
const DATA_RANGE_LEFT = 80

export const FilterBubble: React.FC<FilterBubbleProps> = ({
  activeFilter,
  dateRangeFilter,
  handleDataRangeChange,
  handleFilterChange,
  handleRemoveFilter,
  isFilterActive,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const { currentTab } = useCaseManagementRouteParam()
  const { isCaseRouting } = useSettingsAndUserRoles()

  const handleRemoveFilterAndClose = (key: string) => (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation()
    handleRemoveFilter(key)
    setIsDropdownVisible(false)
  }

  const renderBubbleButtonText = (): string => {
    if (activeFilter.key === CasesFiltersKeys.DATE_REGISTRATION) {
      return dateRangeFilter ? `${dateRangeFilter.caseDateFrom} - ${dateRangeFilter.caseDateTo}` : ''
    }

    const filterCount = activeFilter.activeFilters.length
    if (filterCount === 1) {
      return `${activeFilter.activeFilters[0].filterName}`
    }
    return `${filterCount}`
  }

  const toggleDropdown = () => setIsDropdownVisible((prev) => !prev)

  const subMenuDropdown = (subMenuKey: string) => {
    const subMenu = menuConfig.find((menu) => menu.key === subMenuKey)

    if (!subMenu) return <></>

    if (subMenu.filterType === FilterType.DATA_RANGE) {
      return (
        <StyledMenu>
          <div style={{ position: 'relative' }}>
            <DateRangePicker
              open={isDropdownVisible}
              style={{ bottom: DATA_RANGE_BOTTOM, left: -100, right: 0 }}
              allowClear={false}
              onChange={handleDataRangeChange}
              getPopupContainer={(trigger) => {
                if (trigger?.parentNode && trigger?.parentNode instanceof HTMLElement) {
                  return trigger?.parentNode
                }
                return document.body
              }}
              value={[
                dateRangeFilter?.caseDateFrom ? parseISO(dateRangeFilter?.caseDateFrom) : null,
                dateRangeFilter?.caseDateTo ? parseISO(dateRangeFilter?.caseDateTo) : null,
              ]}
            />
          </div>
        </StyledMenu>
      )
    }

    return (
      <StyledMenu className="bubble-button-submenu">
        {subMenuKey === 'stage'
          ? caseStagesFilters({ currentTab, handleFilterChange, isCaseRouting, isFilterActive })
          : subMenu.items.map((item) => (
              <Menu.Item key={item.key}>
                <CheckboxElement onChange={handleFilterChange(item, subMenu)} checked={isFilterActive(item.key)}>
                  {item.label}
                </CheckboxElement>
              </Menu.Item>
            ))}
      </StyledMenu>
    )
  }

  return (
    <Dropdown
      overlay={subMenuDropdown(activeFilter.key)}
      trigger={['click']}
      visible={isDropdownVisible}
      onVisibleChange={setIsDropdownVisible}
    >
      <BubbleButton
        onClick={toggleDropdown}
        style={{
          border: isDropdownVisible ? '1px solid var(--color-purple)' : '0 solid transparent',
          color: 'var(--color-text-1)',
        }}
      >
        <FilterTitle>
          {activeFilter.title}
          {COLON_WITH_SPACE}
        </FilterTitle>
        <FilterText>{renderBubbleButtonText()}</FilterText>
        <IconElement
          className="cross-icon-bubble-button"
          size={'md'}
          defaultFill={false}
          name="cross16"
          hoverColor="var(--color-text-1)"
          onClick={handleRemoveFilterAndClose(activeFilter.key)}
        />
      </BubbleButton>
    </Dropdown>
  )
}

const StyledMenu = styled(Menu)`
  ${() => modalBackground}
  ${() => modalShadow}
`

const FilterTitle = styled.span`
  font-weight: 600;
`

const FilterText = styled.span`
  margin-left: 3px;
  font-weight: 400;
`
