import { EAllCasesTabType, ECaseTableType, EMyCasesTabType } from 'features/cases-management/types/ECaseTableType'
import { ESortBy, ESortDir, TQueryParams } from 'features/cases-management/types/TCasePagination'
import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'
import { TABS_QUERY_PARAMS_KEY } from 'shared/lib/common/storageKeys'
import { addToLocalStorage, getFromLocalStorage } from 'shared/lib/local-storage'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { ICaseDTO } from 'types/ICase'

type TCaseManagementTabContext = {
  /** Ключи выбранных строк */
  selectedRowKeys: React.Key[]
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
  /** Количество выбранных кейсов */
  selectedCasesCount: number
  /** Обновить выбранные кейсы */
  setSelectedCases: React.Dispatch<React.SetStateAction<ICaseDTO[]>>
  /** Выбранные кейсы чекбоксом */
  selectedCases: ICaseDTO[]
  /** Параметры запросов для табов */
  tabsQueryParams: TQueryParams
  setTabsQueryParams: React.Dispatch<React.SetStateAction<TQueryParams>>
}

const getQueryParamsInitial = (isCaseRouting?: boolean) => ({
  queryParams: {
    sortBy: ESortBy.CASE_DATE,
    sortDir: isCaseRouting === false ? ESortDir.DESC : ESortDir.ASC,
  },
})

const getTabsQueryParamsInitial = (isCaseRouting?: boolean) => ({
  [ECaseTableType.WORKLIST]: {
    [EMyCasesTabType.ASSIGNED_TO_ME]: getQueryParamsInitial(isCaseRouting),
    [EMyCasesTabType.INCOMING]: getQueryParamsInitial(isCaseRouting),
    [EMyCasesTabType.FAVORITES]: getQueryParamsInitial(isCaseRouting),
  },
  [ECaseTableType.WORKSPACE]: {
    [EAllCasesTabType.ALL_STATUSES]: getQueryParamsInitial(isCaseRouting),
    [EAllCasesTabType.COMPLETED]: getQueryParamsInitial(isCaseRouting),
    [EAllCasesTabType.DELETED]: getQueryParamsInitial(isCaseRouting),
    [EAllCasesTabType.OPENED]: getQueryParamsInitial(isCaseRouting),
  },
})

const getInitialCaseManagementTabContextState = (isCaseRouting?: boolean): TCaseManagementTabContext => ({
  selectedCases: [],
  selectedCasesCount: 0,
  selectedRowKeys: [],
  setSelectedCases: () => {},
  setSelectedRowKeys: () => {},
  setTabsQueryParams: () => {},
  tabsQueryParams: getTabsQueryParamsInitial(isCaseRouting),
})

const CaseManagementTabContext = createContext<TCaseManagementTabContext>(getInitialCaseManagementTabContextState())

export const useCaseManagementTabContext = () => useContext(CaseManagementTabContext)

const CaseManagementTabProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedCases, setSelectedCases] = useState<ICaseDTO[]>([])
  const { isCaseRouting } = useSettingsAndUserRoles()
  const initialCaseManagementTabContextState = getInitialCaseManagementTabContextState(isCaseRouting)
  const [tabsQueryParams, setTabsQueryParams] = useState<TQueryParams>(
    () => getFromLocalStorage(TABS_QUERY_PARAMS_KEY) ?? initialCaseManagementTabContextState.tabsQueryParams,
  )

  const selectedCasesCount = selectedCases.length
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    initialCaseManagementTabContextState.selectedRowKeys,
  )

  useEffect(() => {
    addToLocalStorage(TABS_QUERY_PARAMS_KEY, tabsQueryParams)
  }, [tabsQueryParams])

  return (
    <CaseManagementTabContext.Provider
      value={{
        selectedCases,
        selectedCasesCount,
        selectedRowKeys,
        setSelectedCases,
        setSelectedRowKeys,
        setTabsQueryParams,
        tabsQueryParams,
      }}
    >
      {children}
    </CaseManagementTabContext.Provider>
  )
}

export default CaseManagementTabProvider
