import { useTypedSelector } from 'app/redux/lib/selector'
import { useSlideQuery } from 'entities/slide'
import { useTaskQuery } from 'entities/tasks/api/query'
import { HotkeysModal } from 'features/hotkeys'
import { isChatAccess } from 'features/support-chat'
import { TOOLBAR_BTN_OPACITY } from 'features/toolbar/lib/constants'
import {
  useOpenViewers,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import {
  selectTasksViewerUrlTaskId,
  selectUrlCaseId,
  TGridInfoTool,
  viewerPageSlice,
} from 'pages/viewer/model/viewerPageSlice'
import { MouseEventHandler, useCallback, useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { isGuestHandler } from 'shared/lib/local-storage'
import { FullScreenButton, IconButtonElement, IconElement, IIconElementProps, TooltipElement } from 'shared/ui/kit'
import { VerticalSeparator } from 'shared/ui/kit/ui/VerticalSeparator'
import styled from 'styled-components'
import ICase from 'types/ICase'
import { RotationToolsPanel } from 'viewer/tools/ui/rotation/RotationToolsPanel'
import { ZoomToolsPanel } from 'viewer/tools/ui/zoom/ZoomToolsPanel'

const ToolbarWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
`

const StyledInfoToolsPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > * {
    margin-right: 12px;
  }
`

const ToolbarButton = styled(IconButtonElement)<{ active?: boolean; disable?: boolean }>`
  color: ${({ active }) => (active ? 'var(--color-purple-light) !important' : 'transparent')};
  opacity: ${({ disable }) => (disable ? TOOLBAR_BTN_OPACITY : undefined)};
`

export const BottomToolbarContainer = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { activeViewerId } = useOpenViewers()
  const urlCaseId = useSelector(selectUrlCaseId)
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const { data: task } = useTaskQuery(taskId)
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, urlCaseId])
  const { slideId, source } = useViewerIdSlideState(activeViewerId)
  const { data: slide } = useSlideQuery({ slideId, source })
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const OVERVIEW = useTypedSelector((state) => state.viewerPage.tools?.OVERVIEW)
  const SLIDE_INFO = useTypedSelector((state) => state.viewerPage.tools?.SLIDE_INFO)
  const SLIDE_LABEL = useTypedSelector((state) => state.viewerPage.tools?.SLIDE_LABEL)
  const isHotkeysModalOpen = useTypedSelector((state) => state.viewerPage.isHotkeysModalOpen)
  const authorities = useTypedSelector((state) => state.user.authorities)
  const isReadOnly = task?.status === 'PAUSED' || caseRecord?.permissionLevel === 'READ_ONLY'
  const isShared = caseRecord?.relation === 'SHARED'
  const isGuest = isGuestHandler(authorities)
  const isDisabledLabel = (isReadOnly && isShared) || slide?.labelUrl === null || isGuest

  const onToggleHandler = (type: TGridInfoTool) => {
    if (type === 'SLIDE_LABEL') {
      !isDisabledLabel ? dispatch(viewerPageSlice.actions.toggleTool(type)) : () => null
    } else {
      dispatch(viewerPageSlice.actions.toggleTool(type))
    }
  }

  const onHotkeysClick = useCallback(() => {
    dispatch(viewerPageSlice.actions.setHotkeysModal(true))
  }, [dispatch])

  const [chatActive, setChatActive] = useState(false)

  useEffect(() => {
    const widget = document.getElementById('__replain_widget_iframe') as HTMLIFrameElement
    let closeLink: HTMLLinkElement | null
    if (widget && widget?.contentWindow) {
      closeLink = widget.contentWindow.document.getElementsByClassName('hide-chat')[0] as HTMLLinkElement
      closeLink && closeLink.addEventListener('click', chatToggle)
    }
    return () => {
      closeLink && closeLink?.removeEventListener('click', chatToggle)
    }
  }, [chatActive])

  const chatToggle = () => {
    setChatActive(!chatActive)
    if (window.ReplainAPI) chatActive ? window.ReplainAPI('close') : window.ReplainAPI('open')
  }

  type DisplayButtonProps = {
    title: string
    iconName: IIconElementProps['name']
    type?: TGridInfoTool
    active?: boolean
    hotkey?: string
    onClick?: MouseEventHandler<HTMLElement>
    disable?: boolean
  }

  const DisplayButton = ({ active, disable, hotkey, iconName, onClick, title, type }: DisplayButtonProps) => {
    const { isFastTravel } = useViewerPageProvided()
    type &&
      hotkey &&
      !isFastTravel &&
      useHotkeys(hotkey, () => {
        onToggleHandler(type)
      })

    return (
      <TooltipElement title={hotkey ? `${title} (${hotkey})` : title}>
        <ToolbarButton
          data-testid={`${iconName.toLowerCase()}-btn`}
          onClick={type ? () => onToggleHandler(type) : onClick}
          icon={<IconElement name={iconName} />}
          active={!disable && active}
          className={`${(type || '').toLowerCase()}-btn`}
          disable={disable}
          disabled={disable}
        />
      </TooltipElement>
    )
  }
  const { t } = useTranslation()
  return (
    <ToolbarWrapper>
      <RotationToolsPanel />
      <StyledInfoToolsPanel>
        <DisplayButton
          title={t('Обзор слайда')}
          type={'OVERVIEW'}
          iconName={'map'}
          active={OVERVIEW?.isVisible}
          hotkey={'N'}
        />
        <DisplayButton
          title={t('Этикетка')}
          type={'SLIDE_LABEL'}
          iconName={'label'}
          active={SLIDE_LABEL?.isVisible}
          disable={isDisabledLabel}
          hotkey={'E'}
        />
        <DisplayButton
          title={t('Информация о слайде')}
          type={'SLIDE_INFO'}
          iconName={'info'}
          active={SLIDE_INFO?.isVisible}
          hotkey={'U'}
        />
        <VerticalSeparator />
        <DisplayButton
          title={t('Горячие клавиши')}
          iconName={'keyboard'}
          active={isHotkeysModalOpen}
          onClick={onHotkeysClick}
        />
        {isChatAccess(currentWorkspace) && (
          <DisplayButton title={t('Чат с поддержкой')} iconName={'support'} active={chatActive} onClick={chatToggle} />
        )}
        <VerticalSeparator />
        <FullScreenButton />
      </StyledInfoToolsPanel>
      <ZoomToolsPanel />
      <HotkeysModal visible={isHotkeysModalOpen} />
    </ToolbarWrapper>
  )
}
