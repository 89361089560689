import Dexie from 'dexie'

const db = new Dexie('database')

/** Версия для базы */
const version = 2

db.version(version).stores({
  dictionaries: 'id, data, hash',
})

export default db
