import { useTypedSelector } from 'app/redux/lib/selector'
import { AUTOCOMPLETE_MIN_LENGTH, SEARCH_BUFFER } from 'pages/sump/lib/constants'
import { StompClientContext, useSubscription } from 'processes/stomp'
import { WsResponseSumpSearchList } from 'processes/stomp/types'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'shared/lib/hooks'
import { SearchElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { useOnClickOutside } from 'viewer/map/layers/annotations/lib/hooks/useOnClickOutside'

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`

const Options = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  min-height: 32px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-3-full);
  border-radius: 16px;
  word-break: break-word;
`

const Option = styled.div`
  width: 100%;
  padding: 6px 8px;
  &:hover {
    background: var(--color-bg-4);
    cursor: pointer;
  }
`

type Props = {
  /** функция установки строки поиска */
  setSearchString: (value: string) => void
  /** строка поиска */
  searchQuery: string
}

export const SumpAutocompleteSearchContainer = ({ searchQuery, setSearchString }: Props) => {
  const debouncedSearchQuery = useDebounce(searchQuery, SEARCH_BUFFER)
  const [autocompleteSearchList, setAutocompleteSearchList] = useState<string[]>([])
  const workspaceId = useTypedSelector((state) => state.workspaces.currentWorkspace)?.workspaceId
  const uuid = useRef<string>(uuidv4())
  /** optionsDisabling is an additional logic for input options basic behavoir */
  const [optionsDisabling, setOptionsDisabling] = useState<boolean>(false)
  const { publish } = useContext(StompClientContext)

  const handleChooseAutocomplete = useCallback(
    (item: string) => {
      setSearchString(item)
      setOptionsDisabling(true)
    },
    [autocompleteSearchList],
  )

  const handleFocus = useCallback(() => {
    autocompleteSearchList.length && setOptionsDisabling(false)
  }, [autocompleteSearchList])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    setSearchString(value)
    if (value.length < AUTOCOMPLETE_MIN_LENGTH) {
      setAutocompleteSearchList([])
    } else {
      setOptionsDisabling(false)
    }
  }

  useSubscription<WsResponseSumpSearchList>(`/user/topic/autocomplete/uploaded-files/${uuid.current}`, (result) => {
    setAutocompleteSearchList(result?.payload)
  })

  useEffect(() => {
    if (debouncedSearchQuery.length > AUTOCOMPLETE_MIN_LENGTH - 1) {
      publish(`/app/workspace/${workspaceId}/uploaded-file/search/${uuid.current}`, { query: debouncedSearchQuery })
    }
    setSearchString(debouncedSearchQuery)
  }, [debouncedSearchQuery])

  const root = useRef<HTMLDivElement | null>(null)

  useOnClickOutside(root, () => {
    setOptionsDisabling(true)
  })
  const { t } = useTranslation()

  return (
    <Wrapper ref={root}>
      <SearchElement
        value={searchQuery}
        onFocus={handleFocus}
        onChange={handleChange}
        placeholder={`${t(`Поиск`)}...`}
      />
      {!!autocompleteSearchList.length && !optionsDisabling && (
        <Options>
          {autocompleteSearchList.map((item) => (
            <Option key={item} onClick={() => handleChooseAutocomplete(item)}>
              {item}
            </Option>
          ))}
        </Options>
      )}
    </Wrapper>
  )
}
