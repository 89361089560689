import { Resizable } from 're-resizable'
import { Direction } from 're-resizable/lib/resizer'
import { useEffect, useState } from 'react'
import { css } from 'styled-components'
import styled from 'styled-components/macro'
import TViewerId from 'types/TViewerId'
import { useSlideMapViewSelector } from 'viewer/container/lib/useViewerSelector'
import {
  INITIAL_SIZES,
  MAX_MINIMAP_SIZE,
  MIN_MINIMAP_SIZE,
  MINIMAP_LONG_SIDE_STORAGE_KEY,
  RESIZABLE_OFFSET,
} from 'viewer/tools/ui/lib/constans'
import { ViewerInfoToolPanelContainer } from 'viewer/tools/ui/ViewerInfoToolPanelContainer'

import OverviewMapContainer from './OverviewMapContainer'

interface IMapSize {
  /** Ширина миникарты */
  width: number
  /** Высота миникарты */
  height: number
}

type Props = {
  /** overviewRef - ссылка на блок миникарты */
  overviewRef: React.RefObject<HTMLDivElement>
  /**  viewerId - id вьювера */
  viewerId: TViewerId
  /** slideId - id слайда в текущем вьювере */
  slideId: number
  /** caseId - id случая */
  caseId: number
  /** initPosition - координаты блока */
  initPosition: number[]
  /** onPositionChange - установка параметров координат блока */
  onPositionChange: (position: number[]) => void
  /** isVisible - отображается ли миникарта */
  isVisible: boolean
}

const StyledResizable = styled(Resizable)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const MARGIN_BORDER = 30

const OverviewToolPanel = ({
  caseId,
  initPosition,
  isVisible,
  onPositionChange,
  overviewRef,
  slideId,
  viewerId,
}: Props) => {
  const [backgroundSizes, setBackgroundSizes] = useState(INITIAL_SIZES)
  const [mapSizes, setMapSizes] = useState(INITIAL_SIZES)
  const [resizeIsActive, setResizeIsActive] = useState(false)
  const [workSpaceHeight, setWorkSpaceHeight] = useState<number>(0)
  const [workSpaceWidth, setWorkSpaceWidth] = useState<number>(0)
  const getParentBlock = () => overviewRef?.current?.parentElement?.parentElement?.parentElement?.parentElement

  const getParentBlockSizes = () => {
    const parentBlock = getParentBlock()

    return [parentBlock?.getBoundingClientRect().width || 0, parentBlock?.getBoundingClientRect().height || 0]
  }

  const [blockedWidth, setBlockedWidth] = useState<string | undefined>()
  const [position, setPosition] = useState<number[]>(initPosition || [0, 0])
  const parentBlockHeight = getParentBlockSizes()[1]
  const parentBlockWidth = getParentBlockSizes()[0]
  const parentBlockBottomCoordinate = getParentBlock()?.getBoundingClientRect().bottom || 0
  const parentBlockRightCoordinate = getParentBlock()?.getBoundingClientRect().right || 0
  const { viewRotation } = useSlideMapViewSelector({ slideId, viewerId })

  /** Сохраняем в локалсторейдж вертикальную высоту и горизонтальную ширину миникарты. Используем для запоминания размеров*/
  const localStorageMinimapSizeSync = ({ height, width }: IMapSize) => {
    // Для вертикального и квадратного слайда запоминаем высоту
    if (height >= width) {
      localStorage.setItem(MINIMAP_LONG_SIDE_STORAGE_KEY, String(height))
    } else {
      // для горизонтального слайда запоминаем ширину
      localStorage.setItem(MINIMAP_LONG_SIDE_STORAGE_KEY, String(width))
    }
  }

  const resizeOverviewTool = (el?: HTMLElement) => {
    const editBoxElement = document.getElementsByClassName('edit-box')[0]
    const toolElement = el || editBoxElement?.parentElement

    // @ts-ignore
    const { height, left, top, width } = toolElement.getBoundingClientRect()
    const bottomCoordinate = height + top
    const rightCoordinate = width + left

    if (
      bottomCoordinate > parentBlockBottomCoordinate - MARGIN_BORDER ||
      rightCoordinate > parentBlockRightCoordinate - MARGIN_BORDER
    ) {
      return setBlockedWidth(toolElement?.style?.width)
    } else setBlockedWidth(undefined)

    // перепроверяем размер элемента на соответствие допустимым параметрам
    const backgroundWidth = backgroundSizes[0] > MAX_MINIMAP_SIZE ? MAX_MINIMAP_SIZE : backgroundSizes[0]
    const trueDelta = backgroundWidth / width
    localStorageMinimapSizeSync({ height, width })

    // уменьшение ререндеров из-за повторных ресетов значений
    if (backgroundWidth !== width || backgroundSizes[1] !== height) {
      setBackgroundSizes([width, height])
      setMapSizes([mapSizes[0] / trueDelta, mapSizes[1] / trueDelta])
    }
  }

  const onResizeHandler = (w: MouseEvent | TouchEvent, direction: Direction, el: HTMLElement) => resizeOverviewTool(el)

  const onRotatePositionHandler = () => {
    if (!overviewRef?.current) return
    const { height, left, top, width } = overviewRef.current.getBoundingClientRect()
    const bottomCoordinate = height + top
    const rightCoordinate = width + left

    //parentBlockBottomCoordinate must be !== 0
    if (parentBlockBottomCoordinate && bottomCoordinate > parentBlockBottomCoordinate) {
      const delta = bottomCoordinate - parentBlockBottomCoordinate
      return setPosition([position[0], position[1] - delta])
    }

    //parentBlockRightCoordinate must be !== 0
    if (parentBlockRightCoordinate && rightCoordinate > parentBlockRightCoordinate) {
      const delta = rightCoordinate - parentBlockRightCoordinate
      return setPosition([position[0] - delta, position[1]])
    }
  }

  const updateSizes = () => {
    const parentBlockWidth = getParentBlockSizes()[0]
    const parentBlockHeight = getParentBlockSizes()[1]

    setWorkSpaceWidth(Number(blockedWidth) || parentBlockWidth)
    setWorkSpaceHeight(parentBlockHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSizes)
    return () => window.removeEventListener('resize', updateSizes)
  }, [])

  useEffect(() => {
    setWorkSpaceWidth(Number(blockedWidth) || parentBlockWidth)
  }, [blockedWidth, parentBlockWidth])

  useEffect(() => {
    onRotatePositionHandler()
    setBlockedWidth(undefined)
    setWorkSpaceHeight(parentBlockHeight)
  }, [viewRotation, parentBlockHeight])

  return (
    <ViewerInfoToolPanelContainer
      id="MINIMAP"
      panelRef={overviewRef}
      initPosition={initPosition}
      onPositionChange={onPositionChange}
      setSizes={setMapSizes}
      type={'OVERVIEW'}
      disableDrag={resizeIsActive}
      mapSizes={backgroundSizes}
      setPosition={setPosition}
      position={position}
      style={css`
        display: ${isVisible ? `block` : `none`};
      `}
    >
      <StyledResizable
        minWidth={MIN_MINIMAP_SIZE}
        minHeight={MIN_MINIMAP_SIZE}
        maxWidth={
          MAX_MINIMAP_SIZE < workSpaceWidth - RESIZABLE_OFFSET ? MAX_MINIMAP_SIZE : workSpaceWidth - RESIZABLE_OFFSET
        }
        maxHeight={
          MAX_MINIMAP_SIZE < workSpaceHeight - RESIZABLE_OFFSET ? MAX_MINIMAP_SIZE : workSpaceHeight - RESIZABLE_OFFSET
        }
        lockAspectRatio={true}
        size={{ height: backgroundSizes[1], width: backgroundSizes[0] }}
        onResizeStart={() => setResizeIsActive(true)}
        onResizeStop={() => setResizeIsActive(false)}
        handleStyles={{ bottomRight: { bottom: -5, right: -5, zIndex: 10 } }}
        onResize={onResizeHandler}
        enable={{ bottomRight: true }}
      >
        <OverviewMapContainer
          backGroundSizes={backgroundSizes}
          setBackGroundSizes={setBackgroundSizes}
          mapSizes={mapSizes}
          setMapSizes={setMapSizes}
          caseId={caseId}
          slideId={slideId}
          viewerId={viewerId}
          viewRotation={viewRotation}
          resizeIsActive={resizeIsActive}
        />
      </StyledResizable>
    </ViewerInfoToolPanelContainer>
  )
}

export default OverviewToolPanel
