import { useTypedSelector } from 'app/redux/lib/selector'
import { useAnnotationQuery } from 'features/annotations'
import { Feature } from 'ol'
import { Geometry } from 'ol/geom'
import Popup from 'ol-ext/overlay/Popup'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { AnnotationType } from 'types/IAnnotations'
import { IMapOl } from 'types/IMapOl'
import ISlide, { IGroupType } from 'types/ISlide'
import TViewerId from 'types/TViewerId'
import { ObjectsCountingContext } from 'viewer/map/layers/objects-counting/ui/ObjectsCountingContext'
import { coordinatesHandler, getArrowDegree, getArrowPositionFromDegree } from 'viewer/map/lib/utils'

import { getDescriptionRawHTML, getMitosisData } from './lib/helpers'

/**
 * Props for AnnotationDescriptionLayer component
 */
type Props = {
  /** inctance of ol map */
  map: IMapOl
  /** current viewer id (TViewerId) */
  viewerId: TViewerId
  /** array of annotation features */
  features: Feature<Geometry>[]
  /** slide mppX */
  mppX: number
}

const AnnotationDescriptionLayer = ({ features, map, mppX, viewerId }: Props) => {
  const { caseId, slideGroupType, slideId } = useViewerIdSlideState(viewerId)
  /** Список типов аннотаций для которых показывается попап независимо от наличия описания */
  const annotationWithPopupVisibles = ['MITOSIS', 'RULER', 'OBJECTS']

  return (
    <>
      {features.map((f) => {
        const annotationType: AnnotationType = f.get('annotation_type')

        if (f.get('description') || annotationWithPopupVisibles.includes(annotationType)) {
          return (
            <FeaturePopup
              key={f.get('slideAnnotationId')}
              annotationId={f.get('slideAnnotationId')}
              caseId={caseId}
              feature={f}
              map={map}
              slideGroupType={slideGroupType}
              mppX={mppX}
              slideId={slideId}
            />
          )
        }
      })}
    </>
  )
}

type FeaturePopupProps = {
  /** current annotation feature */
  feature: Feature<Geometry>
  caseId: number
  /** inctance of ol map */
  map: IMapOl
  /** enum of slide type MACRO or MICRO */
  slideGroupType: IGroupType
  /** slide mppX */
  mppX: number
  /** current slide id */
  slideId: number
  /** current annotation id */
  annotationId: number
}
const FeaturePopup = ({ annotationId, caseId, feature, map, mppX, slideGroupType, slideId }: FeaturePopupProps) => {
  const queryClient = useQueryClient()
  const popupRef = useRef<Popup>()
  const { descriptionsVisibility } = useTypedSelector((state) => state.viewerPage)
  const { annotationsIsVisible, currentAnnotationByClass, currentAnnotationUsers } = useTypedSelector(
    (state) => state.annotations,
  )
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  const isDescriptionVisible =
    descriptionsVisibility &&
    annotationsIsVisible?.includes(feature?.get('slideAnnotationId')) &&
    (!currentAnnotationByClass || currentAnnotationByClass?.includes(feature?.get('class'))) &&
    (!currentAnnotationUsers || currentAnnotationUsers?.includes(feature?.get('user')))

  const { data: annotation } = useAnnotationQuery(caseId, slideId, annotationId)
  const slide = queryClient.getQueryData<ISlide>([QUERY_TYPE.SLIDE, slideId])
  const isVisibleAreaLabel = !!slide?.slideMetadata?.commonMetadata?.mppX

  const { getTotalMitosisCount } = useContext(ObjectsCountingContext)
  const mitosisTotalCount = getTotalMitosisCount()
  const mitosisData = useMemo(() => getMitosisData(annotation), [annotation, mitosisTotalCount])
  const createPopup = () => {
    //@ts-ignore
    const popup = new Popup({
      popupClass: 'custom-ol-draw-sm-popup',
    })
    map.addOverlay(popup)
    popupRef.current = popup
  }

  const showPopup = (f: Feature<Geometry>) => {
    const geometry = f.getGeometry()
    if (geometry === undefined) return
    const type = f.get('annotation_type')
    const description = f.get('description') || annotation?.caption
    const isArrow = type === AnnotationType.ARROW
    const descriptionHTML = getDescriptionRawHTML(
      // @ts-ignore
      slideGroupType === 'MACRO' ? undefined : geometry,
      isVisibleAreaLabel,
      type,
      description,
      mppX,
      mitosisData,
    )
    try {
      popupRef.current?.show(coordinatesHandler(geometry, isArrow), descriptionHTML)
      if (isArrow) {
        popupRef.current?.setPositioning(getArrowPositionFromDegree(getArrowDegree(geometry)))
      }
    } catch (e) {
      removePopup()
    }
  }

  const removePopup = () => {
    if (popupRef.current) {
      popupRef.current.hide()
      map.removeOverlay(popupRef.current)
      popupRef.current = undefined
    }
  }

  const updatePopup = (f: Feature<Geometry>) => {
    removePopup()
    createPopup()
    showPopup(f)
  }

  const rendercomplete = () => {
    setIsFirstRender(false)
  }

  const change = () => {
    isDescriptionVisible && updatePopup(feature)
  }

  useEffect(() => {
    map?.once('rendercomplete', rendercomplete)
    return () => {
      setIsFirstRender(true)
      map?.un('rendercomplete', rendercomplete)
    }
  }, [slideId])

  useEffect(
    () => () => {
      removePopup()
    },
    [],
  )

  useEffect(() => {
    feature?.on('change', change)
    return () => {
      feature?.un('change', change)
    }
  }, [feature, isDescriptionVisible])

  useEffect(() => {
    // TODO: пока что не удалять
    // if (isFirstRender) {
    //   createPopup()
    // showPopup(feature)
    //   return
    // }
    isDescriptionVisible ? change() : removePopup()
  }, [isDescriptionVisible, isFirstRender, mitosisData, feature])

  return null
}

export default AnnotationDescriptionLayer
