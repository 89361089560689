import { Feature } from 'ol'
import { IAnnotation } from 'types/IAnnotations'
/**
 * @param annotation - Аннотация
 * @returns Сохранена ли аннотация только локально
 */
export const checkLocalAnnotation = (annotation: IAnnotation): boolean => annotation.slideAnnotationId < 0

/**
 * @param feature - Фича аннотации
 * @returns Сохранена ли аннотация только локально
 */
export const checkLocalAnnotationFromFeature = (feature: Feature<any>): boolean => feature.get('slideAnnotationId') < 0
