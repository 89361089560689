import { Divider } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useCaseQuery } from 'features/cases'
import { useCaseReferencesQuery } from 'features/cases/api/query'
import { viewerPageSlice } from 'pages/viewer'
import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CONFIG_CASE_PANELS_KEY } from 'shared/lib/common/storageKeys'
import { getWorkspaceIdUrl } from 'shared/lib/workspaces'
import { IconElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { getSlideSummary, isAllSlides } from 'viewer/map/layers/slide/helpers'

import { BlockContainer } from './BlockContatiner'
import { ACTION_SHOW_LABELS, ACTION_SHOW_NAME_SLIDE, ACTION_SHOW_PREVIEW } from './common/utils'
import { useSlidesList } from './hooks'
import { slideBlocksSlice } from './model/BlocksSlice'
import TusFileLoaderContainer from './TusFileLoaderContainer'
import AdaptiveThumbnail from './ui/AdaptiveThumbnail'

type Props = {
  caseId: number
}

const Wrapper = styled.div`
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TitleCount = styled(TitleElement)`
  font-size: 12px !important;
  line-height: 16px !important;
`
const ContainerCount = styled.div`
  display: flex;
  padding: 4px 0;
`
const StyledIcon = styled(IconElement)<{ isAllSlides?: boolean }>`
  margin-right: 4px;
  color: ${({ isAllSlides }) => (isAllSlides ? 'var(--Light-Text-text-3, #595b5d)' : '#DC524C')};
`

const AdaptiveThumbnailListContainer = memo(({ caseId }: Props) => {
  const dispatch = useDispatch()
  const workspaceId =
    useTypedSelector((state) => state.workspaces.currentWorkspace)?.workspaceId ||
    getWorkspaceIdUrl(window.location.href)
  const { data: caseResult } = useCaseQuery({ caseId: Number(caseId), source: 'PLATFORM' })
  const { data: caseReferencesSlides } = useCaseReferencesQuery({
    caseId: caseId,
    workspaceId: Number(workspaceId),
  })
  const { blockDictionary, blocksSorts, emptyBlocks, filesList, macroSlidesIds } = useSlidesList(caseId)

  useEffect(() => {
    const configString = localStorage.getItem(CONFIG_CASE_PANELS_KEY)
    if (configString) {
      const configParse = JSON.parse(configString)
      const configCasePanels = {
        [ACTION_SHOW_LABELS]: configParse[ACTION_SHOW_LABELS],
        [ACTION_SHOW_NAME_SLIDE]: configParse[ACTION_SHOW_NAME_SLIDE],
        [ACTION_SHOW_PREVIEW]: configParse[ACTION_SHOW_PREVIEW],
      }
      dispatch(viewerPageSlice.actions.setConfigPanel(configCasePanels))
    }
  }, [])

  useEffect(
    () => () => {
      dispatch(slideBlocksSlice.actions.setInitialState())
    },
    [],
  )

  const slideSummary = getSlideSummary(caseResult, caseReferencesSlides)
  const areAllSlidesEqual = isAllSlides(caseResult, caseReferencesSlides)
  return (
    <div>
      <ContainerCount style={{ display: 'flex', padding: '4px 0' }}>
        <StyledIcon isAllSlides={areAllSlidesEqual} size={'md'} name={'imagesSmall'} />
        {!!slideSummary && <TitleCount>{slideSummary}</TitleCount>}
      </ContainerCount>
      <Divider />
      {blocksSorts.map((key, i) => {
        const typedKey = key as keyof typeof blockDictionary
        const blocks = blockDictionary[typedKey]
        dispatch(slideBlocksSlice.actions.setSlideBlockState({ block: typedKey, key: i }))
        return <BlockContainer i={i} blocks={blocks} caseId={caseId} typedKey={typedKey} key={key} />
      })}
      {emptyBlocks?.length !== 0 && (
        <BlockContainer i={9999} blocks={emptyBlocks} caseId={caseId} typedKey={'emptyBlocks'} emptyBlocks />
      )}
      {macroSlidesIds?.length !== 0 && (
        <BlockContainer i={10000} blocks={macroSlidesIds} caseId={caseId} typedKey={'macroSlidesIds'} macroSlidesIds />
      )}
      {filesList.length > 0 && (
        <Wrapper>
          {filesList.map((fileData) => (
            <AdaptiveThumbnail activeCursor="A" key={fileData}>
              <TusFileLoaderContainer tusFileId={fileData} key={fileData} />
            </AdaptiveThumbnail>
          ))}
        </Wrapper>
      )}
    </div>
  )
})

export default AdaptiveThumbnailListContainer
