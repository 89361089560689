import { notices } from 'features/notices'
import { SUMP_TAB } from 'pages/sump/ui/SumpTopActions'
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import IListOfItems from 'types/api/IListOfItems'
import { ISumpSlide } from 'types/ISumpSlide'

import uploadedFilesService, {
  AttachUploadedFilesDataType,
  DeleteUploadFilesDataType,
  SumpAvailableFilters,
} from './service'

type SumpSlidesFilters = {
  createdDateFrom?: string
  createdDateTo?: string
  uploadedFileAttachmentStates?: string
  uploadedFileTypes?: string
  attachedToCaseDateFrom?: string
  attachedToCaseDateTo?: string
  deletedDateFrom?: string
  deletedDateTo?: string
  ascDirection?: boolean | null
  sortByType?: string
  deletedByUserIds?: number[]
  attachedToCaseByUserIds?: number[]
}

export type PaginatedSampFilters = {
  page?: number
  size?: number
} & SumpSlidesFilters

export const useUploadedFilesQuery = (uploadedFileTab: string, filters: PaginatedSampFilters, query: string) => {
  const queryClient = useQueryClient()
  return useQuery<IListOfItems<ISumpSlide> | undefined, unknown>(
    [
      QUERY_TYPE.UPLOADED_FILE,
      {
        uploadedFileTab,
        ...filters,
        query,
      },
    ],
    async (data: any) => {
      if (!data || !filters || query) return
      const result = await uploadedFilesService.fetchUploadedFiles({
        uploadedFileTab,
        ...filters,
        query,
        //@ts-ignore
        uploadedFileAttachmentStates: filters.uploadedFileAttachmentStates?.split(/[\s,]|[\s|]/).join(),
      })
      if (!result) return
      result.content.forEach((record) => {
        const cacheRecord = queryClient.getQueryData<ISumpSlide>([QUERY_TYPE.UPLOADED_FILE, record.uploadedFileId])
        if (!cacheRecord) {
          queryClient.setQueryData<ISumpSlide>([QUERY_TYPE.UPLOADED_FILE, record.uploadedFileId], () => record)
        }
      })
      return result
    },
  )
}

/**
 * Хук для загрузки файлов из всех статусов по поиску в отстойнике
 *
 * @param filters - фильтры страницы
 * @param query - поисковой запрос
 * @param searchMinLength - минимальная длина строки поиска
 */
export const useUploadedAllFilesQuery = (filters: PaginatedSampFilters, query: string, searchMinLength: number) =>
  useQuery<IListOfItems<ISumpSlide> | undefined, unknown>(
    [
      QUERY_TYPE.UPLOADED_ALL_FILES,
      {
        ...filters,
        query,
      },
    ],
    async () =>
      query.length > searchMinLength - 1
        ? uploadedFilesService.fetchUploadedFiles({
            ...filters,
            query,
          })
        : undefined,
  )

/**
 * Хук для загрузки файлов-дубликатов по штрихкоду
 *
 * @param barcode - штрихкод
 */
export const useUploadedDuplicateFilesQuery = (barcode: string) =>
  useQuery<IListOfItems<ISumpSlide> | undefined, unknown>(
    [QUERY_TYPE.UPLOADED_DUPLICATE_FILES, { query: barcode }],
    async () =>
      barcode
        ? uploadedFilesService.fetchUploadedDuplicateFiles({
            query: barcode,
            uploadedFileAttachmentStates: 'DUPLICATE',
            uploadedFileTab: 'NOT_ATTACHED_TO_CASE',
          })
        : undefined,
  )

export const useAvailableFiltersQuery = (uploadedFileTab?: SUMP_TAB) =>
  useQuery<SumpAvailableFilters | undefined, unknown>([QUERY_TYPE.SUMP_FILTERS], async (data: any) => {
    if (!data) return
    return uploadedFilesService.fetchAvailableFilters({
      uploadedFileTab: uploadedFileTab || '',
    })
  })

export type AttachUploadedFilesQueryType = {
  caseName?: string
} & AttachUploadedFilesDataType

export const useAttachUploadedFiles = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: AttachUploadedFilesQueryType) => {
      const notificationKey = 'attach-' + data.targetCaseId
      await uploadedFilesService.attachUploadedFiles(data)
      notices.openOnLinkingSlidesNotification({
        caseName: data.caseName,
        count: data.uploadedFileIds.length,
        key: notificationKey,
      })
    },
    {
      onSuccess: (e, data) => {
        queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_FILE])
        queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_ALL_FILES])
        queryClient.invalidateQueries([QUERY_TYPE.CASE, data.targetCaseId])
        queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_DUPLICATE_FILES])
        queryClient.invalidateQueries([QUERY_TYPE.SUMP_FILES_COUNT])
      },
    },
  )
}

export const useDeleteUploadFiles = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: DeleteUploadFilesDataType) => {
      const notificationKey = 'upload-slide-removed'
      await uploadedFilesService.deletedUploadedFiles(data)
      notices.openOnSumpSlideRemoveNotification({ key: notificationKey })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_FILE])
        queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_ALL_FILES])
        queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_DUPLICATE_FILES])
        queryClient.invalidateQueries([QUERY_TYPE.SUMP_FILES_COUNT])
      },
    },
  )
}

export const useMoveToTrash = (
  options?: UseMutationOptions<void, unknown, { uploadedFileIds: number[] }, unknown> | undefined,
) => {
  const queryClient = useQueryClient()
  return useMutation(async ({ uploadedFileIds }) => {
    await uploadedFilesService.moveToTrash({ uploadedFileIds })
    await queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_FILE])
    await queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_ALL_FILES])
    await queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_DUPLICATE_FILES])
    await queryClient.invalidateQueries([QUERY_TYPE.SUMP_FILES_COUNT])
  }, options)
}

export const useRestoreBinTrash = (
  options?: UseMutationOptions<void, unknown, { uploadedFileIds: number[] }, unknown> | undefined,
) => {
  const queryClient = useQueryClient()
  return useMutation(async ({ uploadedFileIds }) => {
    await uploadedFilesService.restoreFromTrash({ uploadedFileIds })
    await queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_FILE])
    await queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_ALL_FILES])
    await queryClient.invalidateQueries([QUERY_TYPE.UPLOADED_DUPLICATE_FILES])
    await queryClient.invalidateQueries([QUERY_TYPE.SUMP_FILES_COUNT])
  }, options)
}

export const useFilesCountQuery = () => {
  const queryClient = useQueryClient()
  return useQuery([QUERY_TYPE.SUMP_FILES_COUNT], async (data: any) => {
    if (!data) return
    const result = await uploadedFilesService.fetchFilesCount()
    queryClient.setQueryData(QUERY_TYPE.SUMP_FILES_COUNT, result)
    return result
  })
}
