import { Divider } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { ThemeContext } from 'app/styled/ThemeProvider'
import helpImage from 'assets/thumbnails/searchSlideHelpImage.png'
import { SiteMultiSelectContainer } from 'entities/site'
import { useSlideQuery } from 'entities/slide'
import { notices } from 'features/notices'
import { searchSlice } from 'features/search/model/searchSlice'
import { viewerPageSlice } from 'pages/viewer'
import { useOpenViewers, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { ChangeEvent, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ButtonElement, CheckboxElement, InputElement, SegmentElement, SpaceElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { DictionaryItem } from 'types/IDictionary'
import ISimilarRegion from 'types/ISimilarRegion'
import { useViewerDispatch, viewerSlice } from 'viewer/container'

const HelpBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  background-color: var(--color-bg-3);
  padding: 8px;
  border-radius: 5px;
`

const HelpText = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: var(--color-text-1);
  max-width: 180px;
  margin-left: 8px;
`

const SearchFormWrapper = styled.div``

const AgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const DashContainer = styled.div`
  padding: 0 8px;
`
const SearchButton = styled(ButtonElement)`
  width: 100%;
`

const HelpImageWrapper = styled.div`
  border-radius: 3px;
  width: 47px;
  height: 47px;
`

const DEFAULT_MIN_AGE = 25
const DEFAULT_MAX_AGE = 65

const SearchForm = () => {
  const theme = useContext(ThemeContext)
  const [ageChecked, setAgeChecked] = useState(false)
  const [sexChecked, setSexChecked] = useState(false)
  const [gender, setGender] = useState<'MALE' | 'FEMALE'>('MALE')
  const [minAge, setMinAge] = useState(DEFAULT_MIN_AGE)
  const [maxAge, setMaxAge] = useState(DEFAULT_MAX_AGE)
  const [sites, setSites] = useState<DictionaryItem[]>([])
  const { activeViewerId: viewerId } = useOpenViewers()
  const { caseId, slideId, source } = useViewerIdSlideState(viewerId)
  const mainSelectedBbox = useTypedSelector((state) => state.search.mainSelectedBbox)
  const dispatch = useDispatch()
  const viewerDispatch = useViewerDispatch(viewerId)
  const { data: slide } = useSlideQuery({ caseId, slideId, source })
  const defaultModel = slide?.searchModels?.find((m) => m.default)
  const { t } = useTranslation()

  const bboxImage = mainSelectedBbox ? mainSelectedBbox.thumbnailUrl : helpImage

  const genderOptions = [
    {
      label: t('Мужской'),
      value: 'MALE',
    },
    {
      label: t('Женский'),
      value: 'FEMALE',
    },
  ]

  const ageCheckHandler = () => {
    setAgeChecked((prev) => !prev)
  }

  const sexCheckHandler = () => {
    setSexChecked((prev) => !prev)
  }

  const onMinAgeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMinAge(e.target.value as unknown as number)
  }
  const onMaxAgeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMaxAge(e.target.value as unknown as number)
  }

  const search = () => {
    if (!mainSelectedBbox) {
      notices.info({ key: 'select search area', message: t('Выберите область') })
      return
    }
    if (slide) {
      const region: ISimilarRegion = {
        age: ageChecked ? { from: minAge, to: maxAge } : null,
        bbox: mainSelectedBbox.bbox,
        bboxId: mainSelectedBbox.bboxId,
        caseId,
        modelName: defaultModel?.model,
        modelVersion: defaultModel?.version,
        searchSources: ['PLATFORM', 'ATLAS'],
        sex: sexChecked ? gender : undefined,
        siteIds: sites.map((item) => item.id),
        slideId,
        source,
      }
      dispatch(searchSlice.actions.setMainSelectedSimilarRegion(region))
      dispatch(searchSlice.actions.setIsSearching(true))
    }
    viewerDispatch(viewerSlice.actions.setGridIsVisible(false))
    viewerDispatch(viewerPageSlice.actions.closeTool('SEARCH_MORPHOLOGY'))
  }

  return (
    <SearchFormWrapper>
      <HelpBlock>
        <HelpImageWrapper>
          <img src={bboxImage} style={{ height: '48px', width: '48px' }} alt="searchSimilarSlides" />
        </HelpImageWrapper>
        <HelpText>{t('Выберите область для поиска на изображении')}</HelpText>
      </HelpBlock>
      <SpaceElement size={16} />
      <CheckboxElement checked={ageChecked} onClick={ageCheckHandler}>
        {t('Возраст')}
      </CheckboxElement>
      <SpaceElement size={8} />
      {ageChecked && (
        <>
          <AgeContainer>
            <InputElement suffix={<span>{t('лет')}</span>} value={minAge} onChange={onMinAgeChange} />
            <DashContainer>—</DashContainer>
            <InputElement suffix={<span>{t('лет')}</span>} value={maxAge} onChange={onMaxAgeChange} />
          </AgeContainer>
          <SpaceElement size={16} />
        </>
      )}
      <CheckboxElement checked={sexChecked} onClick={sexCheckHandler}>
        {t('Пол')}
      </CheckboxElement>
      <SpaceElement size={8} />
      {sexChecked && (
        <>
          <AgeContainer>
            <SegmentElement
              colorTheme={theme.theme}
              style={{ width: '100%' }}
              value={gender}
              options={genderOptions}
              onChange={(e) => setGender(e.target.value)}
            />
          </AgeContainer>
          <SpaceElement size={8} />
        </>
      )}
      <Divider />
      <SpaceElement size={8} />
      <SiteMultiSelectContainer value={sites} onChange={setSites} />
      <SpaceElement size={8} />
      <Divider />
      <SpaceElement size={8} />
      <SearchButton onClick={search}>{t('Начать поиск')}</SearchButton>
    </SearchFormWrapper>
  )
}

export default SearchForm
