import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { getBarcodeFailText } from 'pages/sump/lib/renderHandlers'
import DuplicateShowBtn from 'pages/sump/ui/tables/ui/button/DuplicateShowBtn'
import { useOpenViewers } from 'pages/viewer/lib/common/ViewerPageProvider'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouterLink } from 'shared/ui/kit'
import styled from 'styled-components'
import { ISumpSlide } from 'types/ISumpSlide'
import { useViewerDispatch } from 'viewer/container'

const StyledRouterSpan = styled.span`
  .ant-typography:hover {
    opacity: 0.7;
  }
`

export const ColumnProblemText = ({
  conversionFailureCodeDzi,
  conversionFailureCodeTiff,
  duplicateOfSlide = {
    barcode: '',
    caseId: 0,
    slideId: 0,
  },
  duplicatesCount = 0,
  state = 'SUCCESS',
}: Partial<ISumpSlide>) => {
  const { t } = useTranslation()
  const { activeViewerId: viewerId } = useOpenViewers()
  const viewerDispatch = useViewerDispatch(viewerId)
  const { barcode, caseId, slideId } = duplicateOfSlide || {}
  const workspaceId = useCurrentWorkspaceId()

  const mrxsError = conversionFailureCodeDzi || conversionFailureCodeTiff ? t('Файл поврежден') : undefined

  return (
    <>
      <span>{mrxsError || getBarcodeFailText(state)}</span>
      {!!slideId && !!caseId && (
        <>
          <span> • </span>
          <StyledRouterSpan>
            <RouterLink to={`/workspace/${workspaceId}/viewer/${caseId}?slideId=${slideId}`} target="_blank">
              {t('Открыть оригинал')}
            </RouterLink>
          </StyledRouterSpan>
        </>
      )}
      {duplicatesCount > 1 && !!barcode && (
        <DuplicateShowBtn
          type="link"
          onClick={() => viewerDispatch(viewerPageSlice.actions.setSumpDuplicateBarCodeShowNumber(barcode))}
        >
          {`${t('Все дубли слайда')}: ${duplicatesCount}`}
        </DuplicateShowBtn>
      )}
    </>
  )
}
