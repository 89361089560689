import { Form } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { ThemeContext } from 'app/styled/ThemeProvider'
import { push } from 'connected-react-router'
import { CaseForm, CaseFormDataType, casesSlice, useMutateCase } from 'features/cases'
import { useCaseDocumentsQuery } from 'features/cases/api/query'
import caseService, { IFile } from 'features/cases/api/service'
import { EDistributionTabType } from 'features/cases-management/types/ECaseTableType'
import { NoticesList } from 'features/notifications/NotificationsList'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import ContentLayout from 'pages/cases/ui/ContentLayout'
import { SideRightPanelType } from 'pages/viewer/model/viewerPageSlice'
import React, { RefObject, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { formatDateForDisplay } from 'shared/lib/date'
import { ContentHeaderElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'

type Props = {
  /** Ссылка на кнопку уведомления. */
  notificationButton?: RefObject<HTMLDivElement | null>
}

export const CreateRoute = ({ notificationButton }: Props) => {
  const dispatch = useDispatch()
  const workspaceId = useCurrentWorkspaceId()
  const [editableResult, setEditableResult] = useState<ICase>()
  const [initialError, setInitialError] = useState<any>()
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutateCase(editableResult?.caseId || -1)
  const rightPanelType = useTypedSelector((state) => state.viewerPage.rightPanelType)
  const { data: caseRecordDocs } = useCaseDocumentsQuery({
    caseId: editableResult?.caseId,
    // @ts-ignore
    source: editableResult?.source,
  })
  const { useForm } = Form
  const [form] = useForm()
  const onEditSave = async (data: CaseFormDataType, newFiles: IFile[], deletedFiles?: IFile[]) => {
    await mutateAsync({ data, deletedFiles, newFiles }).then(
      () =>
        editableResult &&
        dispatch(push(`/management/routing?caseId=${editableResult.caseId}&tab=${EDistributionTabType.NOT_COMPLETED}`)),
    )
  }
  const onSave = async (data: CaseFormDataType, newFiles: IFile[]) => {
    async function createDocs(caseId: number, files: IFile[]) {
      await Promise.all(files.map((entity) => caseService.uploadDocument(entity?.originFileObj, caseId)))
    }
    try {
      const result = await caseService.createCase({
        ...data,
        materialTakeoutDate: data.materialTakeoutDate as string,
        status: 'OPEN',
      })
      //TODO remove invalidateQueries
      if (result) {
        await createDocs(result.caseId, newFiles)
          .catch((e) => {
            queryClient.invalidateQueries([QUERY_TYPE.CASE_DOCS, result.caseId])
            setInitialError(e)
            throw e
          })
          .then(() => queryClient.invalidateQueries([QUERY_TYPE.CASE_DOCS, result.caseId]))
      }
      dispatch(push(`/management/routing?caseId=${result.caseId}&tab=${EDistributionTabType.NOT_COMPLETED}`))
      setEditableResult(result)
    } catch (e: any) {
      throw e
    }
  }

  const theme = useContext(ThemeContext)

  const FormWrapper = styled.div<{ theme: string }>`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: scroll;

    & > * {
      min-width: 600px;
      width: 600px;
      margin: 32px auto;
      background: var(--color-bg-1);
      border: 1px solid var(--color-border-1);
      border-radius: 5px;
      overflow-y: auto;
    }
  `
  const { t } = useTranslation()
  return (
    <ContentLayout
      rightPanel={
        rightPanelType === SideRightPanelType.NOTIFICATION && <NoticesList notificationButton={notificationButton} />
      }
      header={
        <ContentHeaderElement>
          <TitleElement level={1}>{t('Создание случая')}</TitleElement>
        </ContentHeaderElement>
      }
    >
      <FormWrapper theme={theme.theme}>
        {editableResult ? (
          <CaseForm
            onSave={onEditSave}
            isEdit={true}
            initialValues={{
              description: editableResult.description,
              diagnosticProcedureType: editableResult.casePathologicalInfo.diagnosticProcedureType || undefined,
              materialTakeoutDate: editableResult.materialTakeoutDate
                ? new Date(formatDateForDisplay(editableResult.materialTakeoutDate))
                : undefined,
              // @ts-ignore
              name: editableResult.name,
              patient: {
                ...editableResult.patient,
                birthDate: editableResult.patient?.birthDate,
                fullname: '',
                patientProfileId: -1,
                sex: 'MALE',
              },
              siteSpecificMetadata: editableResult.siteSpecificMetadata || undefined,
            }}
            caseRecordDocs={caseRecordDocs}
            initialError={initialError}
            onOpenPatientModal={() => dispatch(casesSlice.actions.setPatientModal(true))}
          />
        ) : (
          <CaseForm
            form={form}
            onSave={onSave}
            initialValues={{ ...form.getFieldsValue() }}
            onOpenPatientModal={() => dispatch(casesSlice.actions.setPatientModal(true))}
          />
        )}
      </FormWrapper>
    </ContentLayout>
  )
}
