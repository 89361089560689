import HoverWithClickPreviewPopup from 'shared/ui/table/ui/HoverWithClickPreviewPopup'
import styled from 'styled-components'
import { ISumpSlide } from 'types/ISumpSlide'

const Actions = styled.div`
  margin-bottom: 0px;
  position: relative;
  display: flex;
  align-items: center;

  & > * {
    margin-right: 8px;
    height: 24px;
  }

  svg {
    path {
      fill: #99989f;
    }
  }
`

type Props = {
  record: ISumpSlide
  labelUrl: any
}

const RowActions = ({ labelUrl, record }: Props) => {
  if (record.fileType === 'DOCUMENT') return null
  return (
    <Actions>
      <HoverWithClickPreviewPopup
        src={record.thumbnails.medium}
        iconName={'preview'}
        disable={record.thumbnails.medium === null || undefined}
        tooltipTitle={'Превью недоступно'}
      />
      {record.fileType === 'MICRO' && (
        <HoverWithClickPreviewPopup
          src={labelUrl}
          iconName={'label'}
          disable={labelUrl === null || undefined}
          tooltipTitle={'Этикетка недоступна'}
        />
      )}
    </Actions>
  )
}

export default RowActions
