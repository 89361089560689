import { useTypedSelector } from 'app/redux/lib/selector'
import { useSlideQuery } from 'entities/slide'
import { useUserStatusContext } from 'features/multiplayer/lib'
import { useCachersQuery } from 'features/slide-cacher/api/query'
import cacheService from 'features/slide-cacher/api/service'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import IIIFInfo, { ImageInformationResponse } from 'ol/format/IIIFInfo'
import TileLayer from 'ol/layer/Tile'
import { IIIF } from 'ol/source'
import { selectAtlasViewerUrlSlideId, viewerPageSlice } from 'pages/viewer'
import { useViewerMapParams } from 'pages/viewer/lib/common/MapsProvider'
import {
  useOpenViewers,
  useViewerIdMap,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import { selectTasksViewerUrlTaskId } from 'pages/viewer/model/viewerPageSlice'
import { useSubscription } from 'processes/stomp'
import { WsResponseDziCache } from 'processes/stomp/types'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { useEventBusProvided } from 'shared/lib/EventBus'
import { useOS } from 'shared/lib/hooks'
import { useMapChangeHandlers } from 'shared/lib/map'
import { getResolutionByZoomLevel } from 'shared/lib/metadata'
import { DOT_WITH_SPACE } from 'shared/text-constants'
import { TitleElement } from 'shared/ui/kit'
import ISlideLayerConfig from 'types/ISlideLayerConfig'
import TViewerId from 'types/TViewerId'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useSlideMapViewSelector, useViewerMainSelector } from 'viewer/container'
import { WrongSlideId } from 'viewer/container/ui/Viewer'

import { dzi } from './SlideCacher'
import { SlideLayerContext } from './SlideLayerProvider'

const CANTALOUPE_CACHE_SIZE = 512
interface SlideLayerProps {
  iiifInfo: string | ImageInformationResponse
  objectivePower?: number
  adapterLensPower?: number
  iiif2AuthToken?: string
  viewerId: TViewerId
  caseId: number
  /** Загрузка структуры из инфо джейсона */
  isInfoLoading?: boolean
}
const SlideLayer = ({
  adapterLensPower,
  caseId,
  iiif2AuthToken,
  iiifInfo,
  isInfoLoading,
  objectivePower,
  viewerId,
}: SlideLayerProps) => {
  const { activeViewerId } = useViewerPageProvided()
  const queryClient = useQueryClient()
  const { setConfig } = useContext(SlideLayerContext)
  const dispatch = useDispatch()
  const { slideId, source } = useViewerIdSlideState(viewerId)
  const isTaskViewer = useSelector(selectTasksViewerUrlTaskId) !== undefined
  const isAtlasViewer = useSelector(selectAtlasViewerUrlSlideId) !== undefined
  const workspaceId = useCurrentWorkspaceId()
  // Список доступных кешеров
  const { data: cachers, isLoading: isCachersLoading } = useCachersQuery({
    isAtlas: isTaskViewer || isAtlasViewer,
    workspaceId: String(workspaceId),
  })
  const [cachedImgUrl, setCachedImgUrl] = useState<string>()
  const [cacheLoading, setCacheLoading] = useState<boolean>(true)
  const [isNotFound, setIsNotFound] = useState<boolean>(false)
  const dziSubscription = useRef<string | null>(null)
  const dziErrorHandler = () => setCachedImgUrl(undefined)
  const wsUrl = `/topic/case/${caseId}/dzi`
  const { firstViewerId } = useOpenViewers()
  const { data: currentSlide } = useSlideQuery({ caseId, slideId, source })
  const map = useViewerIdMap(viewerId)
  const view = map.getView()
  const bus = useEventBusProvided()
  const { handleChangeRotation, handleChangeZoom } = useMapChangeHandlers(map, bus, viewerId)
  const { slideId: firstSlideId } = useViewerMainSelector(firstViewerId)
  const { openViewerIds } = useOpenViewers()
  const ctrl = useOS() === 'MacOS' ? 'cmd' : 'ctrl'
  const { viewRotation: firstViewRotation } = useSlideMapViewSelector({ slideId: firstSlideId, viewerId })
  const { viewRotation } = useSlideMapViewSelector({ slideId, viewerId })
  const { isFastTravel } = useViewerPageProvided()
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const drawMode = useTypedSelector((state) => state.annotations.drawMode)
  const origViewParams = useViewerMapParams(viewerId)
  const origZoomLlv = getResolutionByZoomLevel(origViewParams?.origZoom || 0, currentSlide)
  const firstRender = useRef<boolean>(true)
  const MITOSIS = useTypedSelector((state) => state.viewerPage.tools.MITOSIS)
  const { targetUserId, unsubscribeFromUser } = useUserStatusContext()

  let slideLayerConfig: ISlideLayerConfig | null = null
  const { t } = useTranslation()

  if (iiifInfo) {
    const options = {
      ...new IIIFInfo(iiifInfo).getTileSourceOptions(),
      cacheSize: CANTALOUPE_CACHE_SIZE,
      crossOrigin: 'anonymous',
      reprojectionErrorThreshold: 1,
      zDirection: -1,
    }

    const source = new IIIF(options)
    const layer = new TileLayer()

    source.setTileLoadFunction((title, url) => {
      // @ts-ignore
      title.getImage().src = iiif2AuthToken ? url + `?access_token=${iiif2AuthToken}` : url
    })

    layer.set('type', 'main')
    layer.setSource(source)

    slideLayerConfig = {
      layer,
      objectivePower,
      options,
      source,
    }
  }

  dziSubscription.current = wsUrl
  // Для датасета нет поддержки перехода на кешер через WS
  !isTaskViewer && !isAtlasViewer
    ? useSubscription<WsResponseDziCache>(
        wsUrl,
        (result) => {
          if (result.payload.slideId !== slideId) return
          queryClient
            .refetchQueries([
              QUERY_TYPE.CACHERS,
              { isAtlas: isTaskViewer || isAtlasViewer, workspaceId: String(workspaceId) },
            ])
            .then(() => queryClient.invalidateQueries([QUERY_TYPE.SLIDE, slideId]))
        },
        [],
        { 'x-oc-workspace-id': String(workspaceId) },
      )
    : null

  useEffect(() => {
    if (workspaceId && slideId && cachers?.length) {
      setCacheLoading(true)
      const emptyPromise = new Promise((resolve) => {
        setTimeout(() => resolve({ available: false, slideId: 0, uri: '' }), 0)
      })
      const results = Promise.allSettled(
        cachers
          .sort((a, b) => b.weight - a.weight)
          .map(async (item) => {
            const { shardId, url } = item

            return shardId === currentSlide?.shardId
              ? cacheService.fetchSlideCacheStatus(url, slideId)
              : await emptyPromise
          }),
      )

      results.then((res) => {
        // @ts-ignore
        const result = res?.find((item) => item.status === 'fulfilled' && item.value.available)?.value
        const url = result?.uri
        const resultedSlideId = result?.slideId
        if (url && resultedSlideId === slideId) {
          setCachedImgUrl(url)
        } else {
          setCachedImgUrl(undefined)
          dispatch(viewerPageSlice.actions.setCacheMessage(true))
        }
        setCacheLoading(false)
      })
    } else if (!isCachersLoading) {
      setCachedImgUrl(undefined)
      setCacheLoading(false)
    }
  }, [slideId, cachers, workspaceId, currentSlide?.shardId, isCachersLoading])

  useEffect(() => {
    firstRender.current = false
  }, [])

  useDeepCompareEffect(() => {
    // если кеш в процессе загрузки, сразу выходим из эффекта
    if (cacheLoading) return
    // если все загрузки прошли, обновим состояние найденного слайда
    if (!cacheLoading && !isInfoLoading) {
      setIsNotFound(!cachedImgUrl && !iiifInfo)
    }
    // обрабатываем кейс, когда нет слайда в кеше
    if (!cachedImgUrl && !isInfoLoading) {
      slideLayerConfig && setConfig(slideLayerConfig)
      !firstRender.current && dispatch(viewerPageSlice.actions.setCacheMessage(true))
    }

    let layer: TileLayer<any> | undefined
    if (!isInfoLoading) {
      // Если не найден урл из кеша выбираем стандартный конфиг для загрузки из канталупа
      layer = cachedImgUrl ? dzi.loadUrl({}, setConfig, dziErrorHandler, cachedImgUrl) : slideLayerConfig?.layer
      !!layer && map.addLayer(layer)
    }

    return () => {
      !!layer && map.removeLayer(layer)
    }
  }, [cachedImgUrl, openViewerIds, cacheLoading, isInfoLoading])

  useHotkeys(
    'shift+0, shift+1, shift+2, shift+3, shift+4, shift+5, shift+6, shift+7',
    (_, handler) => {
      const trueAdapterLensPower = adapterLensPower || 1
      const zoomKeys: any = {
        'shift+0': origZoomLlv,
        'shift+1': 2,
        'shift+2': 5,
        'shift+3': 10,
        'shift+4': 20,
        'shift+5': 40,
        'shift+6': 80,
        'shift+7': objectivePower ? objectivePower * trueAdapterLensPower : 20,
      }
      viewerId === activeViewerId && handleChangeZoom(getResolutionByZoomLevel(zoomKeys[handler.key], currentSlide))
      viewerId === activeViewerId && dispatch(viewerPageSlice.actions.setViewerIdOnMouseZoom(activeViewerId))
    },
    [origViewParams?.origZoom, activeViewerId, view, targetUserId],
  )

  useHotkeys(
    '=, -, shift+=, num_add, num_subtract',
    () => {
      viewerId === activeViewerId && dispatch(viewerPageSlice.actions.setViewerIdOnMouseZoom(activeViewerId))
    },
    [origViewParams?.origZoom, activeViewerId, view, targetUserId],
  )

  const rotation = useMemo(() => {
    if (viewRotation === 0 || viewRotation === undefined) return 0
    return (viewRotation * 180) / Math.PI
  }, [viewRotation, activeViewerId])

  const finalRotation = useCallback(
    (angle: number) => {
      const rotationUpd = rotation + angle
      if (rotationUpd < 0) return rotationUpd + 360
      if (rotationUpd > 360) return rotationUpd - 360
      return rotationUpd
    },
    [rotation],
  )
  useHotkeys(
    `${ctrl}+L, ${ctrl}+R, ${ctrl}+shift+L, ${ctrl}+shift+R, shift+Q`,
    (evt, handler) => {
      unsubscribeFromUser()
      evt.preventDefault()
      if (viewerId !== activeViewerId) return
      switch (handler.key) {
        case 'ctrl+L':
        case 'cmd+L':
          handleChangeRotation(finalRotation(-1))
          break
        case 'ctrl+R':
        case 'cmd+R':
          handleChangeRotation(finalRotation(1))
          break
        case 'ctrl+shift+L':
        case 'cmd+shift+L':
          handleChangeRotation(finalRotation(-90))
          break
        case 'ctrl+shift+R':
        case 'cmd+shift+R':
          handleChangeRotation(finalRotation(90))
          break
        case 'shift+Q':
          handleChangeRotation(0)
          break
      }
    },
    {
      filter: (event: globalThis.KeyboardEvent) => {
        if (event.ctrlKey) {
          if (event.key === 'r' || event.key === 'R' || event.key === 'l' || event.key === 'L') event.preventDefault()
        }
        return true
      },
    },
    [viewRotation, firstViewRotation, activeViewerId, targetUserId],
  )

  useHotkeys(
    'F2',
    (e, handler) => {
      if (drawMode || selectedAnnotationsIds.length > 0 || MITOSIS.isVisible) return
      if (activeViewerId !== viewerId) return
      switch (handler.key) {
        case 'F2':
          !isFastTravel ? map.getViewport().requestPointerLock() : document.exitPointerLock()
          break
      }
    },
    [isFastTravel, drawMode, openViewerIds, activeViewerId, MITOSIS.isVisible],
  )

  useHotkeys(
    'esc',
    () => {
      document.exitPointerLock()
    },
    [],
  )

  return isNotFound ? (
    <WrongSlideId>
      <TitleElement>
        {t('Слайд не найден')}
        {DOT_WITH_SPACE}
        {t('Выберите другой')}
        {DOT_WITH_SPACE}
      </TitleElement>
    </WrongSlideId>
  ) : null
}

export default SlideLayer
