import { useQuery, useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import ICase from 'types/ICase'
import { IPatientDTO } from 'types/IPatient'

import patientService from './service'

export const usePatientsQuery = () => {
  const queryClient = useQueryClient()
  return useQuery<IPatientDTO[] | undefined>([QUERY_TYPE.PATIENT], async () => {
    let result
    try {
      result = await patientService.fetchPatients()
      if (!result) return
      result?.forEach((it) => {
        const cacheRecord = queryClient.getQueryData<IPatientDTO>([QUERY_TYPE.PATIENT, it?.patientProfileId])
        if (!cacheRecord) {
          queryClient.setQueryData([QUERY_TYPE.PATIENT, it.patientProfileId], () => it)
        }
      })
    } catch (e) {
      throw e
    }
    return result
  })
}

export const usePatientCasesQuery = (patientProfileId?: number) => {
  const queryClient = useQueryClient()
  return useQuery<ICase[] | undefined>([QUERY_TYPE.PATIENT_CASES_BY_PROFILE, patientProfileId], async () => {
    let result
    try {
      if (!patientProfileId) return
      result = await patientService.fetchPatientCases(patientProfileId)
      if (!result) return
      result.forEach((it: any) => {
        const cacheRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.PATIENT_CASES, it?.caseId])
        if (!cacheRecord) {
          queryClient.setQueryData<ICase>([QUERY_TYPE.PATIENT_CASES, it?.caseId], () => it)
        }
      })
    } catch (e) {
      throw e
    }
    return result
  })
}
