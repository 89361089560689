import IUserRole from 'types/IUserRole'

export const migrations = {
  12: (state: any) => ({
    ...state,
    viewerPage: {
      ...state.viewerPage,
      coregActiveViewerIds: [state.viewerPage.activeViewerId],
      tools: {
        OVERVIEW: { isVisible: true, position: [0, 0] },
        SLIDE_INFO: { isVisible: false, position: [0, 0] },
        SLIDE_LABEL: { isVisible: false, position: [0, 0] },
      },
    },
  }),
  13: (state: any) => ({
    ...state,
    viewerPage: {
      ...state.viewerPage,
      descriptionsVisibility: true,
      liteContextMenuVisibility: true,
    },
  }),
  14: (state: any) => ({
    ...state,
    viewerPage: {
      ...state.viewerPage,
      tools: {
        ...state.viewerPage.tools,
        HEAT_MAP: { isVisible: false, position: [0, 0] },
        KI67: { isVisible: false, position: [0, 0] },
        MITOSIS: { isVisible: false, position: [0, 0] },
        SEARCH_MORPHOLOGY: { isVisible: false, position: [0, 0] },
        SEGMENTATION: { isVisible: false, position: [0, 0] },
        VALIDATION: { isVisible: true, position: [0, 0] },
      },
    },
  }),
  17: (state: any) => ({
    ...state,
    viewerPage: {
      ...state.viewerPage,
      tools: {
        ...state.viewerPage.tools,
        ARTEFACTS: { isVisible: false, position: [0, 0] },
        PV_PROSTATE: { isVisible: false, position: [0, 0] },
      },
    },
  }),
  18: (state: any) => ({
    ...state,
    authorities: [...state.authorities, IUserRole.ROLE_IMAGE_MANAGER, IUserRole.ROLE_ARCHIVE_MANAGER],
  }),
  20: (state: any) => ({
    ...state,
    viewerPage: {
      ...state.viewerPage,
      tools: {
        ...state.viewerPage.tools,
        MEDICALNEURONETS_CRC: { isVisible: false, position: [0, 0] },
      },
    },
  }),
}
