import { FormInstance } from 'antd'
import { useReportCreateMutation, useReportEditMutation, useReportRemoveMutation } from 'features/reports/api/query'
import { useValidations } from 'features/reports/ui/lib/useValidations'
import { ReportFormData } from 'features/reports/ui/ReportCreationForm'
import { Footer } from 'features/reports/ui/styled'
import { useTranslation } from 'react-i18next'
import { ButtonElement } from 'shared/ui/kit'
import IReport from 'types/IReport'

type Props = {
  initialReport: Partial<IReport>
  form: FormInstance<ReportFormData>
  onSave: () => void
  onDelete: (reportId: number) => void
  onCancel?: () => void
}

export const CommonFooter = ({ form, initialReport, onCancel, onDelete, onSave }: Props) => {
  const { isLoading: isRemoveLoading } = useReportRemoveMutation()
  const { isLoading } = useReportCreateMutation()
  const { isLoading: isEditLoading } = useReportEditMutation()
  const { isValidCommonRequired } = useValidations(initialReport, form)
  const { t } = useTranslation()
  return (
    <Footer>
      {initialReport?.medicalReportId && (
        <ButtonElement
          danger
          style={{ gridColumn: 1 }}
          onClick={() => initialReport.medicalReportId && onDelete(initialReport.medicalReportId)}
          loading={isRemoveLoading}
        >
          {t('Удалить')}
        </ButtonElement>
      )}
      <ButtonElement style={{ gridColumn: 5 }} onClick={onCancel}>
        {t('Отменить')}
      </ButtonElement>
      <ButtonElement
        type="primary"
        style={{ gridColumn: 6 }}
        onClick={onSave}
        loading={isEditLoading || isLoading}
        disabled={!isValidCommonRequired()}
      >
        {t('Сохранить')}
      </ButtonElement>
    </Footer>
  )
}
