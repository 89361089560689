import { Descriptions as AntDescriptions } from 'antd'
import type { DescriptionsProps } from 'antd/es/descriptions'
import type { DescriptionsItemProps } from 'antd/es/descriptions/Item'
import { FC } from 'react'
import styled from 'styled-components/macro'

type Props = {
  items?: DescriptionsItemProps[]
} & DescriptionsProps

const StyledDescriptions = styled(AntDescriptions)`
  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 0px;
  }
  .ant-descriptions-item-label {
    position: relative;
    color: var(--color-text-3);
    font-size: 12px;
    line-height: 16px;
  }
  .ant-descriptions-item-content {
    display: inline;
    color: var(--color-text-1);
    font-size: 12px;
    line-height: 16px;
    white-space: pre-line;
  }
  .ant-descriptions-item-container {
    display: block;
  }
`

export const Descriptions: FC<Props> = ({ children, items, ...rest }) => (
  <StyledDescriptions {...rest}>
    {items
      ? items.map((item, i) => <AntDescriptions.Item key={i.toString()} {...item} span={item.span || 3} />)
      : children}
  </StyledDescriptions>
)

export const DescriptionsItem = AntDescriptions.Item
