import { del, get, post, put } from 'shared/api'
import { AnnotationType, IAnnotation, IAnnotationInfo, INewAnnotationDataObject } from 'types/IAnnotations'
import { isObjectsCounting } from 'viewer/map/layers/annotations/lib/helpers'

const getAnnotations = async (caseId: number | string, slideId: number) =>
  await get<IAnnotation[]>({
    url: `/case/${caseId}/slide/${slideId}/annotations`,
  })
const getAnnotationsByCase = async (caseId: number | string) =>
  await get<Record<number, IAnnotation[]>>({
    url: `/case/${caseId}/annotations`,
  })

const createAnnotation = async (
  caseId: number | string,
  slideId: number,
  data: INewAnnotationDataObject,
  type: AnnotationType,
  metric: number,
  zindex?: number,
  caption?: string,
  info?: IAnnotationInfo,
) => {
  const response = await post<IAnnotation[]>({
    data: {
      body: [
        {
          caption,
          data: {
            formattedFeature: JSON.parse(data.formattedFeature || ''),
            type: data.type,
          },
          info,
          metric,
          type,
          zindex,
        },
      ],
    },
    url: `/case/${caseId}/slide/${slideId}/annotations`,
  })

  return response[0]
}

const createAnnotations = async (caseId: number, annotations: Partial<IAnnotation>[]) => {
  const slideId = annotations[0]?.slideId
  const postData = annotations?.map(({ caption, data, metric, type, zindex }) => {
    const featureData = data?.formattedFeature ? JSON.parse(data.formattedFeature) : {}
    const payloadMetric: number = isObjectsCounting(type) ? featureData?.properties?.targetArea : metric
    const payloadInfo = isObjectsCounting(type) ? { mitosis: featureData?.properties?.mitosis } : undefined

    return {
      caption,
      data: {
        formattedFeature: JSON.parse(data?.formattedFeature || ''),
        type: data?.type,
      },
      info: payloadInfo,
      metric: payloadMetric,
      type,
      zindex,
    }
  })

  return await post<Partial<IAnnotation[]>>({
    data: { body: postData },
    url: `/case/${caseId}/slide/${slideId}/annotations`,
  })
}

const updateAnnotation = async (
  caseId: number | string,
  slideId: number,
  slideAnnotationId: number,
  data: INewAnnotationDataObject | undefined,
  type: AnnotationType,
  metric: number,
  zindex?: number,
  caption?: string,
  info?: IAnnotationInfo,
) => {
  const formattedFeature =
    typeof data?.formattedFeature === 'string' ? JSON.parse(data?.formattedFeature) : data?.formattedFeature
  const response = await put<IAnnotation[]>({
    data: {
      body: [
        {
          caption,
          data: {
            formattedFeature,
            type: data?.type,
          },
          info,
          metric,
          slideAnnotationId,
          type,
          zindex,
        },
      ],
    },
    url: `/case/${caseId}/slide/${slideId}/annotations`,
  })

  return response[0]
}

const updateAnnotations = async (caseId: number | string, annotations: IAnnotation[]) => {
  const slideId = annotations[0]?.slideId
  const putData = annotations?.map(({ caption, data, info, metric, slideAnnotationId, type, zindex }) => ({
    caption,
    data: {
      formattedFeature: JSON.parse(data?.formattedFeature || ''),
      type: data?.type,
    },
    info,
    metric,
    slideAnnotationId,
    type,
  }))

  return await put<Partial<IAnnotation[]>>({
    data: { body: putData },
    url: `/case/${caseId}/slide/${slideId}/annotations`,
  })
}

const deleteAnnotation = async (caseId: number | string, slideId: number, slideAnnotationId: number) => {
  const response = await del({
    config: { data: { body: [slideAnnotationId] } },
    url: `/case/${caseId}/slide/${slideId}/annotations`,
  })
  return response as { success: boolean }
}

const deleteAnnotations = async (caseId: number | string, slideId: number, slideAnnotationIds: number[]) => {
  const response = await del({
    config: { data: { body: slideAnnotationIds } },
    url: `/case/${caseId}/slide/${slideId}/annotations`,
  })
  return response as { success: boolean }
}

const getAnnotation = async (caseId: number | string, slideId: number, slideAnnotationId: number) => {
  const response = await get({
    url: `/case/${caseId}/slide/${slideId}/annotations/${slideAnnotationId}`,
  })
  return response as IAnnotation
}

const annotationsService = {
  createAnnotation,
  createAnnotations,
  deleteAnnotation,
  deleteAnnotations,
  getAnnotation,
  getAnnotations,
  getAnnotationsByCase,
  updateAnnotation,
  updateAnnotations,
}

export default annotationsService
