import { push } from 'connected-react-router'
import { notices } from 'features/notices'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { selectUrlCaseId } from 'pages/viewer'
import { IViewingState, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { formatDateForDisplay } from 'shared/lib/date'
import styled from 'styled-components'
import ICase, { ICaseRelation, ICaseUser } from 'types/ICase'
import IReport from 'types/IReport'
import TViewerId from 'types/TViewerId'

type Props = {
  caseRecords: ICase[]
}

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledListItem = styled.div<{ active: boolean }>`
  padding: 8px;
  width: 100%;
  color: ${({ active }) => (active ? '#fff' : 'var(--color-text-1)')};
  font-weight: 500;
  line-height: 16px;
  background: ${({ active }) => active && 'var(--color-purple)'};
  border-radius: ${({ active }) => active && '5px'};
  &:hover {
    background: ${({ active }) => (active ? 'var(--color-purple)' : 'var(--color-bg-3)')};
    border-radius: 5px;
  }
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0px;
  }
`

const StyledItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const StyledItem = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`

const StyledContent = styled(StyledItem)`
  color: var(--color-text-3);
  margin-top: 4px;
`

const StyledName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

type ItemProps = {
  caseRecord: ICase
  active: boolean
}

const CaseItem = ({ active, caseRecord }: ItemProps) => {
  const dispatch = useDispatch()
  const { caseDate, caseId, casePathologicalInfo, createdAt, name, relation, reports, slides, user, workspace } =
    caseRecord
  const { removeViewer, setAutoCoreg, setHandableCoreg, viewingState } = useViewerPageProvided()
  const currentWorkspaceId = useCurrentWorkspaceId()
  const urlCaseId = useSelector(selectUrlCaseId)
  const { t } = useTranslation()
  const { activeViewerId, changeViewerSlide } = useViewerPageProvided()

  const findKeysNotEqualToCaseId = (obj: IViewingState, caseId: number) => {
    const keys: string[] = []
    for (const key in obj) {
      if (obj[key as keyof IViewingState]?.slide.caseId !== caseId) {
        keys.push(key)
      }
    }
    return keys
  }
  useEffect(() => {
    if (urlCaseId) {
      const arr = findKeysNotEqualToCaseId(viewingState, urlCaseId)
      if (arr.length) {
        arr.forEach((item) => {
          removeViewer(item as TViewerId)
        })
      }
    }
  }, [viewingState, urlCaseId])

  const onReturnToOriginCase = () => {
    const pathname = localStorage.getItem('ORIGIN_CASE_PATH')
    setAutoCoreg(false)
    setHandableCoreg(false)
    if (pathname) {
      dispatch(push(pathname))
      localStorage.removeItem('ORIGIN_CASE_PATH')
    }
  }

  const onClick = (e: any) => {
    e.stopPropagation()
    if (active) return
    if (workspace) {
      setAutoCoreg(false)
      setHandableCoreg(false)
      setTimeout(() => {
        relation === ICaseRelation.RESTRICTED && slides[0]
          ? changeViewerSlide(activeViewerId, {
              caseId,
              slideGroupType: slides[0]?.groupType || 'MICRO',
              slideId: slides[0]?.slideId,
              source: 'PLATFORM',
              viewerMode: 'DEFAULT',
            })
          : dispatch(push(`/workspace/${currentWorkspaceId}/viewer/${caseId}`))
        notices.openOnOtherPatientCaseNotification({ key: 'otherCase', onReturnToOriginCase })
      }, 0)
    }
  }

  /** Если в заключении несколько значений, то возвращает более старое по полю created, которое написал владелец случая */
  const filterReportsByUserId = (reports: IReport[], user: ICaseUser) => {
    const userId = user?.userId

    if (reports.length === 1) return reports[0]

    const filteredReports = reports
      .filter((report) => report?.createdBy?.userId === userId)
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())

    const oldestReport = reports.reduce((oldest, current) =>
      new Date(oldest.createdAt) < new Date(current.createdAt) ? oldest : current,
    )

    return filteredReports[0] ? filteredReports[0] : oldestReport
  }

  /** Возвращает элементы с информацией Icd10 и IcdO */
  const renderReports = () => {
    const { incomingIcd10 } = casePathologicalInfo
    const filteredReport = reports && filterReportsByUserId(reports, user)
    const isSignedReports = filteredReport?.signed === true || filteredReport?.signed === null

    if (incomingIcd10 && !isSignedReports) {
      return <StyledContent>{`${incomingIcd10?.code}: ${incomingIcd10?.name};`}</StyledContent>
    }
    if (filteredReport && isSignedReports) {
      if (filteredReport?.icd10DetailedList) {
        return filteredReport?.icd10DetailedList?.map((item) => (
          <StyledContent key={item?.icd10?.id}>
            {`${item?.icd10?.code}: ${item?.icd10?.name}`}
            <br />
            {item?.morphologicalIcdO && `(${item?.morphologicalIcdO.code});`}
          </StyledContent>
        ))
      } else if (filteredReport.icd10) {
        return (
          <StyledContent>
            {`${filteredReport?.icd10?.code}: ${filteredReport?.icd10?.name}`}
            <br />
            {filteredReport?.morphologyCode && `(${filteredReport?.morphologyCode?.code});`}
          </StyledContent>
        )
      }
      return null
    } else if (incomingIcd10) {
      return <StyledContent>{`${incomingIcd10?.code}: ${incomingIcd10?.name};`}</StyledContent>
    }

    return null
  }

  return (
    <StyledListItem onClick={onClick} active={active}>
      <StyledItemContainer>
        <StyledName>{name}</StyledName>
        <StyledItem>{formatDateForDisplay(caseDate || createdAt || '')}</StyledItem>
      </StyledItemContainer>
      {workspace?.workspaceId !== currentWorkspaceId && <StyledItem>{workspace?.name}</StyledItem>}
      {casePathologicalInfo && renderReports()}
    </StyledListItem>
  )
}

export const AllCasesContainer = ({ caseRecords }: Props) => {
  const { params, url } = useRouteMatch<{ caseId: string; workspaceId: string }>()
  const localOriginPath = localStorage.getItem('ORIGIN_CASE_PATH')

  useEffect(() => {
    if (!localOriginPath) localStorage.setItem('ORIGIN_CASE_PATH', url)
  }, [localOriginPath])

  return (
    <StyledList>
      {caseRecords.map((it) => (
        <CaseItem key={it?.caseId} caseRecord={it} active={it.caseId === +params.caseId} />
      ))}
    </StyledList>
  )
}
