import { useTranslation } from 'react-i18next'
import { useOS } from 'shared/lib/hooks'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  width: 140px;
  &.ant-notification-notice-message {
    color: var(--color-text-1);
  }
`

export const Shortcut = styled.div<{ theme: string }>`
  font-size: 9px;
  font-weight: 600;
  line-height: 15px;
  color: var(--color-text-1);

  width: 100%;
  height: 100%;
  padding: 1px 4px 0;
  border-radius: 3px;
  background: ${({ theme }) =>
    theme === 'dark'
      ? 'linear-gradient(180deg,rgb(0 0 0 / 0%) 0%,rgb(255 255 255 / 12%) 100%)'
      : 'linear-gradient(180deg,rgb(0 0 0 / 8%) 0%,rgb(255 255 255 / 0%) 100%)'};
  box-shadow: ${({ theme }) =>
    theme === 'dark'
      ? '0px 1px 1px 1px rgb(0 0 0 / 76%), inset 0px 1px 0px rgb(255 255 255 / 16%)'
      : '0px 1px 1px 1px rgb(0 0 0 / 32%), inset 0px 1px 0px rgb(255, 255, 255)'};
`

const PasteNotification = ({ theme }: { theme: any }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>{t('Вставить')}</div>
        <div style={{ display: 'flex', gap: 6, lineHeight: '15px' }}>
          <Shortcut theme={theme.theme}> {useOS() === 'MacOS' ? 'Cmd' : 'Ctrl'}</Shortcut>
          {'+'}
          <Shortcut theme={theme.theme}>V</Shortcut>
        </div>
      </div>
    </Wrapper>
  )
}

export default PasteNotification
