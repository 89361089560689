import { Divider, Row } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { annotationsSlice } from 'features/annotations'
import { useAnnotationUsersByCase } from 'features/annotations/api/query'
import { UserInfoSelectItem } from 'features/share/ui/children/UserInfoSelectItem'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { pluralText } from 'shared/lib/text'
import { ButtonElement, Scroll } from 'shared/ui/kit'
import { DatePeriod } from 'shared/ui/kit/ui/DatePeriod'
import { AnnotationFilter, FilterPeriod, IAnnotation } from 'types/IAnnotations'
import IUser from 'types/IUser'
import useDeepCompareEffect from 'use-deep-compare-effect'

type Props = {
  /** ID кейса */
  caseId: number
  /** Функция закрытия окна */
  onClose: () => void
}

/** Количество юзеров для отображения без скролла */
const MAX_COUNT_VISIBILITY_USERS = 7

export const AnnotationFilterForm = ({ caseId, onClose }: Props) => {
  const { data: users } = useAnnotationUsersByCase(caseId)
  const currentUser = useTypedSelector((state) => state.user.user)
  const otherUsers: IUser[] = users?.filter((item: IUser) => item.userId !== currentUser?.userId)
  const [filterPeriod, setFilterPeriod] = useState<FilterPeriod>()
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([])
  const [filteredCount, setFilteredCount] = useState<number>(0)
  // идет процесс предфильтрации (ввод параметров и перварительного просмотра количесвта аннотаций)
  const isAnnotationsFiltering = useTypedSelector((state) => state.annotations.isAnnotationsFiltering)
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const caseAnnotationData = queryClient.getQueryData<Record<number, IAnnotation[]>>([
    QUERY_TYPE.ANNOTATION,
    { caseId },
  ])
  const onDatePeriodChange = (period: FilterPeriod) => {
    setFilterPeriod(period)
  }

  const getFilter = () => ({
    period: filterPeriod,
    userIds: selectedUserIds,
  })

  const onSelectUserChange = (userId: number) => {
    const isSelect = !selectedUserIds.find((item) => item === userId)
    setSelectedUserIds(isSelect ? [...selectedUserIds, userId] : selectedUserIds.filter((item) => item !== userId))
  }
  const getAnnotationCountByFilter = (filter: AnnotationFilter) => {
    // Массив всех аннотаций по кейсу
    const allCaseAnnotations: IAnnotation[] = []
    caseAnnotationData &&
      Object.keys(caseAnnotationData).forEach((slideId: string) => {
        allCaseAnnotations.push(...caseAnnotationData[+slideId])
      })

    return allCaseAnnotations.filter((annotation) => {
      const annotationUpdatedTime = new Date(annotation.updatedAt || annotation.createdAt).getTime()
      return (
        (filter.userIds?.length ? filter.userIds.includes(annotation.userId) : true) &&
        (filter.period?.fromDate?.getTime() || annotationUpdatedTime - 1) < annotationUpdatedTime &&
        annotationUpdatedTime < (filter.period?.toDate?.getTime() || annotationUpdatedTime + 1)
      )
    })?.length
  }

  const onAnnotationFilter = () => {
    dispatch(annotationsSlice.actions.setAnnotationFilter(getFilter()))
    onClose()
  }

  useEffect(() => {
    if (!isAnnotationsFiltering) {
      setFilterPeriod({ fromDate: null, toDate: null })
      setSelectedUserIds([])
    }
  }, [isAnnotationsFiltering])

  useDeepCompareEffect(() => {
    const isFiltering = !!filterPeriod?.fromDate || !!filterPeriod?.toDate || !!selectedUserIds?.length

    dispatch(annotationsSlice.actions.setAnnotationFiltering(isFiltering))
    setFilteredCount(getAnnotationCountByFilter(getFilter()))
  }, [filterPeriod, selectedUserIds])

  return (
    <>
      {currentUser && (
        // дополняем паддинг для равнения с отальным списком пользователей, под скролл
        <div style={{ paddingLeft: 2, paddingRight: otherUsers?.length > MAX_COUNT_VISIBILITY_USERS ? 12 : 2 }}>
          <UserInfoSelectItem
            key={currentUser.userId}
            onSelect={onSelectUserChange}
            value={currentUser.userId}
            checked={selectedUserIds?.includes(currentUser.userId)}
            fullname={t('Мои аннотации')}
          />
        </div>
      )}
      <Divider style={{ marginTop: 0 }} />
      <Scroll style={{ maxHeight: 240, overflowX: 'hidden', padding: '0 2px' }}>
        {otherUsers?.map((user: IUser) => (
          <UserInfoSelectItem
            key={user.userId}
            onSelect={onSelectUserChange}
            value={user.userId}
            checked={selectedUserIds?.includes(user.userId)}
            fullname={user.fullname}
          />
        ))}
      </Scroll>
      {!!otherUsers?.length && <Divider style={{ marginTop: 0 }} />}
      <DatePeriod value={filterPeriod} onChange={onDatePeriodChange} />
      {isAnnotationsFiltering && (
        <>
          <Divider />
          <Row>
            {!filteredCount && (
              <div style={{ textAlign: 'center', width: '100%' }}>{t('Нет подходящих аннотаций')}</div>
            )}
            {!!filteredCount && (
              <ButtonElement style={{ width: '100%' }} type={'primary'} onClick={onAnnotationFilter}>{`${t(
                'Показать',
              )} ${filteredCount} 
               ${pluralText(filteredCount, [t('аннотацию'), t('аннотации'), t('аннотаций')])}`}</ButtonElement>
            )}
          </Row>
        </>
      )}
    </>
  )
}
