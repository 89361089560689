/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'antd'
import { useCaseAssignToDoctorMutation } from 'features/cases-management/api/query'
import { getQueryParams, refetchPages } from 'features/cases-management/lib/helpers'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { useCaseManagementTabContext } from 'features/cases-management/ui/CaseManagementTabContext'
import { notices } from 'features/notices'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useCaseManagementRouteParam } from 'pages/cases-new/CasesManagementRoutes'
import React, { BaseSyntheticEvent, FC, memo, MouseEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { TextElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { ECaseStageQuery } from 'types/ICase'
import { IDoctor } from 'types/IUser'
import useDeepCompareEffect from 'use-deep-compare-effect'

const styles = [
  {
    background: 'var(--color-blue)',
    color: 'white',
  },
  {
    background: 'var(--color-red)',
    color: 'white',
  },
  {
    background: 'var(--color-green)',
    color: 'white',
  },
  {
    background: 'var(--color-yellow)',
    color: 'black',
  },
  {
    background: 'var(--color-emerald)',
    color: 'white',
  },
  {
    background: 'var(--color-purple)',
    color: 'white',
  },
]

const STYLES_QUANTITY = styles.length

type TProps = {
  /** Выбран текущий доктор */
  isActive: boolean
  /** Колбек нажатия по доктору */
  handleNameClick: () => void
  /** Доктор */
  doctor: IDoctor
}

export const DoctorNameItem: FC<TProps> = ({ doctor, handleNameClick, isActive }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { currentTab, menuTab } = useCaseManagementRouteParam()
  const { tabsQueryParams } = useCaseManagementTabContext()
  const { filterParams, visibleRowsCount } = useCaseManagementContext()
  const { isCaseRouting } = useSettingsAndUserRoles()
  const getNameLetterColor = (name: string) => styles[name.length % STYLES_QUANTITY]

  const { selectedCases, selectedCasesCount, setSelectedCases, setSelectedRowKeys } = useCaseManagementTabContext()
  /** Кнопка распределить задизейблина */
  const [isAssignDisabled, setIsAssignDisabled] = useState<boolean>(false)
  const workspaceId = Number(useCurrentWorkspaceId())

  const { isLoading: isCaseAssigning, mutate: assignCaseMutate } = useCaseAssignToDoctorMutation(Number(workspaceId), {
    onSuccess: () => {
      refetchPages(
        queryClient,
        menuTab,
        {
          tab: currentTab,
          ...getQueryParams(tabsQueryParams, menuTab, currentTab, isCaseRouting),
        },
        filterParams,
        workspaceId,
        visibleRowsCount,
      )

      setSelectedRowKeys([])
      setSelectedCases([])
      handleNameClick()

      notices.dictributionCases({ doctorName: doctor.fullname, selectedCasesCount })
    },
  })

  const { fullname, userId } = doctor
  const style = getNameLetterColor(fullname)

  useDeepCompareEffect(() => {
    const allCasesAssigned = selectedCases.every((currentCase) => currentCase?.stage === ECaseStageQuery.ASSIGNED)
    const allCasesAssignedToDoctor = selectedCases.every(
      (currentCase) => currentCase?.assignedDoctor?.userId === userId,
    )

    if ((allCasesAssigned && allCasesAssignedToDoctor) || !selectedCasesCount) {
      setIsAssignDisabled(true)
    } else {
      setIsAssignDisabled(false)
    }
  }, [selectedCases, userId, selectedCasesCount])

  const handleCaseAssign = useCallback(
    (ev: MouseEvent<HTMLElement>) => {
      ev.stopPropagation()
      if (isCaseAssigning) return

      const caseIds = selectedCases.map((currentCase) => currentCase.caseId)
      assignCaseMutate({ caseIds, userId })
    },
    [selectedCasesCount, userId, isCaseAssigning],
  )

  const onItemClick = (e: BaseSyntheticEvent) => {
    handleNameClick()
    const itemElement = e.target.className == 'doctor-item' ? e.target : e.target.closest('.doctor-item')

    setTimeout(() => {
      itemElement.scrollIntoViewIfNeeded(false)
    })
  }

  return (
    <>
      {doctor && (
        <DoctorItemWrapper
          className="doctor-item"
          onClick={onItemClick}
          style={{ background: isActive ? 'var(--color-border-1)' : 'transparent' }}
        >
          <NameWrapper key={doctor.userId}>
            <StyledDoctorNameLetter style={style}>{fullname[0].toUpperCase()}</StyledDoctorNameLetter>
            <DoctorName>{t(fullname)}</DoctorName>
          </NameWrapper>
          {isActive && (
            <Button
              type="primary"
              style={{ marginTop: '8px' }}
              onClick={handleCaseAssign}
              disabled={isAssignDisabled}
              loading={isCaseAssigning}
            >
              {`${t('Распределить')} (${selectedCasesCount})`}
            </Button>
          )}
        </DoctorItemWrapper>
      )}
    </>
  )
}

export const DoctorNameCellTable = memo(({ doctor, key }: { doctor: string; key: React.Key }) => {
  const { t } = useTranslation()

  const getNameLetterColor = (doctor: string) => styles[doctor?.length % STYLES_QUANTITY]
  const style = getNameLetterColor(doctor)

  return (
    <>
      {doctor ? (
        <TableNameWrapper key={key}>
          <StyledDoctorNameLetter style={style}>{doctor[0].toUpperCase()}</StyledDoctorNameLetter>{' '}
          <TextElement ellipsis>{doctor ? doctor : t('Не назначен')}</TextElement>
        </TableNameWrapper>
      ) : (
        <p style={{ color: 'var(--color-text-3)' }}>{t('Не назначен')}</p>
      )}
    </>
  )
})

const TableNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
  cursor: pointer;
`
const StyledDoctorNameLetter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-weight: 700;
`
const DoctorName = styled.p`
  margin: 0;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
const DoctorItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px 16px 16px 16px;
`
