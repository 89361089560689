import { useTypedSelector } from 'app/redux/lib/selector'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

import AdaptiveThumbnailContainer from './AdaptiveThumbnailContainer'
import { slideBlocksSlice } from './model/BlocksSlice'

const FlexRow = styled.div`
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
`

const Wrapper = styled.div`
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TitleBlock = styled.div`
  display: flex;
  color: var(--color-text-1);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`
type BlockSort = { slideId: number; block?: string | null; container?: string | null }
type Props = {
  i: number
  caseId: number
  typedKey: string
  blocks?: BlockSort[]
  emptyBlocks?: boolean
  macroSlidesIds?: boolean
}

export const BlockContainer = ({ blocks, caseId, emptyBlocks, i, macroSlidesIds, typedKey }: Props) => {
  const dispatch = useDispatch()
  const block = useTypedSelector((state) => state.slideBlocks[i])
  const clickHandler = () =>
    dispatch(slideBlocksSlice.actions.setSlideBlockState({ block: block ? null : typedKey, key: i }))
  const { t } = useTranslation()
  const newTypedKey =
    typedKey[0] === '-'
      ? typedKey.slice(1)
      : typedKey[typedKey.length - 1] === '-'
      ? typedKey.slice(0, typedKey.length - 1)
      : typedKey

  return (
    <div key={Math.random() * Math.random()}>
      <FlexRow onClick={clickHandler}>
        <IconElement name={block ? 'sectionIsOpen' : 'sectionIsClose'} fill={'var(--color-text-3)'} size="md" />
        <TitleBlock>{emptyBlocks ? t('Нет блока') : macroSlidesIds ? t('Макро') : newTypedKey}</TitleBlock>
      </FlexRow>
      <Wrapper data-testid={'viewer-thumbnails-left-panel-common'} style={{ display: block ? '' : 'none' }}>
        {blocks?.map((blockData) => (
          <AdaptiveThumbnailContainer slideId={blockData.slideId} caseId={caseId} key={blockData.slideId} />
        ))}
      </Wrapper>
    </div>
  )
}
