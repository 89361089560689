import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { useIntersectAnnotationMutation } from 'features/annotations/api/query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import usePrevious from 'shared/lib/hooks/usePrevious'
import { IconButtonElement, IconElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { MergeType } from 'types/MergeType'

const Panel = styled.div`
  position: absolute;
  top: 40%;
  left: -48px;
  padding: 8px;
  z-index: 9999;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${() => modalBackground}
  ${() => modalShadow}
`

type Props = {
  annotationsIds: number[]
  /** Является ли аннотация мультиполигоном */
  isMultiPolygonAnnotation: boolean
  /** Есть ли у аннотации вложенная геометрия*/
  isNestedGeometryAnnotation: boolean
  caseId: number
  slideId: number
  /** Флаг на тип операции Разделение */
  disunion: boolean
  disabled: boolean
}

export const IntersectPanel = ({
  annotationsIds,
  caseId,
  disabled,
  disunion,
  isMultiPolygonAnnotation,
  isNestedGeometryAnnotation,
  slideId,
}: Props) => {
  const { t } = useTranslation()
  const { isError, mutate: intersect } = useIntersectAnnotationMutation({ caseId, slideId })
  /** Флаг на предыдущий тип операции */
  const prevDisunion = usePrevious(disunion)
  /** Состояние кнопок в панели */
  const [btnsDisabled, setBtnsDisabled] = useState<boolean>(disabled)

  useEffect(() => {
    /** Включаем кнопки если получаем ошибку при мутации или меняется панель */
    if (disunion !== prevDisunion || isError) {
      setBtnsDisabled(disabled)
    }
  }, [disunion, isError])

  const mergeHandler = async (mergeType: MergeType) => {
    if (!btnsDisabled) {
      setBtnsDisabled(true)
      await intersect({
        annotationsIds: annotationsIds as any,
        caseId,
        mergeType,
      })
    }
  }

  return !disunion ? (
    <Panel>
      <TooltipElement title={t('Объединение')} placement="left">
        <IconButtonElement
          onClick={() => mergeHandler(MergeType.UNION)}
          icon={<IconElement name="union" />}
          disabled={btnsDisabled}
        />
      </TooltipElement>
      <TooltipElement title={t('Вычитание')} placement="left">
        <IconButtonElement
          onClick={() => mergeHandler(MergeType.SUBTACT)}
          icon={<IconElement name="subtact" />}
          disabled={btnsDisabled}
        />
      </TooltipElement>
      <TooltipElement title={t('Пересечение')} placement="left">
        <IconButtonElement
          onClick={() => mergeHandler(MergeType.INTERSECT)}
          icon={<IconElement name="intersect" />}
          disabled={btnsDisabled}
        />
      </TooltipElement>
      <TooltipElement title={t('Исключение пересечения')} placement="left">
        <IconButtonElement
          onClick={() => mergeHandler(MergeType.EXCLUDE)}
          icon={<IconElement name="exclude" />}
          disabled={btnsDisabled}
        />
      </TooltipElement>
    </Panel>
  ) : isMultiPolygonAnnotation ? (
    <Panel>
      <TooltipElement title={t('Разделение мультиполигона')} placement="left">
        <IconButtonElement
          onClick={() => mergeHandler(MergeType.DISUNION)}
          icon={<IconElement name="multipolygon" />}
          disabled={btnsDisabled}
        />
      </TooltipElement>
    </Panel>
  ) : isNestedGeometryAnnotation ? (
    <Panel>
      <TooltipElement title={t('Разделить вложенную геометрию')} placement="left">
        <IconButtonElement
          onClick={() => mergeHandler(MergeType.INTERIOR)}
          icon={<IconElement name="inside" />}
          disabled={btnsDisabled}
        />
      </TooltipElement>
    </Panel>
  ) : null
}
