import { useTypedSelector } from 'app/redux/lib/selector'
import { useSlideQuery } from 'entities/slide'
import { useTaskQuery } from 'entities/tasks/api/query'
import markupTasksService from 'entities/tasks/api/service'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { notices } from 'features/notices'
import * as jsts from 'jsts'
import * as _ from 'lodash'
import GeoJSON from 'ol/format/GeoJSON'
import { LinearRing, LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon } from 'ol/geom'
import {
  selectAtlasViewerUrlSlideId,
  selectTasksViewerUrlTaskId,
  selectUrlSlideId,
} from 'pages/viewer/model/viewerPageSlice'
import {
  UseMutateFunction,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import i18next from 'shared/lib/i18n/i18n'
import { getSlideMppx } from 'shared/lib/metadata'
import {
  AnnotationFilter,
  AnnotationType,
  IAnnotation,
  IAnnotationInfo,
  IAnnotationStack,
  INewAnnotationDataObject,
} from 'types/IAnnotations'
import ICase from 'types/ICase'
import { IMarkupSlide, IMarkupSlideResult } from 'types/IMarkupSlide'
import { MergeType } from 'types/MergeType'
import { getFeaturesFromGeoJson } from 'viewer/map'
import { getPolygonArea } from 'viewer/map/layers/annotations/lib/annotationsDrawHelpers'
import { MAXIMUM_ANNOTATIONS_STACK_SIZE } from 'viewer/map/layers/annotations/lib/constants'
import { isObjectsCounting, unsetSlidePositioningForAnnotation } from 'viewer/map/layers/annotations/lib/helpers'
import {
  getClearFeature,
  getDisunionGeom,
  getExcludeGeom,
  getInteriorGeom,
  getSingleFeatureFromGeoJson,
  getUnionGeom,
} from 'viewer/map/lib/utils'

import annotationsService from './service'

export type IAnnotationQuery = {
  /** idшники аннотаций */
  ids: number[]
  /** дата изменения хранилища аннотаций (для управления мемомизацией) */
  date: Date
}

export type IAddLocalAnnotation = {
  /** id кейса */
  caseId: number
  /** объект аннотации */
  localAnnotation: Partial<IAnnotation>
}
export type IRemoveLocalAnnotation = {
  /** id кейса */
  caseId: number
  /** id слайда */
  slideId: number
  /** id локальной аннотации */
  slideAnnotationId: number
}

const t = i18next.t

// Условия для фильтрации кеша аннотаций
const getFilterCondition = (filter: AnnotationFilter, annotation?: IAnnotation) => {
  const annotationUpdatedTime = annotation ? new Date(annotation.updatedAt || annotation.createdAt).getTime() : null

  return annotationUpdatedTime && annotation
    ? (filter.userIds?.length ? filter.userIds?.includes(annotation.userId) : true) &&
        (filter.period?.fromDate?.getTime() || annotationUpdatedTime - 1) < annotationUpdatedTime &&
        annotationUpdatedTime < (filter.period?.toDate?.getTime() || annotationUpdatedTime + 1)
    : false
}

export const useAnnotationsQuery = (
  /** caseId - id кейса */
  caseId: number,
  /** slideId - id слайда */
  slideId: number,
  /** slideResults - результат разметки по slideId */
  slideResults?: IMarkupSlideResult[],
  /** useTasksSlideResult - результат разметки по taskId */
  tasksSlideResult?: IMarkupSlideResult[],
  options?: UseQueryOptions<{ ids: number[]; date: Date } | undefined>,
) => {
  const queryClient = useQueryClient()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const taskData = useTaskQuery(taskId).data
  const newSlideResults = slideResults || tasksSlideResult

  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const annotationFilter = useTypedSelector((state) => state.annotations.annotationFilter)
  const isAnnotationsFiltered = useTypedSelector((state) => state.annotations.isAnnotationsFiltered)

  const applyFilter = (annotationData: IAnnotationQuery | undefined) => {
    if (annotationData && isAnnotationsFiltered && annotationFilter) {
      const filteredData: IAnnotationQuery | undefined = { ...annotationData }
      filteredData.ids = filteredData.ids?.filter((id) => {
        const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, id])
        return getFilterCondition(annotationFilter, annotation)
      })

      return filteredData
    } else {
      return annotationData
    }
  }

  return useQuery<IAnnotationQuery | undefined>(
    [QUERY_TYPE.ANNOTATION, { slideId }],
    async () => {
      if ((caseId === slideId && !taskId) || !slideId) return

      //TODO find case when relation === 'RESTRICTED' and remove
      if (!caseRecord && !taskId) {
        return undefined
      }
      try {
        if (taskId) {
          const userData = taskData?.participants?.find((item) => item.userId === currentUserId)
          const currentUserResults =
            (userData?.canSeeOtherResults
              ? newSlideResults
              : newSlideResults?.filter((item) => item.markupParticipant?.userId === currentUserId)) || []
          const response = await Promise.all(
            currentUserResults.map(
              async (item) =>
                await markupTasksService.getTaskAnnotations(
                  item.markupSlideId,
                  item.markupResultId,
                  item.markupParticipant?.markupTaskId,
                ),
            ),
          )
          const sortedByZindex = _.sortBy(
            response.flatMap((item) => item),
            (el) => getSingleFeatureFromGeoJson(el.data?.formattedFeature).get('annotationZindex') as number,
          )
          const ids =
            sortedByZindex.map((item) => {
              queryClient.setQueryData([QUERY_TYPE.ANNOTATION, item.slideAnnotationId], () => item)
              return item.slideAnnotationId
            }) || []
          return { date: new Date(), ids: [...ids] }
        } else {
          const response = await annotationsService.getAnnotations(caseId, slideId)

          const sortedByZindex = _.sortBy(response, (el) => {
            if (!el.data?.formattedFeature || !getFeaturesFromGeoJson(el.data?.formattedFeature).length) return 0
            return getFeaturesFromGeoJson(el.data?.formattedFeature)[0].get('annotationZindex')
          })
          const ids =
            sortedByZindex
              ?.filter((item) => item.data?.type === 'ANNOTATION')
              .map((item) => {
                queryClient.setQueryData([QUERY_TYPE.ANNOTATION, item.slideAnnotationId], () => item)
                return item.slideAnnotationId
              }) || []
          return { date: new Date(), ids: [...ids] }
        }
      } catch (e) {
        return undefined
      }
    },
    {
      cacheTime: Infinity,
      //TODO find case when relation === 'RESTRICTED' and remove
      enabled: taskId ? !!taskData && !!newSlideResults : true,
      select: applyFilter,
      staleTime: Infinity,
      ...options,
    },
  )
}
export const useAnnotationQuery = (
  caseId: number,
  slideId: number,
  annotationId: number,
  options?: UseQueryOptions<IAnnotation>,
) =>
  useQuery<IAnnotation>(
    [QUERY_TYPE.ANNOTATION, annotationId],
    () => annotationsService.getAnnotation(caseId, slideId, annotationId),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      ...options,
    },
  )

export const useAnnotationsByCaseQuery = (
  caseId: number,
  compareLocation?: boolean,
  options?: UseQueryOptions<Record<number, IAnnotation[]> | undefined>,
) => {
  const annotationFilter = useTypedSelector((state) => state.annotations.annotationFilter)
  const isAnnotationsFiltered = useTypedSelector((state) => state.annotations.isAnnotationsFiltered)

  const applyFilter = (annotationData: Record<number, IAnnotation[]> | undefined) => {
    if (annotationData && isAnnotationsFiltered && annotationFilter) {
      const filteredData = { ...annotationData }
      Object.keys(filteredData).forEach((slideId: string) => {
        filteredData[+slideId] = filteredData[+slideId]?.filter((annotation) =>
          getFilterCondition(annotationFilter, annotation),
        )
      })
      return filteredData
    } else {
      return annotationData
    }
  }

  return useQuery<Record<number, IAnnotation[]> | undefined>(
    [QUERY_TYPE.ANNOTATION, { caseId }],
    async () => {
      if (!caseId || compareLocation) return
      const result = await annotationsService.getAnnotationsByCase(caseId)
      Object.keys(result).forEach((key: any) => {
        result[key] = _.sortBy(result[key], (el) => el.zindex)
      })

      return result
    },
    {
      cacheTime: Infinity,
      ...options,
      select: applyFilter,
    },
  )
}

export const useAnnotationUsersByCase = (
  caseId: number,
  options?: UseQueryOptions<Record<number, IAnnotation[]> | undefined>,
) =>
  useQuery<any>(
    [QUERY_TYPE.USERS_BY_CASE, { caseId }],
    async () => {
      if (!caseId) return

      const result = await annotationsService.getAnnotationsByCase(caseId)
      const allAnnotations = Object.values(result).flat()

      return _.uniqBy(
        allAnnotations.map((item) => ({
          fullname: item.user,
          userId: item.userId,
        })),
        (annotation) => annotation.userId,
      )
    },
    {
      cacheTime: Infinity,
      ...options,
    },
  )

const getCurrentResult = async ({
  currentSlides,
  currentUserId,
  slideId,
  taskId,
}: {
  slideId: number
  taskId: number
  currentUserId: number
  currentSlides?: IMarkupSlide[]
}) => {
  const currSlide = currentSlides?.filter((item) => item.slideId === slideId)[0]
  let slideResults = await markupTasksService.fetchTaskSlideResult(taskId, currSlide?.markupSlideId || 0)

  if (slideResults?.length === 0)
    slideResults = [await markupTasksService.createTaskSlideResult(taskId, currSlide?.markupSlideId || 0)]

  return slideResults?.filter((item) => item?.markupParticipant?.user?.userId === currentUserId)[0]
}

export const useAddAnnotationMutation = ({ caseId, slideId }: { caseId?: number; slideId?: number }) => {
  const queryClient = useQueryClient()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const currentStack = queryClient.getQueryData<IAnnotationStack[]>([QUERY_TYPE.ANNOTATIONS_STACK, slideId]) || []
  const currentUserId = Number(useTypedSelector((state) => state.user.user?.userId))

  return useMutation(
    async ({
      caption,
      data,
      info,
      metric,
      noCashing,
      type,
      zindex,
    }: {
      data: INewAnnotationDataObject
      type: AnnotationType
      metric: number
      zindex?: number
      caption?: string
      noCashing?: boolean
      info?: IAnnotationInfo
    }) => {
      let result: IAnnotation | null = null

      if (taskId && slideId) {
        const currentSlides = queryClient.getQueryData<IMarkupSlide[]>([QUERY_TYPE.TASKS_SLIDES, taskId])
        const currentResult = await getCurrentResult({ currentSlides, currentUserId, slideId, taskId })
        result = await markupTasksService.createTaskAnnotation(
          taskId,
          currentResult?.markupSlideId,
          currentResult?.markupResultId,
          getClearFeature(data),
          type,
        )
      } else if (caseId && slideId) {
        // Геометрия аннотации. Для митозов и объектов нельзя ее менять
        const finalData = isObjectsCounting(type) ? data : getClearFeature(data)
        result = await annotationsService.createAnnotation(
          caseId,
          slideId,
          finalData,
          type,
          metric,
          zindex,
          caption,
          info,
        )
      }

      if (result && !noCashing) {
        queryClient.setQueryData(
          [QUERY_TYPE.ANNOTATIONS_STACK, slideId],
          [
            ...currentStack.slice(
              currentStack.length !== MAXIMUM_ANNOTATIONS_STACK_SIZE ? 0 : 1,
              MAXIMUM_ANNOTATIONS_STACK_SIZE,
            ),
            {
              annotation: result,
              type: 'add',
            },
          ],
        )
      }

      return result
    },
  )
}

export const useAddAnnotationsMutation = ({ caseId, slideId }: { caseId: number; slideId: number }) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const currentUserId = Number(useTypedSelector((state) => state.user.user?.userId))
  const currentStack = queryClient.getQueryData<IAnnotationStack[]>([QUERY_TYPE.ANNOTATIONS_STACK, slideId]) || []

  return useMutation(async (annotations: Partial<IAnnotation>[] & { noCashing?: boolean }) => {
    let result: (IAnnotation | undefined)[] = []
    if (taskId) {
      const currentSlides = queryClient.getQueryData<IMarkupSlide[]>([QUERY_TYPE.TASKS_SLIDES, taskId])
      const currentResult = await getCurrentResult({ currentSlides, currentUserId, slideId, taskId })

      result = await markupTasksService.createTaskAnnotations(
        taskId,
        currentResult?.markupSlideId,
        currentResult?.markupResultId,
        annotations,
        slideId,
      )
    } else {
      const newAnnotations: Partial<IAnnotation>[] = []
      // Геометрия аннотации. Для митозов и объектов нельзя ее менять
      annotations.forEach((annotation) => {
        const newAnnotationData = isObjectsCounting(annotation?.type)
          ? annotation?.data
          : getClearFeature(annotation?.data)

        newAnnotations.push({
          ...annotation,
          data: newAnnotationData,
        })
      })

      result = await annotationsService.createAnnotations(caseId, newAnnotations)
    }

    queryClient.invalidateQueries([QUERY_TYPE.ANNOTATION, { caseId }])
    const ids = queryClient.getQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }])
    const slideAnnotationIds = result?.map((annotation) => Number(annotation?.slideAnnotationId)) || []

    ids &&
      queryClient.setQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }], {
        date: new Date(),
        ids: [...ids.ids, ...slideAnnotationIds],
      })

    result?.forEach((item) => {
      queryClient.setQueryData([QUERY_TYPE.ANNOTATION, item?.slideAnnotationId], item)
    })
    dispatch(annotationsSlice.actions.addAnnotationsToVisible(slideAnnotationIds))

    if (!annotations.noCashing) {
      queryClient.setQueryData(
        [QUERY_TYPE.ANNOTATIONS_STACK, slideId],
        [
          ...currentStack.slice(
            currentStack.length !== MAXIMUM_ANNOTATIONS_STACK_SIZE ? 0 : 1,
            MAXIMUM_ANNOTATIONS_STACK_SIZE,
          ),
          {
            annotation: result,
            type: 'add',
          },
        ],
      )
    }

    return result
  })
}

export const useIntersectAnnotationMutation = ({ caseId, slideId }: { caseId: number; slideId: number }) => {
  const queryClient = useQueryClient()
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const isAtlas = !useSelector(selectAtlasViewerUrlSlideId)
  const { mutateAsync: deleteAnnotation } = deleteAnnotationMutation(
    {
      caseId,
      currentUserId,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_TYPE.ANNOTATION, { caseId }])
      },
    },
  )
  const { mutateAsync: addAnnotation } = useAddAnnotationMutation({
    caseId,
    slideId,
  })
  const { mutateAsync: editAnnotationAsync } = useChangeAnnotationMutation({
    caseId,
    slideId,
  })

  const { data: slide } = useSlideQuery({ caseId, slideId, source: isAtlas ? 'ATLAS' : 'PLATFORM' })
  const mppX = getSlideMppx(slide)

  const getIsGeometrySimple = (geometry: jsts.geom.Geometry) => {
    const geomFactory = new jsts.geom.GeometryFactory()
    try {
      const linearRing = geomFactory.createLinearRing(geometry?.getCoordinates())
      // self-intersection check
      return linearRing.isSimple()
    } catch (e) {
      // if geometry is not closed, then this is a union of primitives
      return true
    }
  }
  return useMutation(
    async ({ annotationsIds, mergeType }: { caseId: number; annotationsIds: number[]; mergeType: MergeType }) => {
      const annotations = annotationsIds.map((id) => queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, id]))
      const features = annotations.flatMap((annotation) =>
        getSingleFeatureFromGeoJson(annotation?.data?.formattedFeature),
      )
      const parser = new jsts.io.OL3Parser()
      //@ts-ignore
      parser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon)

      const featuresJstsGeom = features.map((feature) => parser.read(feature.getGeometry()))
      const areAllGeomSimple = featuresJstsGeom.filter((geom) => !getIsGeometrySimple(geom)).length === 0
      if (!areAllGeomSimple) {
        notices.error({
          message: t('Невозможно выполнить операцию, одна из аннотаций пересекает саму себя'),
        })
        throw Error
      }

      let mergeRes
      switch (mergeType) {
        case MergeType.EXCLUDE: {
          mergeRes = getExcludeGeom(featuresJstsGeom)
          break
        }
        case MergeType.INTERSECT: {
          featuresJstsGeom.forEach((item) => {
            featuresJstsGeom.forEach((feature) => {
              if (!item.intersects(feature)) {
                notices.error({
                  message: t('Аннотации не пересекаются'),
                })
                throw Error
              }
            })
          })
          featuresJstsGeom.reduce((acc, feature) => {
            if (!acc.intersects(feature)) {
              notices.error({
                message: t('Аннотации не пересекаются'),
              })
              throw Error
            }
            return acc
          }, featuresJstsGeom[0])
          mergeRes = featuresJstsGeom.reduce((acc, feature, i) => {
            acc = i > 0 ? acc.intersection(feature) : acc
            return acc
          }, featuresJstsGeom[0])
          break
        }
        case MergeType.SUBTACT: {
          const sortedByArea = featuresJstsGeom.sort((item1, item2) => item2.getArea() - item1.getArea())
          mergeRes = sortedByArea.reduce((acc, feature, i) => {
            acc = i > 0 ? acc.difference(feature) : acc
            return acc
          }, featuresJstsGeom[0])
          break
        }
        case MergeType.UNION: {
          mergeRes = getUnionGeom(featuresJstsGeom)
          break
        }
        case MergeType.DISUNION: {
          mergeRes = getDisunionGeom(featuresJstsGeom)
          break
        }
        case MergeType.INTERIOR: {
          mergeRes = getInteriorGeom(featuresJstsGeom)
          break
        }
      }
      const format = new GeoJSON()
      const getAnnotationAreaByFormattedFeature = (formattedFeature?: string) => {
        let metric = 0
        if (!formattedFeature) return 0
        const annotationF = getFeaturesFromGeoJson(formattedFeature)[0]
        const geom = annotationF.getGeometry()
        if (geom) {
          metric = getPolygonArea(geom, mppX)
        }
        return metric
      }

      if (Array.isArray(mergeRes)) {
        const firstAnnotation = annotations[0]
        await deleteAnnotation({ slideAnnotationId: annotationsIds[0] })
        await Promise.all(
          mergeRes.flat().map(async (item) => {
            item && features[0].setGeometry(parser.write(item))
            unsetSlidePositioningForAnnotation(features[0])
            const formattedFeature = format.writeFeatures([features[0]])

            const data = {
              formattedFeature: formattedFeature,
              type: 'ANNOTATION',
            }
            return addAnnotation({
              data: getClearFeature(data),
              metric: getAnnotationAreaByFormattedFeature(formattedFeature),
              type: AnnotationType.POLYGON,
              zindex: firstAnnotation?.zindex || 0,
            })
          }),
        )
        await queryClient.invalidateQueries([QUERY_TYPE.ANNOTATION, { caseId }])
        await queryClient.invalidateQueries([QUERY_TYPE.ANNOTATION, { slideId }])
      } else {
        const geometry = parser.write(mergeRes)
        const firstAnnotation = annotations[0]
        const { slideAnnotationId, zindex } = firstAnnotation || {}
        if (geometry.flatCoordinates.length === 0) {
          notices.error({
            message: t('Ошибка операции. Отсутствует геометрия'),
          })
          throw Error
        }
        mergeRes && features[0].setGeometry(geometry)
        unsetSlidePositioningForAnnotation(features[0])

        const data = {
          formattedFeature: format.writeFeatures([features[0]]),
          type: 'ANNOTATION',
        }
        slideAnnotationId &&
          (await editAnnotationAsync({
            caption: firstAnnotation?.caption,
            data: getClearFeature(data),
            metric: getAnnotationAreaByFormattedFeature(data?.formattedFeature),
            slideAnnotationId,
            type: AnnotationType.POLYGON,
            zindex,
          }))
      }
    },
    {
      onSuccess: async (
        _,
        {
          annotationsIds,
        }: {
          caseId: number
          annotationsIds: number[]
          mergeType: MergeType
        },
      ) => {
        const [__, ...annotations] = annotationsIds.map((id) =>
          queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, id]),
        )
        if (!annotations || annotations.length === 0) {
          return
        }
        await Promise.all(
          annotations.map(async (annotation) => {
            if (!annotation) return
            await deleteAnnotation(
              { slideAnnotationId: annotation.slideAnnotationId },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries([QUERY_TYPE.ANNOTATION, { slideId: annotation.slideId }])
                },
              },
            )
          }),
        )
      },
    },
  )
}

export type ChangeAnnotationMutationProp = UseMutateFunction<
  unknown,
  unknown,
  {
    /** Id редактируемой аннотации */
    slideAnnotationId: number
    /** Feature редактируемой аннотации */
    data?: INewAnnotationDataObject
    /**  Тип редактируемой аннотации */
    type: AnnotationType
    /** Метрика редактируемой аннотации */
    metric: number
    /** Высота по Z редактируемой аннотации */
    zindex?: number | undefined
    /** Описание редактируемой аннотации */
    caption?: string | undefined
    /** Массив всех аннотаций отсортированный по zindex */
    sortedIds?: number[] | undefined
    /** Записывать ли операцию в хранилище отмены последнего действия */
    noCashing?: boolean | undefined
  },
  IAnnotation | null
>

export const useChangeAnnotationMutation = (
  {
    caseId,
    slideId,
  }: {
    caseId?: number
    slideId?: number
  },
  options?: UseMutationOptions<
    unknown,
    unknown,
    {
      slideAnnotationId: number
      data?: INewAnnotationDataObject
      type?: AnnotationType
      metric: number
      sortedIds?: number[]
      noCashing?: boolean
    },
    IAnnotation | null
  >,
) => {
  const queryClient = useQueryClient()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const currentUserId = Number(useTypedSelector((state) => state.user.user?.userId))

  return useMutation(
    async ({
      caption,
      data,
      info,
      metric,
      noCashing,
      slideAnnotationId,
      type,
      zindex,
    }: {
      slideAnnotationId: number
      data?: INewAnnotationDataObject
      type: AnnotationType
      metric: number
      info?: IAnnotationInfo
      zindex?: number
      caption?: string
      sortedIds?: number[]
      noCashing?: boolean
    }) => {
      const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, slideAnnotationId])
      const annotationOwnerUserId = annotation?.userId ?? currentUserId

      if (!data) return
      if (taskId && slideId) {
        const currentSlides = queryClient.getQueryData<IMarkupSlide[]>([QUERY_TYPE.TASKS_SLIDES, taskId])
        const { markupResultId, markupSlideId } = await getCurrentResult({
          currentSlides,
          currentUserId: annotationOwnerUserId,
          slideId,
          taskId,
        })
        if (!markupSlideId || !markupResultId) return
        const result = await markupTasksService.updateTaskAnnotation(
          taskId,
          slideAnnotationId,
          getClearFeature(data),
          type,
          metric,
          slideId,
          markupSlideId,
          markupResultId,
        )
        queryClient.setQueryData([QUERY_TYPE.ANNOTATION, slideAnnotationId], result)
      } else if (slideId && caseId) {
        // Геометрия аннотации. Для митозов и объектов нельзя ее менять
        const finalData = isObjectsCounting(annotation?.type) ? data : getClearFeature(data)
        const result = await annotationsService.updateAnnotation(
          caseId,
          slideId,
          slideAnnotationId,
          finalData,
          type,
          metric,
          zindex,
          caption,
          info,
        )
        queryClient.invalidateQueries([QUERY_TYPE.ANNOTATION, { caseId }])
        queryClient.setQueryData([QUERY_TYPE.ANNOTATION, slideAnnotationId], result)
      }
      const currentStack = queryClient.getQueryData<IAnnotationStack[]>([QUERY_TYPE.ANNOTATIONS_STACK, slideId]) || []
      !noCashing &&
        queryClient.setQueryData(
          [QUERY_TYPE.ANNOTATIONS_STACK, slideId],
          [
            ...currentStack.slice(
              currentStack.length !== MAXIMUM_ANNOTATIONS_STACK_SIZE ? 0 : 1,
              MAXIMUM_ANNOTATIONS_STACK_SIZE,
            ),
            {
              annotation: annotation,
              type: 'edit',
            },
          ],
        )
    },
    {
      ...options,
      onSuccess: async () => {
        const ids = queryClient.getQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }])?.ids
        if (ids) {
          await queryClient.cancelQueries([QUERY_TYPE.ANNOTATION, { slideId }])
          queryClient.setQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }], () => ({
            date: new Date(),
            ids,
          }))
        }

        return null
      },
    },
  )
}

export const deleteAnnotationMutation = (
  {
    caseId,
    currentUserId,
  }: {
    caseId: number
    currentUserId?: number
  },
  options?: UseMutationOptions<
    unknown,
    unknown,
    { slideAnnotationId: number; noCashing?: boolean },
    IAnnotation | null
  >,
) => {
  const queryClient = useQueryClient()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const currSlideId = useSelector(selectUrlSlideId)

  return useMutation(
    async ({ noCashing, slideAnnotationId }: { slideAnnotationId: number; noCashing?: boolean }) => {
      const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, slideAnnotationId])
      if (!annotation) {
        return
      }
      const slideId = annotation?.slideId
      if (slideAnnotationId > 0) {
        if (taskId) {
          const taskSlideResult = queryClient.getQueryData<IMarkupSlideResult[]>([QUERY_TYPE.TASKS_SLIDE, currSlideId])
          const currentTaskResult = taskSlideResult?.find((item) => item.markupParticipant?.userId === currentUserId)
          await markupTasksService.deleteTaskAnnotation(
            taskId,
            currentTaskResult?.markupSlideId,
            annotation?.markupResultId,
            slideAnnotationId,
          )
        } else {
          await annotationsService.deleteAnnotation(caseId, slideId, slideAnnotationId)
        }
      }

      const ids = queryClient.getQueryData<{ ids: number[]; date: Date }>([
        QUERY_TYPE.ANNOTATION,
        { slideId: taskId ? currSlideId : slideId },
      ])
      ids &&
        queryClient.setQueryData<IAnnotationQuery>(
          [QUERY_TYPE.ANNOTATION, { slideId: taskId ? currSlideId : slideId }],
          {
            date: new Date(),
            ids: ids.ids.filter((id) => id !== slideAnnotationId),
          },
        )
      const currentStack =
        queryClient.getQueryData<IAnnotationStack[]>([QUERY_TYPE.ANNOTATIONS_STACK, currSlideId]) || []
      !noCashing &&
        queryClient.setQueryData(
          [QUERY_TYPE.ANNOTATIONS_STACK, currSlideId],
          [
            ...currentStack.slice(
              currentStack.length !== MAXIMUM_ANNOTATIONS_STACK_SIZE ? 0 : 1,
              MAXIMUM_ANNOTATIONS_STACK_SIZE,
            ),
            {
              annotation: annotation,
              type: 'del',
            },
          ],
        )
    },
    {
      ...options,
    },
  )
}

export const useDeleteAnnotationsMutation = (
  {
    caseId,
    currentUserId,
  }: {
    caseId: number
    currentUserId?: number
  },
  options?: UseMutationOptions<
    unknown,
    unknown,
    { slideAnnotationIds: number[]; noCashing?: boolean },
    IAnnotation | null
  >,
) => {
  const queryClient = useQueryClient()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const currSlideId = useSelector(selectUrlSlideId)

  return useMutation(
    async ({ noCashing, slideAnnotationIds }: { slideAnnotationIds: number[]; noCashing?: boolean }) => {
      const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, slideAnnotationIds[0]])
      if (!annotation) {
        return
      }
      const slideId = annotation?.slideId
      if (taskId) {
        const taskSlideResult = queryClient.getQueryData<IMarkupSlideResult[]>([QUERY_TYPE.TASKS_SLIDE, currSlideId])
        const currentTaskResult = taskSlideResult?.find((item) => item.markupParticipant?.userId === currentUserId)
        await markupTasksService.deleteTaskAnnotations(
          taskId,
          currentTaskResult?.markupSlideId,
          annotation?.markupResultId,
          slideAnnotationIds,
        )
      } else {
        await annotationsService.deleteAnnotations(caseId, slideId, slideAnnotationIds)
      }

      const ids = queryClient.getQueryData<{ ids: number[]; date: Date }>([
        QUERY_TYPE.ANNOTATION,
        { slideId: taskId ? currSlideId : slideId },
      ])
      ids &&
        queryClient.setQueryData<IAnnotationQuery>(
          [QUERY_TYPE.ANNOTATION, { slideId: taskId ? currSlideId : slideId }],
          {
            date: new Date(),
            ids: ids.ids.filter((id) => !slideAnnotationIds?.includes(id)),
          },
        )
      const currentStack =
        queryClient.getQueryData<IAnnotationStack[]>([QUERY_TYPE.ANNOTATIONS_STACK, currSlideId]) || []
      !noCashing &&
        queryClient.setQueryData(
          [QUERY_TYPE.ANNOTATIONS_STACK, currSlideId],
          [
            ...currentStack.slice(
              currentStack.length !== MAXIMUM_ANNOTATIONS_STACK_SIZE ? 0 : 1,
              MAXIMUM_ANNOTATIONS_STACK_SIZE,
            ),
            {
              annotation: annotation,
              type: 'del',
            },
          ],
        )
    },
    options,
  )
}

/**
 * Добавление аннотации в локальное хранилище react query
 */
export const addLocalAnnotationToCase = ({ localAnnotation }: IAddLocalAnnotation) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const slideId = Number(localAnnotation.slideId)
  const annotationsIsVisible = useTypedSelector((state) => state.annotations.annotationsIsVisible)
  const ids = queryClient.getQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }])?.ids || []
  const annotationId = Number(localAnnotation?.slideAnnotationId)

  queryClient.setQueryData([QUERY_TYPE.ANNOTATION, annotationId], localAnnotation)
  dispatch(annotationsSlice.actions.setAnnotationsIsVisible([...(annotationsIsVisible || []), annotationId]))
  queryClient.setQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }], {
    date: new Date(),
    ids: [...ids, annotationId],
  })

  return localAnnotation
}

/**
 * Удаление аннотации из локального хранилища react query
 */
export const removeLocalAnnotationToCase = ({ slideAnnotationId, slideId }: IRemoveLocalAnnotation) => {
  const queryClient = useQueryClient()
  const ids = queryClient.getQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }])?.ids || []
  queryClient.setQueryData([QUERY_TYPE.ANNOTATION, slideAnnotationId], {})
  queryClient.setQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }], {
    date: new Date(),
    ids: ids.filter((id) => id !== slideAnnotationId),
  })

  return slideAnnotationId
}
