import { useTypedSelector } from 'app/redux/lib/selector'
import { initDictionaries } from 'features/dictionaries/lib/handlers'
import { reportsSlice, selectedReportByCaseSelector } from 'features/reports/model/reportsSlice'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { viewerPageSlice } from 'pages/viewer'
import React, { RefObject, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { clearEmptyProps } from 'shared/lib/common'
import { useLisModeAsGemotest } from 'shared/lib/workspaces'
import { ModalHandle, ModalProvider } from 'shared/ui/modal'
import styled from 'styled-components'
import ISource from 'types/ISource'

import {
  useReportCreateMutation,
  useReportEditMutation,
  useReportRemoveMutation,
  useReportsDataQuery,
} from './api/query'
import ReportCreationForm, { ReportCreationFormProps } from './ui/ReportCreationForm'

type Props = {
  caseId: number
  source: ISource
  modalRef: RefObject<ModalHandle>
}

/** Код ошибки для специальной ошибки на UI*/
const CONFLICT_SERVER_CODE = 409

const StyledModalProvider = styled(ModalProvider)`
  height: 100vh;
  .ant-modal-content {
    height: 100vh;
    .ant-modal-close {
      top: 2px;
      right: 2px;
    }
  }
`

const ReportCreationModalContainer = ({ caseId, modalRef, source }: Props) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const isGemotest = useLisModeAsGemotest()
  const [saveReportError, setSaveReportError] = useState<string>('')
  const [isConflictError, setIsConflictError] = useState<boolean>(false)
  const initialReport = useTypedSelector((state) => state.reports.creationModalInitialReport)
  const index = useTypedSelector(selectedReportByCaseSelector(caseId))
  const { data } = useReportsDataQuery(caseId, source)
  const workspaceId = useCurrentWorkspaceId()
  const { isLoading: isReportCreating, mutate: createMutate } = useReportCreateMutation()
  const { isLoading: isReportUpdating, mutate: editMutate } = useReportEditMutation()
  const { mutate: removeMutate } = useReportRemoveMutation()
  const { t } = useTranslation()
  const closeModal = useCallback(() => {
    resetReportError()
    initDictionaries(String(workspaceId))
    dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(false))
    modalRef.current?.close()
    dispatch(reportsSlice.actions.setInitialReport(undefined))
  }, [workspaceId])

  const onSaveReportError = (error: any) => {
    const data = error?.response?.data
    const isConflict = data?.status === CONFLICT_SERVER_CODE
    const uiError = isConflict
      ? t('Заключение было подписано в ЛИС, редактирование невозможно')
      : String(data?.message || error)

    setIsConflictError(isConflict)
    setSaveReportError(uiError)
  }

  const resetReportError = () => {
    setIsConflictError(false)
    setSaveReportError('')
  }

  const onSuccess = () => {
    closeModal()
    queryClient.invalidateQueries([QUERY_TYPE.REPORT_DATA, { caseId }])
  }

  const onSave: ReportCreationFormProps['onSave'] = async ({
    caseMacroDescription,
    comment,
    complexity,
    icd10,
    microDescription,
    morphologyCode,
    report,
    signed,
  }) => {
    let payload = {}
    if (isGemotest) {
      payload = {
        signed,
      }
    }

    const formReport = {
      ...(initialReport || {}),
      ...payload,
      caseId,
      caseMacroDescription,
      comment,
      customInfo: { complexity, type: 'BASIC' },
      icd10,
      microDescription,
      morphologyCode,
      report,
    }
    // очищаем пустые поля для формы
    const clearFormReport = clearEmptyProps(formReport)

    resetReportError()
    if (initialReport?.medicalReportId) {
      editMutate(
        {
          caseId,
          report: clearFormReport,
          reportId: initialReport.medicalReportId,
        },
        { onError: onSaveReportError, onSuccess },
      )
    } else {
      createMutate(
        {
          ...payload,
          caseId,
          report: formReport,
        },
        {
          onError: onSaveReportError,
          onSuccess,
        },
      )
    }
  }

  const onDelete = (reportId: number) => {
    resetReportError()
    removeMutate(
      {
        caseId,
        reportId,
      },
      {
        onSuccess: () => {
          const reportsLength = data?.length || 0
          if (index + 1 > reportsLength)
            dispatch(reportsSlice.actions.setSelectedReportIndex({ caseId, index: index - 1 }))
          onSuccess()
        },
      },
    )
  }

  return (
    <StyledModalProvider
      ref={modalRef}
      destroyOnClose
      onCancel={closeModal}
      footer={null}
      width="fit-content"
      centered={true}
      bodyStyle={{
        padding: 0,
      }}
    >
      <ReportCreationForm
        onSave={onSave}
        onCancel={closeModal}
        saveError={saveReportError}
        isConflictError={isConflictError}
        isSaving={isReportCreating || isReportUpdating}
        initialReport={initialReport}
        onDelete={onDelete}
      />
    </StyledModalProvider>
  )
}

export default ReportCreationModalContainer
