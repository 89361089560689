import { Space } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { checkIntegration } from 'entities/lis-integration'
import { SharePopover } from 'features/share'
import { ThumbnailsListContainer } from 'features/thumbnails'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import { forwardRef, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { getIcd10Localized, getLocalizedSiteName } from 'shared/lib/common'
import { formatDateWithWithTextMonth } from 'shared/lib/date'
import { isGuestHandler } from 'shared/lib/local-storage'
import { Descriptions, DescriptionsItem } from 'shared/ui/description'
import { ButtonElement, IconElement, SpaceElement, TitleElement, TooltipElement } from 'shared/ui/kit'
import styled, { css } from 'styled-components/macro'
import ICase, { ICaseRelation } from 'types/ICase'
import IUserRole from 'types/IUserRole'

import CaseActions from './CaseActions'

const StyledCasesCard = styled.div<{ isSelected?: boolean }>`
  padding: 16px;
  border-radius: 18px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 2px var(--color-bg-3);
  }

  border: 2px solid ${({ isSelected }) => (isSelected ? 'var(--color-purple)' : 'transparent')};
`

const CardHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 8px;
`

const expandedGrid = css`
  grid-template-areas: 'name name' 'creationInfo creationInfo';
`
const normalGrid = css`
  grid-template-areas: 'name creationInfo';
`

const CardHeaderInfo = styled.div<{ expanded: boolean }>`
  ${({ expanded }) => (expanded ? expandedGrid : normalGrid)};
  grid-template-rows: auto auto;
  display: block;
  overflow: hidden;
  grid-template-columns: auto 1fr;
  grid-gap: 8px;
  align-items: baseline;
`
const TitleInfo = styled(TitleElement)`
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const SpaceInfo = styled(Space)`
  & > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const StyledDescription = styled(Descriptions)`
  & .ant-descriptions-item {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`
const CommentsCountButton = styled(ButtonElement)`
  font-size: 11px;
  line-height: 12px;
  font-weight: 600;

  &.ant-btn-circle.ant-btn-lg {
    background: var(--color-purple);
    padding: 0 8px;
    border-radius: 32px;
  }
`

type Props = {
  case: ICase
  isSelected?: boolean
  onClick: () => void
  onCommentsClick: () => void
  changeStatus: (status: ICase['status']) => void
  deleteCase: () => void
  pathname: string
  access?: { fieldName: string; value: boolean } | any
}

const CaseCard = memo(
  forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {
      caseId: id,
      casePathologicalInfo,
      commentInfo,
      createdAt,
      integration,
      name,
      permissionLevel,
      relation,
      user,
      workspace,
    } = props.case
    const parsedUrl = props.pathname.split('/')

    const authorities = useTypedSelector((state) => state.user.authorities)
    const isArchiveManager = authorities?.includes(IUserRole.ROLE_ARCHIVE_MANAGER)
    const isShared = permissionLevel === 'READ_ONLY'
    const isGuest = isGuestHandler(authorities)
    const lisMode = useLisMode()
    const { t } = useTranslation()

    const icd10 = getIcd10Localized(casePathologicalInfo?.incomingIcd10)

    return (
      <StyledCasesCard data-testid="case-info-card" onClick={props.onClick} isSelected={props.isSelected} ref={ref}>
        <CardHeader>
          {name && (
            <CardHeaderInfo data-testid={'common-case-info-header'} expanded={name.length > 13}>
              <TitleElement
                level={2}
                data-testid={'case-name-header'}
                style={{
                  gridArea: 'name',
                  marginBottom: 5,
                  maxWidth: name.length > 13 ? 'none' : '200px',
                  whiteSpace: 'pre',
                }}
              >
                {name}
              </TitleElement>
              <SpaceInfo size={8} style={{ gridArea: 'creationInfo', width: 470 }}>
                <TitleInfo data-testid={'case-info-header'} level={4} type="secondary">
                  {createdAt && formatDateWithWithTextMonth(new Date(createdAt))}⠀{integration && workspace?.name}
                  {!integration && user?.fullname}⠀{!integration && workspace?.name}
                </TitleInfo>
              </SpaceInfo>
            </CardHeaderInfo>
          )}
          <div onClick={(e) => e.stopPropagation()}>
            <Space size={8}>
              {parsedUrl.includes('cases') && !isGuest && (
                <SharePopover caseId={id}>
                  <TooltipElement title={t('Поделиться случаем')}>
                    <ButtonElement
                      style={{ opacity: isShared ? 0.4 : undefined }}
                      disabled={isShared}
                      icon={<IconElement name="share" />}
                      shape="circle"
                      size="large"
                    />
                  </TooltipElement>
                </SharePopover>
              )}
              <TooltipElement title={t('Комментарии')}>
                {commentInfo?.unreadCount ? (
                  <CommentsCountButton type="primary" shape="circle" size="large" onClick={props.onCommentsClick}>
                    +{commentInfo.unreadCount}
                  </CommentsCountButton>
                ) : (
                  <ButtonElement
                    icon={<IconElement name="comment" />}
                    shape="circle"
                    size="large"
                    onClick={props.onCommentsClick}
                  />
                )}
              </TooltipElement>
              {relation === ICaseRelation.OWNER && (props.access || isArchiveManager) && (
                <CaseActions
                  caseRecord={props.case}
                  changeStatus={props.changeStatus}
                  accessArchive={isArchiveManager}
                  deleteCase={props.deleteCase}
                  access={props.access}
                />
              )}
            </Space>
          </div>
        </CardHeader>
        <StyledDescription>
          {checkIntegration('caseInfo', 'incomingIcd10', lisMode) && icd10 && (
            <DescriptionsItem label={t('МКБ-10')} span={3}>
              {icd10.code} {icd10.name}
            </DescriptionsItem>
          )}
          {checkIntegration('caseInfo', 'sites', lisMode) &&
            casePathologicalInfo?.sites &&
            casePathologicalInfo?.sites?.length > 0 &&
            casePathologicalInfo?.sites?.map((item, i) => (
              <DescriptionsItem key={i} label={t('Локализация')} span={3}>
                {item.site ? getLocalizedSiteName(item.site) : ''}
              </DescriptionsItem>
            ))}
          {checkIntegration('caseInfo', 'clinicalInfo', lisMode) && casePathologicalInfo?.clinicalInfo && (
            <DescriptionsItem label={t('Диагноз основного заболевания')} span={3}>
              {casePathologicalInfo?.clinicalInfo}
            </DescriptionsItem>
          )}
          {checkIntegration('caseInfo', 'additionalInfo', lisMode) && casePathologicalInfo?.additionalInfo && (
            <DescriptionsItem label={t('Доп. клинические сведения')} span={3}>
              {casePathologicalInfo?.additionalInfo}
            </DescriptionsItem>
          )}
          {checkIntegration('caseInfo', 'macroDescription', lisMode) && casePathologicalInfo?.macroDescription && (
            <DescriptionsItem label={t('Макроописание')} span={3}>
              {casePathologicalInfo?.macroDescription}
            </DescriptionsItem>
          )}
        </StyledDescription>
        <SpaceElement size={8} />
        <ThumbnailsListContainer caseId={props.case.caseId} />
      </StyledCasesCard>
    )
  }),
)

export default CaseCard
