import { Group, RadioGroupProps, RadioProps } from 'antd/es/radio'
import styled from 'styled-components/macro'

const StyledGroupElemet = styled(Group)<{ theme: string }>`
  &.ant-radio-group {
    display: flex;
    background: var(--color-bg-3);
    padding: 2px;
    border-radius: 5px;
  }

  .ant-radio-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 100%;
    text-align: center;
    color: var(--color-text-3);
    height: 20px;
    border-radius: 3px;
    background: transparent;
  }
  &.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: ${({ theme }) => (theme === 'dark' ? 'var(--color-bg-4)' : 'var(--color-bg-1)')};
    box-shadow: ${({ theme }) =>
      theme === 'dark' ? '0 0.5px 1px 0.5px rgb(0 0 0 / 32%)' : '0 0.5px 1px 0.5px rgb(0 0 0 / 16%)'};
  }

  .ant-radio-button-wrapper:not(:first-child)::before,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: var(--color-bg-3);
    width: 5px;
    left: -3px;
  }

  &.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: var(--color-text-1);
    font-weight: 500;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    background: transparent;
  }
`

type Props = RadioGroupElementsProps & { colorTheme: string }

export type RadioGroupElementsProps = RadioProps & RadioGroupProps

export const SegmentElement = (props: Props) => (
  <StyledGroupElemet theme={props.colorTheme} optionType="button" buttonStyle="solid" {...props} />
)
