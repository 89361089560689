import { Divider } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { checkIntegration } from 'entities/lis-integration'
import { getFieldLabelBylisMode } from 'entities/lis-integration/lib/checkIntegration'
import { useCaseBioMaterialsQuery } from 'features/cases/api/query'
import caseService, { IFile } from 'features/cases/api/service'
import { usePatientCasesQuery } from 'features/patients/api/query'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getDiagnosticProcedureLocalize, getIcd10Localized, getLocalizedSiteName } from 'shared/lib/common'
import { formatDateForDisplay } from 'shared/lib/date'
import { Descriptions, DescriptionsItem } from 'shared/ui/description'
import { FullScreenModalElement, IconElement, SpinElement, TextElement } from 'shared/ui/kit'
import { PdfViewer } from 'shared/ui/pdf'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'
import { AllCasesToolPanel } from 'viewer/tools/ui/all-cases/AllCasesToolPanel'

type Props = {
  caseRecord: ICase
  isLoading: boolean
  caseRecordDocs?: IFile[]
  isViewer?: boolean
  /** Флаг управления рендерером длинной информации о кейсе */
  skipLongData?: boolean
}

const FileItem = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 5px 8px 5px 0;
  white-space: nowrap;
  cursor: pointer;

  & > svg {
    color: white;
    flex-shrink: 0;
  }
`

const IconWrapper = styled.div`
  color: var(--color-text-3);
  display: grid;
  place-items: center;

  &:hover {
    color: var(--color-text-1);
    cursor: pointer;
  }
`
const StyledPdfContainer = styled.div``

const ButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 18px;
  margin-bottom: 10px;
`
const StyledButton = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-purple-light);
  margin-right: 4px;
`

const StyledCountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  height: 16px;
  color: white;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  border-radius: 8px;
  background: var(--color-purple);
  padding: 2px 4px;
`

const StyledRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: solid 1px var(--color-bg-3);
  border-radius: 5px;
  margin-bottom: 8px;
`

const StyledColumn = styled.div`
  flex: auto;

  & > div {
    font-size: 11px;
    line-height: 12px;
    padding: 6px 5px;
  }

  & > div:first-child {
    background: var(--color-bg-3);
    color: var(--color-text-3);
  }
`

const StyledDivider = styled(Divider)`
  margin: 8px 0 16px !important;
`

const PDF_MIME_TYPE = 'application/pdf'

const CaseInfo = ({ caseRecord, caseRecordDocs, isViewer, skipLongData }: Props) => {
  const [fileToPreview, setFileToPreview] = useState<{ url: string; type: string }>()
  const [loading, setLoading] = useState<string | number>()
  const [allCasesOpened, setAllCasesOpened] = useState<boolean>(false)
  const query = usePatientCasesQuery(caseRecord?.patient?.patientProfileId)
  const localOriginPath = localStorage.getItem('ORIGIN_CASE_PATH')
  const workspaceId = useTypedSelector((state) => state.workspaces.currentWorkspace?.workspaceId)
  const { t } = useTranslation()
  const lisMode = useLisMode()
  const { caseId, casePathologicalInfo } = caseRecord
  const { data: caseBiomaterial } = useCaseBioMaterialsQuery({
    caseId: caseId,
    workspaceId: Number(workspaceId),
  })

  const icd10 = getIcd10Localized(casePathologicalInfo?.incomingIcd10)
  const diagnosticProcedure = getDiagnosticProcedureLocalize(casePathologicalInfo?.diagnosticProcedureType)

  const downloadFileHandler = async (fileToLoad: IFile) => {
    setLoading(fileToLoad.uid)
    const res = await caseService.fetchDoc(Number(fileToLoad.uid), caseId)
    if (res) {
      setLoading(undefined)
      const attachUrl = URL.createObjectURL(new Blob([res], { type: fileToLoad.type }))
      setFileToPreview({ type: fileToLoad.type, url: attachUrl })
    } else setLoading(undefined)
  }

  const onOpenAllCasesPanel = () => {
    setAllCasesOpened(!allCasesOpened)
    if (localOriginPath) localStorage.removeItem('ORIGIN_CASE_PATH')
  }

  useEffect(() => {
    if (localOriginPath) setAllCasesOpened(true)
  }, [])

  return (
    <>
      <DescriptionsItem span={3}>
        {caseRecordDocs?.map((item) =>
          loading === item.uid ? (
            <SpinElement key={item.uid}>
              <IconWrapper>
                <IconElement name="inbox" />
              </IconWrapper>
            </SpinElement>
          ) : (
            <FileItem onClick={() => downloadFileHandler(item)} key={item.uid}>
              {item.type === PDF_MIME_TYPE ? (
                <IconElement name="pdf" />
              ) : (
                <IconElement fill="var(--color-text-3)" name="jpeg" />
              )}
              <TextElement style={{ color: 'var(--color-purple-light)' }} ellipsis>
                {item.name}
              </TextElement>
            </FileItem>
          ),
        )}
      </DescriptionsItem>
      {!!caseRecordDocs?.length && <StyledDivider />}
      {isViewer && query?.data !== undefined && query?.data.length > 1 && (
        <ButtonWrapper onClick={onOpenAllCasesPanel}>
          <StyledButton>{t('Все случаи пациента')}</StyledButton>
          <StyledCountWrapper>{query?.data?.length}</StyledCountWrapper>
          {allCasesOpened && <AllCasesToolPanel caseRecords={query?.data} />}
        </ButtonWrapper>
      )}
      <Descriptions>
        {!skipLongData && (
          <>
            {/* Клинические сведения */}
            {checkIntegration('caseInfo', 'clinicalInfo', lisMode) && casePathologicalInfo?.clinicalInfo && (
              <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Клинические сведения')} span={3}>
                {casePathologicalInfo.clinicalInfo}
              </DescriptionsItem>
            )}
            {/* Макроописание */}
            {checkIntegration('caseInfo', 'macroDescription', lisMode) && casePathologicalInfo?.macroDescription && (
              <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Макроописание')} span={3}>
                {casePathologicalInfo.macroDescription}
              </DescriptionsItem>
            )}
          </>
        )}
        {/* Доп. клинические сведения */}
        {checkIntegration('caseInfo', 'additionalInfo', lisMode) && casePathologicalInfo?.additionalInfo && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Доп. клинические сведения')} span={3}>
            {casePathologicalInfo?.additionalInfo}
          </DescriptionsItem>
        )}
        {/* Номер заказа */}
        {checkIntegration('caseInfo', 'orderNumber', lisMode) && caseRecord.orderNumber && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Номер заказа')} span={3}>
            {caseRecord.orderNumber}
          </DescriptionsItem>
        )}
        {/* Отделение, направившее материал / Медицинская организация */}
        {checkIntegration('caseInfo', 'department', lisMode) && casePathologicalInfo?.department && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={getFieldLabelBylisMode('department', lisMode)} span={3}>
            {casePathologicalInfo?.department?.name}
          </DescriptionsItem>
        )}
        {/* Дата заказа / Дата исследования */}
        {checkIntegration('caseInfo', 'caseDate', lisMode) && caseRecord.caseDate && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={getFieldLabelBylisMode('caseDate', lisMode)} span={3}>
            {formatDateForDisplay(caseRecord.caseDate)}
          </DescriptionsItem>
        )}
        {/* Услуга */}
        {checkIntegration('caseInfo', 'services', lisMode) && caseRecord.services && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Услуга')} span={3}>
            {caseRecord.services?.map((item, index) => {
              const servicesLength = caseRecord.services?.length || 0
              const isLast = index === servicesLength - 1
              return <div key={item.code || item.name}>{`${item.name}${isLast === false ? `, ` : ''}`}</div>
            })}
          </DescriptionsItem>
        )}
        {/* МКБ-10 / Диагноз направления */}
        {checkIntegration('caseInfo', 'incomingIcd10', lisMode) && icd10 && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={getFieldLabelBylisMode('incomingIcd10', lisMode)}
            span={3}
          >
            {icd10.code} {icd10.name}
          </DescriptionsItem>
        )}
        {/* Дата забора материала / Дата забора */}
        {checkIntegration(
          'caseInfo',
          'materialTakeoutDate',
          lisMode,
          lisMode === 'DZM' ? caseRecord.fieldSet === 'DEFAULT' : true,
        ) &&
          caseRecord.materialTakeoutDate && (
            <DescriptionsItem
              style={{ paddingBottom: 8 }}
              label={getFieldLabelBylisMode('materialTakeoutDate', lisMode)}
              span={3}
            >
              {formatDateForDisplay(caseRecord.materialTakeoutDate)}
            </DescriptionsItem>
          )}
        {/* Тип материала */}
        {checkIntegration('caseInfo', 'materialType', lisMode) && casePathologicalInfo?.materialType && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Тип материала')} span={3}>
            {casePathologicalInfo.materialType?.name}
          </DescriptionsItem>
        )}
        {/* Задача прижизненного патологоанатомического исследования */}
        {checkIntegration('caseInfo', 'task', lisMode) && caseRecord.casePathologicalInfo?.task && (
          <DescriptionsItem
            style={{ paddingBottom: 8 }}
            label={t('Задача прижизненного патологоанатомического исследования')}
            span={3}
          >
            {casePathologicalInfo.task}
          </DescriptionsItem>
        )}
        {/* Способ получения материала / Способ получения */}
        {checkIntegration(
          'caseInfo',
          'diagnosticProcedureType',
          lisMode,
          lisMode === 'DZM' ? caseRecord.fieldSet === 'DEFAULT' : true,
        ) &&
          diagnosticProcedure && (
            <DescriptionsItem
              style={{ paddingBottom: 8 }}
              label={getFieldLabelBylisMode('diagnosticProcedureType', lisMode)}
              span={3}
            >
              {diagnosticProcedure?.name}
            </DescriptionsItem>
          )}
        {/* Тип исследованиия */}
        {checkIntegration('caseInfo', 'caseResearchType', lisMode) && casePathologicalInfo?.caseResearchType && (
          <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Тип исследования')} span={3}>
            {caseRecord.casePathologicalInfo.caseResearchType?.name}
          </DescriptionsItem>
        )}
        {/* Локализация / Биоматериалы */}
        {checkIntegration('caseInfo', 'sites', lisMode) &&
          casePathologicalInfo?.sites &&
          casePathologicalInfo?.sites?.length > 0 && (
            <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Локализация')} span={3}>
              {casePathologicalInfo.sites?.map((item) => (item.site ? getLocalizedSiteName(item.site) : ''))}
            </DescriptionsItem>
          )}
        {checkIntegration('caseInfo', 'biomaterial', lisMode) &&
          (lisMode === 'DZM' ? caseRecord.fieldSet === 'DEFAULT' : true) &&
          caseBiomaterial?.map((item) => (
            <>
              <DescriptionsItem
                style={{ paddingBottom: 2 }}
                label={getFieldLabelBylisMode('biomaterial', lisMode)}
                span={3}
              >
                {item?.site?.name}
              </DescriptionsItem>
              <DescriptionsItem span={3}>
                <StyledRow>
                  {lisMode === 'gemotest' && (
                    <StyledColumn>
                      <div>{t('Фрагментов')}</div>
                      <div>{item.fragmentsCount}</div>
                    </StyledColumn>
                  )}
                  <StyledColumn>
                    <div>{t('Блоков')}</div>
                    <div>{item.blocksCount}</div>
                  </StyledColumn>
                  <StyledColumn>
                    <div>{t('Стекол')}</div>
                    <div>{item.slidesCount}</div>
                  </StyledColumn>
                </StyledRow>
              </DescriptionsItem>
            </>
          ))}
      </Descriptions>
      <FullScreenModalElement visible={!!fileToPreview} onCancel={() => setFileToPreview(undefined)} footer={null}>
        {fileToPreview?.type === PDF_MIME_TYPE ? (
          <StyledPdfContainer>
            <PdfViewer file={fileToPreview.url} />
          </StyledPdfContainer>
        ) : (
          <div style={{ alignItems: 'center', display: 'flex', height: '100%', justifyContent: 'center' }}>
            <img src={fileToPreview?.url} style={{ maxHeight: '100%', maxWidth: '100%' }} />
          </div>
        )}
      </FullScreenModalElement>
    </>
  )
}

export default CaseInfo
