import { Pagination, PaginationProps, Typography } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { NoticesList } from 'features/notifications/NotificationsList'
import { useUploadedAllFilesQuery, useUploadedDuplicateFilesQuery, useUploadedFilesQuery } from 'features/sump'
import ContentLayout from 'pages/cases/ui/ContentLayout'
import {
  ATTACHED_TO_CASE_TAB,
  AWAITING_CASE_TAB,
  DELETED_TAB,
  NOT_ATTACHED_TO_CASE_TAB,
  PAGE_SIZE,
  SEARCH_KEY,
  SEARCH_MIN_LENGTH,
} from 'pages/sump/lib/constants'
import { useOpenViewers } from 'pages/viewer/lib/common/ViewerPageProvider'
import { SideRightPanelType, viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React, { RefObject, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useQueryParams } from 'shared/lib/hooks'
import { IconButtonElement, IconElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { ISumpSlide } from 'types/ISumpSlide'
import { useViewerDispatch } from 'viewer/container'

import { SumpAutocompleteSearchContainer } from './SumpAutoCompleteSearchContainer'
import SumpTopActions, { SUMP_TAB } from './SumpTopActions'
import { SearchFilesList } from './tables'
import FilesList from './tables/FilesList'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`

const StyledPagination = styled(Pagination)`
  margin-right: 12px;
  .ant-pagination-item a {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
  }

  .ant-pagination-item-active {
    background: var(--color-purple);
  }

  .ant-pagination-item-active a {
    color: white;
  }

  .ant-pagination-total-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    height: auto;
  }
`

// Ожидает
// Ошибки: проблема
// Разобрано: проблема, время разбора, разобрано
// Корзина: проблема, перенесено, время переноса
type Props = {
  /** Ссылка на кнопку уведомления. */
  notificationButton?: RefObject<HTMLDivElement | null>
}

const SumpRoute = ({ notificationButton }: Props) => {
  const dispatch = useDispatch()
  const { activeViewerId: viewerId } = useOpenViewers()
  const viewerDispatch = useViewerDispatch(viewerId)
  const [selectedRows, setSelectedRows] = useState<ISumpSlide[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [searchString, setSearchString] = useState<string>('')
  /** Состояние поиска */
  const [isSearching, setIsSearching] = useState<boolean>(false)
  /** Состояние отображения дубликатов слайда */
  const [isDuplicateShow, setIsDuplicateShow] = useState<boolean>(false)
  /** Активный таб отстойника из строки url */
  const queryTab = useQueryParams().get('tab') as SUMP_TAB
  /** Активный таб отстойника c учетом режима поиска */
  const activeTab = isSearching ? SEARCH_KEY : queryTab
  const filters = useTypedSelector((state) => state.viewerPage.sumpFilters[activeTab])
  const rightPanelType = useTypedSelector((state) => state.viewerPage.rightPanelType)
  const sumpDuplicateBarcodeShowNumber = useTypedSelector((state) => state.viewerPage.sumpDuplicateBarcodeShowNumber)
  /** Состояние типа таба для выделенных строк */
  const [selectedTypeTab, setSelectedTypeTab] = useState<SUMP_TAB>()
  const { data, isFetching } = useUploadedFilesQuery(queryTab, filters, searchString)
  const { data: searchData, isFetching: isSearchFetching } = useUploadedAllFilesQuery(
    filters,
    searchString,
    SEARCH_MIN_LENGTH,
  )
  const { data: duplicateData, isFetching: isDuplicateFetching } =
    useUploadedDuplicateFilesQuery(sumpDuplicateBarcodeShowNumber)
  const { Title } = Typography

  useEffect(() => {
    /** При выходе из поиска очищаем состояния выделнных строк и фильтры */
    if (!searchString) {
      setSelectedRows([])
      setSelectedRowKeys([])
      dispatch(
        viewerPageSlice.actions.resetSumpFilters({
          key: SEARCH_KEY,
        }),
      )
    }

    setIsSearching(searchString?.length > SEARCH_MIN_LENGTH - 1)
  }, [searchString])

  useEffect(() => {
    setIsDuplicateShow(!!sumpDuplicateBarcodeShowNumber)
  }, [sumpDuplicateBarcodeShowNumber])

  const onSelectChange = (newSelectedRowKeys: React.Key[], newSelectedRows: ISumpSlide[]) => {
    setSelectedRows(newSelectedRows)
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    onChange: onSelectChange,
    selectedRowKeys,
    selectedRows,
  }

  const onChange: PaginationProps['onChange'] = (page, pageSize) => {
    dispatch(
      viewerPageSlice.actions.setSumpFilters({
        filters: {
          ...filters,
          page: page - 1,
          size: pageSize,
        },
        key: activeTab,
      }),
    )
  }

  const onSearchBack = () => {
    viewerDispatch(viewerPageSlice.actions.setSumpDuplicateBarCodeShowNumber(''))
    setSearchString('')
  }

  const isNumber = (listNumber?: any) => (typeof listNumber === 'number' ? listNumber + 1 : undefined)
  const paginationData = isSearching ? searchData : isDuplicateShow ? duplicateData : data
  const { t } = useTranslation()

  return (
    <ContentLayout
      rightPanel={
        rightPanelType === SideRightPanelType.NOTIFICATION && <NoticesList notificationButton={notificationButton} />
      }
    >
      <ContentWrapper data-testid={'warehouse-page-content'}>
        <Header data-testid={'warehouse-menu-btns'}>
          {(isSearching || isDuplicateShow) && (
            <FlexRow>
              <IconButtonElement
                onClick={onSearchBack}
                style={{ margin: `0 8px 8px 0` }}
                icon={<IconElement name="arrowReturn" />}
              />
              <Title level={2}>
                {isDuplicateShow ? `${t('Дубликаты слайда')} "${sumpDuplicateBarcodeShowNumber}"` : t('Поиск')}
              </Title>
            </FlexRow>
          )}
          <SumpTopActions
            activeTab={selectedTypeTab || activeTab}
            isSearching={isSearching}
            isDuplicateShow={isDuplicateShow}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            setSelectedRowKeys={setSelectedRowKeys}
            updateSelectedTypeTab={setSelectedTypeTab}
          />
          <FlexRow data-testid={'warehouse-total-slides'}>
            <StyledPagination
              total={paginationData?.totalElements}
              current={isNumber(paginationData?.number)}
              pageSize={PAGE_SIZE}
              showSizeChanger={false}
              showTotal={(total) => `${t('Всего файлов')}: ${total}`}
              onChange={onChange}
            />
            {!isDuplicateShow && (
              <SumpAutocompleteSearchContainer searchQuery={searchString} setSearchString={setSearchString} />
            )}
          </FlexRow>
        </Header>
        {isSearching ? (
          <SearchFilesList
            selectedRowKeys={selectedRowKeys}
            rowSelection={rowSelection}
            listType="SEARCH"
            prevFiltersKey="SEARCH"
            filesList={searchData}
            isFetching={isSearchFetching}
            updateSelectedTypeTab={setSelectedTypeTab}
          />
        ) : isDuplicateShow ? (
          <SearchFilesList
            selectedRowKeys={selectedRowKeys}
            rowSelection={rowSelection}
            listType="DUPLICATE"
            prevFiltersKey="DUPLICATE"
            filesList={duplicateData}
            isFetching={isDuplicateFetching}
            updateSelectedTypeTab={setSelectedTypeTab}
          />
        ) : (
          <>
            {activeTab === ATTACHED_TO_CASE_TAB && (
              <FilesList
                selectedRowKeys={selectedRowKeys}
                rowSelection={rowSelection}
                filesList={data}
                isFetching={isFetching}
                listType="FIXED"
                prevFiltersKey="ATTACHED_TO_CASE"
              />
            )}
            {activeTab === NOT_ATTACHED_TO_CASE_TAB && (
              <FilesList
                selectedRowKeys={selectedRowKeys}
                rowSelection={rowSelection}
                filesList={data}
                isFetching={isFetching}
                listType="ERRORS"
                prevFiltersKey={NOT_ATTACHED_TO_CASE_TAB}
              />
            )}
            {activeTab === DELETED_TAB && (
              <FilesList
                selectedRowKeys={selectedRowKeys}
                rowSelection={rowSelection}
                filesList={data}
                isFetching={isFetching}
                listType="BASKET"
                prevFiltersKey={DELETED_TAB}
              />
            )}
            {activeTab === AWAITING_CASE_TAB && (
              <FilesList
                selectedRowKeys={selectedRowKeys}
                rowSelection={rowSelection}
                filesList={data}
                isFetching={isFetching}
                listType="AWAITING"
                prevFiltersKey={AWAITING_CASE_TAB}
              />
            )}
          </>
        )}
      </ContentWrapper>
    </ContentLayout>
  )
}

export default SumpRoute
