import { View } from 'ol'
import { MouseWheelZoom } from 'ol/interaction'
import { viewerPageSlice } from 'pages/viewer'
import { useMapParamsProvided } from 'pages/viewer/lib/common/MapsProvider'
import {
  useViewerIdMap,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import { rotateCoordinate } from 'pages/viewer/lib/helper'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useEventBusProvided } from 'shared/lib/EventBus'
import { getResolutionByZoomLevel } from 'shared/lib/metadata'
import { IMapOl } from 'types/IMapOl'
import ISlide from 'types/ISlide'
import TViewerId from 'types/TViewerId'
import { useSlideMapViewSelector } from 'viewer/container/lib/useViewerSelector'

/** Пороговая сумма для перехода на следующий уровень зума для колесика мыши */
const WHEEL_DELTA_SUM_THRESHOLD = 20
/** Пороговая сумма для перехода на следующий уровень зума для тачпада */
const PINCH_DELTA_SUM_THRESHOLD = 10

type Props = {
  viewerId: TViewerId
  map: IMapOl
  slide?: ISlide
}

const MouseWheelZoomInteraction = ({ map, slide, viewerId }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeViewerId, isFastTravel } = useViewerPageProvided()
  const mapForFastTravel = useViewerIdMap(activeViewerId)
  const { slideId } = useViewerIdSlideState(viewerId)
  const { getOriginParams } = useMapParamsProvided()
  const origin = getOriginParams(viewerId)
  const bus = useEventBusProvided()
  const origZoomLlv =
    getOriginParams(viewerId) !== undefined ? getResolutionByZoomLevel(origin.origZoom || 0, slide) : 0
  const minResolution = map.getView().getMinResolution()
  const maxZoomLvl = getResolutionByZoomLevel(minResolution || 0, slide) || 0
  // eslint-disable-next-line no-magic-numbers
  const zoomSteps = [0.31, 0.5, 0.63, 0.75, 1, 1.25, 2, 2.5, 3.7, 5, 7.5, 10, 15, 20, 30, 40, 60, 80]
  const zoomLevels = [...zoomSteps, origZoomLlv, maxZoomLvl]
    .sort((a, b) => a - b)
    .filter((it) => it >= origZoomLlv && it <= maxZoomLvl)
    .filter((val, idx, array) => array.indexOf(val) === idx)

  const mouseWheelZoom = useRef<any>(null)
  const zoomLevelIndex = useRef<number>(0)
  const wheelDeltaSum = useRef(0)
  const prevScrollDirection = useRef<string | null>(null)
  const fastTravel = useRef(isFastTravel)
  const firstRender = useRef(true)

  useEffect(() => {
    fastTravel.current = isFastTravel
  }, [isFastTravel])
  const { viewZoom } = useSlideMapViewSelector({ slideId, viewerId })

  /** Корректирование уровня зума
   *  необходимо для очередного открытого слайда
   */
  const setZoomLevelIndex = (view: View) => {
    if (!view) return
    const resolution = view?.getResolution()
    if (!resolution) return
    const zoomLevel = +getResolutionByZoomLevel(resolution, slide).toFixed(2)
    const closest = [...zoomLevels].sort((a, b) => Math.abs(zoomLevel - a) - Math.abs(zoomLevel - b))[0]
    zoomLevelIndex.current = zoomLevels.findIndex((it) => it === closest)
  }

  const onZoom = (evt: any) => {
    const view = fastTravel.current ? mapForFastTravel.getView() : evt?.map?.getView()
    if (firstRender.current) {
      setZoomLevelIndex(view)
    }
    firstRender.current = false

    let wheelDelta = evt.originalEvent.wheelDelta
    const ctrlKey = evt.originalEvent.ctrlKey
    if (wheelDelta === 0) return
    const scrollDirection = wheelDelta > 0 ? 'up' : 'down'
    if (Math.abs(wheelDelta) < WHEEL_DELTA_SUM_THRESHOLD || ctrlKey) {
      if (scrollDirection === prevScrollDirection.current) {
        wheelDelta = wheelDelta ? (wheelDelta > 0 ? 1 : -1) : 0
      } else {
        wheelDelta = ctrlKey ? PINCH_DELTA_SUM_THRESHOLD / 2 : WHEEL_DELTA_SUM_THRESHOLD
      }
    }
    prevScrollDirection.current = scrollDirection
    if (Math.abs(wheelDeltaSum.current) > (ctrlKey ? PINCH_DELTA_SUM_THRESHOLD : WHEEL_DELTA_SUM_THRESHOLD)) {
      wheelDeltaSum.current = 0
    }
    wheelDeltaSum.current += wheelDelta
    if (Math.abs(wheelDeltaSum.current) < (ctrlKey ? PINCH_DELTA_SUM_THRESHOLD : WHEEL_DELTA_SUM_THRESHOLD)) {
      return
    }
    wheelDeltaSum.current = 0

    /** Оригинальный центр view */
    const center = view.getCenter()
    /** Точка, в которую зумим */
    const zoomPoint = fastTravel.current ? center : evt.coordinate
    /** Отношение разрешений (во сколько раз призумили) */
    let scale = 1
    let resolution = 0

    const zoomIn = scrollDirection === 'up' && zoomLevelIndex.current < zoomLevels.length - 1
    const zoomOut = scrollDirection === 'down' && zoomLevelIndex.current > 0
    // Приближаем
    if (zoomIn) {
      zoomLevelIndex.current++
      resolution = getResolutionByZoomLevel(zoomLevels[zoomLevelIndex.current], slide)
      const oldResolution = getResolutionByZoomLevel(zoomLevels[zoomLevelIndex.current - 1], slide)
      scale = resolution / oldResolution
    }
    // Отдаляем
    if (zoomOut) {
      zoomLevelIndex.current--
      resolution = getResolutionByZoomLevel(zoomLevels[zoomLevelIndex.current], slide)
      const oldResolution = getResolutionByZoomLevel(zoomLevels[zoomLevelIndex.current + 1], slide)
      scale = resolution / oldResolution
    }

    /** Преобразованный центр со смещением */
    if (zoomIn || zoomOut) {
      const rotated = rotateCoordinate(center, 0, zoomPoint, scale)
      view.cancelAnimations()
      view.setCenter(rotated)
      view.setResolution(resolution)
      dispatch(viewerPageSlice.actions.setViewerIdOnMouseZoom(map?.get('target')))
      bus.$emit('afterMapZoom', map?.get('target') || viewerId)
    }
  }
  useEffect(() => {
    firstRender.current = true
  }, [slideId, viewerId, activeViewerId, viewZoom])

  useEffect(() => {
    mouseWheelZoom.current = new MouseWheelZoom()
    mouseWheelZoom.current.handleEvent = function (evt: any) {
      const type = evt.type
      if (type === 'wheel') {
        onZoom(evt)
      }

      return true
    }
    map.addInteraction(mouseWheelZoom.current)
    return () => {
      mouseWheelZoom.current && map.removeInteraction(mouseWheelZoom.current)
    }
  }, [map, slide, origZoomLlv, activeViewerId])

  return null
}

export default MouseWheelZoomInteraction
