import { Divider } from 'antd'
import { checkIntegration } from 'entities/lis-integration'
import CaseInfoContainer from 'features/case-info/CaseInfoContainer'
import WarningPreview from 'features/new-preview-panel/WarningPreview'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'

type Props = {
  /** Идентификатор случая */
  caseId: number
  /** Данные о случае */
  caseRecord?: ICase
}

const DescriptionTabContent = ({ caseId, caseRecord }: Props) => {
  const lisMode = useLisMode()
  const { t } = useTranslation()
  const { casePathologicalInfo, declinementComment } = caseRecord || {}
  const clinicalInfo = casePathologicalInfo?.clinicalInfo
  const macroDescription = casePathologicalInfo?.macroDescription

  return (
    <>
      {declinementComment && <WarningPreview title={declinementComment} />}
      <div style={{ display: 'flex', minHeight: '100%' }}>
        <div style={{ borderRight: '1px solid var(--color-border-1)', flex: 1, padding: 16 }}>
          {checkIntegration('caseInfo', 'clinicalInfo', lisMode) && clinicalInfo && (
            <>
              <StyledTitle>{t('Клинические сведения')}</StyledTitle>
              <StyledDescription>{clinicalInfo}</StyledDescription>
            </>
          )}
          {checkIntegration('caseInfo', 'macroDescription', lisMode) && macroDescription && (
            <>
              <StyledTitle style={{ marginTop: 16 }}>{t('Макроописание')}</StyledTitle>
              <StyledDescription>{macroDescription}</StyledDescription>
            </>
          )}
        </div>
        <Divider style={{ margin: 0 }} type={'vertical'} />
        <div style={{ width: 256 }}>
          <CaseInfoContainer caseId={caseId} source={'PLATFORM'} skipReports skipLongData />
        </div>
      </div>
    </>
  )
}

export default DescriptionTabContent

const StyledDescription = styled.div`
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
`
const StyledTitle = styled(TitleElement)`
  &.ant-typography {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: var(--color-text-3);
  }
`
