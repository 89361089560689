import { useTypedSelector } from 'app/redux/lib/selector'
import { useTaskSlideResult } from 'entities/tasks/api/query'
import {
  annotationsSlice,
  defaultAnnotationClass,
  useAddAnnotationsMutation,
  useAnnotationsQuery,
} from 'features/annotations'
import { IAnnotationQuery } from 'features/annotations/api/query'
import { useUserStatusContext } from 'features/multiplayer/lib'
import { debounce } from 'lodash'
import { Feature, MapBrowserEvent } from 'ol'
import { Geometry } from 'ol/geom'
import Draw from 'ol/interaction/Draw'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import Tooltip from 'ol-ext/overlay/Tooltip'
import { useViewerIdSlideState, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { selectTasksViewerUrlTaskId } from 'pages/viewer/model/viewerPageSlice'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import i18next from 'shared/lib/i18n/i18n'
import { AnnotationType, IAnnotation } from 'types/IAnnotations'
import { IMapOl } from 'types/IMapOl'
import { IMarkupClass, IMarkupSlide, IMarkupSlideResult } from 'types/IMarkupSlide'
import ISlide from 'types/ISlide'
import TViewerId from 'types/TViewerId'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useViewerDispatch, useViewerMainSelector } from 'viewer/container'
import { useSlideGroupType, viewerSlice } from 'viewer/container/model/viewerSlice'
import { viewerHelpSlice } from 'viewer/help/model/viewerHelpSlice'
import { AnnotationSaveErrorModal } from 'viewer/help/ui/modal/AnnotationSaveErrorModal'
import { getFeaturesFromGeoJson } from 'viewer/map'
import {
  createPolygonAreaLabel,
  getOptionsByAnnotationType,
  getPolygonArea,
  isInvalidGeometry,
} from 'viewer/map/layers/annotations/lib/annotationsDrawHelpers'
import { createLocalAnnotation, setSlidePositioningForAnnotation } from 'viewer/map/layers/annotations/lib/helpers'
import { isDoubleClick, isMiddleClick, isRightClick } from 'viewer/map/layers/olEvents'
import { defaultPlatformStyle, getArrowStyle, getDefaultTaskStyle } from 'viewer/map/layers/olStyles'
import { getFormattedFeature, isPolygonAnnotation } from 'viewer/map/lib/utils'

const t = i18next.t

type Props = {
  map: IMapOl
  type: AnnotationType
  viewerId: TViewerId
  mppX: number
  vectorAnnotations: IAnnotation[]
  /** Коллбэк для окончания рисования аннотации */
  onDrawEnd: (position: number[], annotationId: number, annotationType: AnnotationType) => void
}

/**
 * Currently drawn feature.
 */
let sketch: Feature<any> | null

/**
 * Ids локальных аннотаии
 */
let localAnnotationIds: number[] = []

/**
 * Дебаунс для сохранения аннотаций
 */
const SAVE_DELAY = 1000

/**
 * Метод установки Id локальной аннотации
 */
const setLocalAnnotationIds = (ids: number[]) => {
  localAnnotationIds = ids
}

/**
 * Метод добавления Id локальной аннотации
 */
const addLocalAnnotationId = (id: number | undefined) => {
  id && localAnnotationIds.push(id)
}

// @ts-ignore
const isPolygonStarted = (draw?: Draw) => draw?.sketchCoords_?.length > 0 && draw?.sketchCoords_[0]?.length > 3

const isDrawStarted = (draw?: Draw) => draw?.getOverlay().getSource().getFeatures().length !== 1

const AnnotationDrawInteraction = ({ map, mppX, onDrawEnd, type, vectorAnnotations, viewerId }: Props) => {
  const viewerDispatch = useViewerDispatch(viewerId)
  const dispatch = useDispatch()
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const { caseId, slideId, source } = useViewerIdSlideState(viewerId)
  const [badRequest, setBadRequest] = useState<boolean>(false)
  const { mutateAsync: addAnnotations } = useAddAnnotationsMutation({ caseId, slideId })
  const queryClient = useQueryClient()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const annotationsQueryId = taskId || caseId
  const currTaskClasses = queryClient.getQueryData<IMarkupClass[]>([QUERY_TYPE.TASKS_CLASSES, taskId])
  const slideResults = queryClient.getQueryData<IMarkupSlideResult[]>([QUERY_TYPE.TASKS_SLIDE, slideId])
  const slideRecord = queryClient.getQueryData<ISlide>([QUERY_TYPE.SLIDE, slideId])
  const currentSlidesArr = queryClient.getQueryData<IMarkupSlide[]>([QUERY_TYPE.TASKS_SLIDES, taskId])
  const currSlide = currentSlidesArr?.find((item) => item.slideId === slideId)
  const { data: useTasksSlideResult } = useTaskSlideResult(
    currSlide?.markupTaskId,
    currSlide?.markupSlideId,
    currSlide?.slideId,
  )
  const { data: annotationsIds } = useAnnotationsQuery(caseId, slideId, slideResults, useTasksSlideResult)
  const { currentClass } = useTypedSelector((state) => state.annotations)
  let prevCoordinates: MapBrowserEvent<any> | undefined
  const currentDraw = useRef<Draw>()
  const slideGroupType = useSlideGroupType(viewerId)
  const annotationType = type === AnnotationType.PEN ? AnnotationType.POLYGON : type
  const isPolygon = isPolygonAnnotation(type)
  const user = useTypedSelector((state) => state.user.user)
  const { isFastTravel } = useViewerPageProvided()
  const { actions } = annotationsSlice
  const { setHelpMessage } = viewerHelpSlice?.actions || {}
  const currentAnnotationsIds = annotationsIds?.ids || []
  const [ids, setIds] = useState<number[]>(currentAnnotationsIds)
  const [isDrawing, setIsDrawing] = useState<boolean>(false)
  const isVisiblePopup = slideRecord?.slideMetadata?.commonMetadata?.mppX
  const penSimplifyMode = useTypedSelector((state) => state.annotations.penSimplifyMode)

  const { targetUserId, unsubscribeFromUser } = useUserStatusContext()
  let metric = 0

  useEffect(() => {
    if (isDrawing) {
      unsubscribeFromUser()
    }
  }, [isDrawing, targetUserId])

  useDeepCompareEffect(() => {
    if (!isDrawing && currentAnnotationsIds) {
      setIds(currentAnnotationsIds)
    }
  }, [isDrawing, currentAnnotationsIds])

  /**
   * Добавление аннотации в локальное хранилище react query
   * @param {Partial<IAnnotation>} localAnnotation - объект локальной аннотации
   */
  const addLocalAnnotation = (localAnnotation: Partial<IAnnotation>) => {
    updateLocalAnnotation([localAnnotation])
    addLocalAnnotationId(localAnnotation?.slideAnnotationId)
  }

  /**
   * Обновление аннотации в локальном хранилище react query
   * @param {Partial<IAnnotation>} annotation - объект аннотации
   */
  const updateLocalAnnotation = (annotations: Partial<IAnnotation>[]) => {
    const annotationsId = annotations.map((annotation) => {
      // добавляем в хранилище новый id
      queryClient.setQueryData([QUERY_TYPE.ANNOTATION, annotation?.slideAnnotationId], annotation)
      return Number(annotation?.slideAnnotationId)
    })
    const slideId = Number(annotations[0]?.slideId)

    const caseData = queryClient.getQueryData<Record<number, IAnnotation[]>>([
      QUERY_TYPE.ANNOTATION,
      { caseId: annotationsQueryId },
    ])
    const ids = queryClient.getQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }])?.ids || []

    // обновляем хранилище аннотаций по слайду
    const newIds = [...Array.from(new Set([...ids, ...annotationsId]))]
    queryClient.setQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }], {
      date: new Date(),
      ids: newIds,
    })
    const caseDataBySlideId = caseData?.[slideId] || []
    // обновляем хранилище аннотаций по кейсу (добавляем аннотацию)
    slideId &&
      queryClient.setQueryData(
        [QUERY_TYPE.ANNOTATION, { caseId: annotationsQueryId }],
        caseData
          ? {
              ...caseData,
              [slideId]: [...caseDataBySlideId, ...annotations],
            }
          : { [slideId]: annotations },
      )
  }
  /**
   * Удаление локальных аннотаций из хранилища react query
   */
  const removeLocalAnnotations = () => {
    const caseData = queryClient.getQueryData<Record<number, IAnnotation[]>>([
      QUERY_TYPE.ANNOTATION,
      { caseId: annotationsQueryId },
    ])
    localAnnotationIds?.forEach((annotationId) => {
      queryClient.removeQueries([QUERY_TYPE.ANNOTATION, annotationId])
    })

    const ids = queryClient.getQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }])?.ids || []
    queryClient.setQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }], {
      date: new Date(),
      ids: ids.filter((id) => id > 0),
    })
    // обновляем хранилище аннотаций по кейсу (удаляем аннотацию)
    caseData &&
      queryClient.setQueryData([QUERY_TYPE.ANNOTATION, { caseId: annotationsQueryId }], {
        ...caseData,
        [slideId]: caseData[slideId]?.filter(
          (annotation) => !localAnnotationIds?.includes(annotation.slideAnnotationId),
        ),
      })

    setLocalAnnotationIds([])
  }

  /**
   * Сбрасываем режим активного рисования для слоя
   */
  const resetDrawMode = async () => {
    await dispatch(actions.setAnnotationType())
    await dispatch(actions.setDrawMode(false))
    viewerDispatch(viewerSlice.actions.setSelectedAnnotationsIds([]))
  }

  const getNewAnnotationClass = () => {
    if (!vectorAnnotations?.length) return
    const prevAnnotationFeature = getFeaturesFromGeoJson(
      vectorAnnotations[vectorAnnotations.length - 1]?.data?.formattedFeature,
    )
    const prevClass: AnnotationType | undefined = prevAnnotationFeature
      ? prevAnnotationFeature[0].get('class')
      : undefined

    if (currTaskClasses) {
      const prevCustomClass = currTaskClasses.find(
        ({ annotationType, name }) => name === prevClass && annotationType === type,
      )
      return prevCustomClass
        ? prevCustomClass.name
        : currTaskClasses.find(({ annotationType }) => annotationType === type)?.name
    }

    return prevClass
  }

  const getHelpMessage = (type: AnnotationType) => {
    const CONTINUE_POLIGON_DRAW = t(
      'Кликните один раз для продолжения, правой кнопкой для отмены действия, ESC или Enter для завершения',
    )
    const CLICK_ONCE_TO_STOP = t('Кликните один раз для остановки')
    const CONTINUE_FREEHAND_DRAW = t('Зажмите кнопку мыши для рисования, отпустите для завершения')

    if (type === AnnotationType.POINT) {
      return null
    }

    if ([AnnotationType.CIRCLE, AnnotationType.RECTANGLE].includes(type)) {
      return CLICK_ONCE_TO_STOP
    }

    if (type === AnnotationType.PEN) {
      return CONTINUE_FREEHAND_DRAW
    }

    return CONTINUE_POLIGON_DRAW
  }

  /**
   * Метод для выхода из рисования аннотаций (ESC или DbClick).
   * Для полигона выполняем сохранение
   *
   * @param {Draw} draw объект рисования OpenLayer
   */
  const cancelDraw = (draw?: Draw) => {
    if (isPolygon && !isInvalidGeometry(draw)) {
      isPolygonStarted(draw) && draw?.finishDrawing()
    }
    resetDrawMode()
  }
  /**
   * Метод для сохранения локальных аннотаций
   *
   * @param {slideId} number Id слайда
   */
  const saveLocalAnnotations = async ({ slideId }: { slideId: number }) => {
    const isNotTaskAndPoint = !taskId && type !== AnnotationType.POINT
    const caseData = queryClient.getQueryData<Record<number, IAnnotation[]>>([
      QUERY_TYPE.ANNOTATION,
      { caseId: annotationsQueryId },
    ])
    const slideAnnotations = caseData ? caseData[slideId] : []
    const localAnnotations = slideAnnotations?.filter((item) => item.slideAnnotationId < 0)

    if (localAnnotations?.length) {
      const remoteAnnotations = await addAnnotations(localAnnotations)
      remoteAnnotations && updateLocalAnnotation(remoteAnnotations as Partial<IAnnotation>[])

      removeLocalAnnotations()
      isNotTaskAndPoint && dispatch(actions.setAnnotationType())
      const lastSlideAnnotationId = remoteAnnotations?.at(-1)?.slideAnnotationId
      lastSlideAnnotationId &&
        !taskId &&
        viewerDispatch(viewerSlice.actions.setSelectedAnnotationsIds([lastSlideAnnotationId]))

      return remoteAnnotations
    }

    return undefined
  }

  /**
   * Метод для добавления локальных аннотаций на вьювер
   *
   * @param sketch
   */
  const applyLocalAnnotation = (sketch: Feature<any> | null) => {
    if (sketch) {
      const zindex = sketch?.get('annotationZindex')
      dispatch(annotationsSlice.actions.resetAnnotationFilter())
      sketch?.set('annotation_type', type)
      setSlidePositioningForAnnotation(sketch, map.getView())
      const data = {
        formattedFeature: getFormattedFeature(sketch, penSimplifyMode),
        type: 'ANNOTATION',
      }
      const localAnnotation: Partial<IAnnotation> = createLocalAnnotation(
        {
          data,
          metric,
          slideId,
          type: annotationType,
          zindex: zindex,
        },
        user,
      )
      addLocalAnnotation(localAnnotation)
    }
  }

  /**
   * Асинхронный метод сохранения аннотации .
   * Вызывается для вызова метода POST для конкретной аннотации
   */
  const asyncEndDraw = useCallback(
    async (e?: any) => {
      try {
        const remoteAnnotations = await saveLocalAnnotations({ slideId })
        const lastAnnotationId = remoteAnnotations?.at(-1)?.slideAnnotationId
        const lastAnnotationType = remoteAnnotations?.at(-1)?.type
        !taskId && lastAnnotationType !== AnnotationType.POINT && resetDrawMode()
        e && lastAnnotationType && onDrawEnd(e.target?.downPx_, Number(lastAnnotationId), lastAnnotationType)
        return lastAnnotationId
      } catch {
        setBadRequest(true)
      }
    },
    [slideId],
  )

  const debouncedDrawEnd = useCallback(debounce(asyncEndDraw, SAVE_DELAY), [asyncEndDraw])

  useHotkeys('esc', () => {
    cancelDraw(currentDraw.current)
  })
  useHotkeys(
    'enter',
    () => {
      const draw = currentDraw?.current
      const isPointNotAtlas = type === AnnotationType.POINT && source !== 'ATLAS'
      const isPolygonStarting = isPolygon && isPolygonStarted(draw)
      const isDrawStartingNotRuler = type !== AnnotationType.RULER && isDrawStarted(draw)
      if (isPolygon && isInvalidGeometry(draw)) return

      if (isPolygonStarting || isPointNotAtlas || isDrawStartingNotRuler) {
        draw?.finishDrawing()
      }
      isPointNotAtlas && dispatch(actions.setAnnotationType())
      dispatch(actions.setDrawMode(false))
    },
    [selectedAnnotationsIds],
  )

  useEffect(() => {
    const isPlatform = source === 'PLATFORM'
    !isFastTravel && dispatch(actions.setDrawMode(true))
    const taskClass = currTaskClasses?.find((item) => item.name === currentClass)
    const taskStyle = getDefaultTaskStyle(taskClass)
    const { drawType, freehand, geometryFunction, maxPoints } = getOptionsByAnnotationType(type, map)
    const layer = new VectorLayer({
      source: new VectorSource({}),
      style: [isPlatform ? defaultPlatformStyle : taskStyle],
      zIndex: 100,
    })
    map.addLayer(layer)
    const draw = new Draw({
      clickTolerance: 0,
      condition: (e) => {
        if (isDoubleClick(e, prevCoordinates) || isMiddleClick(e)) return false
        prevCoordinates = e
        if (isRightClick(e)) {
          isPolygon ? draw.removeLastPoint() : cancelDraw(draw)
          return false
        }
        return true
      },
      dragVertexDelay: 0,
      freehand,
      geometryFunction,
      maxPoints,
      snapTolerance: 1,
      source: layer.getSource(),
      style: (feature) => {
        const annotationType = feature.get('annotation_type')
        const isArrow = annotationType === AnnotationType.ARROW
        const geometry = feature.getGeometry()

        const customStyle = new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          image: new CircleStyle({
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.2)',
            }),
            radius: 5,
            stroke: new Stroke({
              color: '#52a899',
            }),
          }),
          stroke: new Stroke({
            color: '#52a899',
            lineDash: [10, 10],
            width: 2,
          }),
        })

        return isArrow ? [...getArrowStyle(geometry, '#52a899', -8), customStyle] : [customStyle]
      },
      type: drawType,
    })

    currentDraw.current = draw

    // @ts-ignore
    const tooltip = new Tooltip({
      getHTML: (feature: Feature<Geometry>) => {
        const type = feature?.get('annotation_type')
        const geometry = feature?.getGeometry()

        if (geometry && slideGroupType !== 'MACRO') {
          return '~' + createPolygonAreaLabel(metric, type === AnnotationType.RULER ? 'line' : 'area')
        }
        return
      },
      maximumFractionDigits: 2,
      offsetBox: 20,
      popupClass: 'custom-ol-draw-tooltip',
      positionning: 'bottom-right',
    })
    map.addInteraction(draw)
    map.addOverlay(tooltip)

    draw.on(['drawstart'], (evt: any) => {
      setIsDrawing(true)
      const helpMessage = getHelpMessage(type)
      helpMessage && viewerDispatch(setHelpMessage(helpMessage))
      sketch = evt.feature
      sketch?.set('annotation_type', type)
      sketch?.set('annotationZindex', ids ? ids.length : 0)
      sketch?.set(
        'class',
        currentClass ||
          getNewAnnotationClass() ||
          (currTaskClasses?.length && currTaskClasses[0]?.name) ||
          defaultAnnotationClass,
      )
      if (isVisiblePopup) {
        tooltip.setFeature(evt.feature)
        map.addOverlay(tooltip)
      }

      sketch?.getGeometry()?.on('change', (evt: any) => {
        const geom = evt.target

        if (geom) {
          metric = getPolygonArea(geom, mppX)
        }
      })
    })

    draw.on('drawend', (e) => {
      if (isPolygon && isInvalidGeometry(e.target as Draw, true)) return
      setIsDrawing(false)
      applyLocalAnnotation(sketch)
      map.removeOverlay(tooltip)
      debouncedDrawEnd(e)
    })

    return () => {
      map.removeInteraction(draw)
      map.removeOverlay(tooltip)
      map.removeLayer(layer)
      viewerDispatch(viewerHelpSlice.actions.hideHelpMessage())
    }
  }, [type, currentClass, ids.length, penSimplifyMode])

  useEffect(() => {
    if (type !== AnnotationType.PEN) dispatch(annotationsSlice.actions.setChangeMode(true))
    return () => {
      dispatch(annotationsSlice.actions.setChangeMode(false))
      dispatch(actions.setDrawMode(false))
    }
  }, [type])

  return (
    <AnnotationSaveErrorModal
      visible={badRequest}
      onRepeatBtnClick={() => {
        setBadRequest(false)
        asyncEndDraw().catch(() => setBadRequest(true))
      }}
      onCancelBtnClick={() => {
        setBadRequest(false)
        removeLocalAnnotations()
        resetDrawMode()
      }}
    />
  )
}

export default AnnotationDrawInteraction
