import { useTypedSelector } from 'app/redux/lib/selector'
import { notices } from 'features/notices'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { COLON_WITH_SPACE } from 'shared/text-constants'
import { ButtonElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { IBarcode } from 'types/ISumpSlide'
import IUserRole from 'types/IUserRole'

const BarcodeButton = styled(ButtonElement)<{ barcodetype: string }>`
  max-width: -webkit-fill-available;
  border: '1px solid rgba(255, 255, 255, 0.1)';
  background-color: ${({ barcodetype }) => (barcodetype === 'DIRECTORY_NAME' ? 'var(--color-purple)' : '')};

  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
type Props = {
  barcode: IBarcode
}

const BarcodeContainer = ({ barcode }: Props) => {
  const handleClickCopy = () => {
    notices.openOnBarCodeCopyNotification({
      key: 'copy',
    })
    navigator.clipboard.writeText(String(barcode.barcode))
  }
  const barcodeRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const isTestUser = useTypedSelector((state) => state.user.authorities)?.includes(IUserRole.ROLE_TEST_USER)

  if (barcode.type === 'DIRECTORY_NAME' && !isTestUser) {
    return <div>-</div>
  }
  return (
    <div ref={barcodeRef} style={{ margin: '4px 0' }}>
      <TooltipElement
        title={
          <>
            {t('Скопировать штрихкод')}
            {COLON_WITH_SPACE}
            <br />
            {barcode.barcode}
          </>
        }
        placement="top"
      >
        <BarcodeButton onClick={handleClickCopy} barcodetype={barcode.type}>
          {barcode.barcode}
        </BarcodeButton>
      </TooltipElement>
    </div>
  )
}

export default BarcodeContainer
