import { useTypedSelector } from 'app/redux/lib/selector'
import { useSlideQuery } from 'entities/slide'
import { useAddAnnotationMutation, useChangeAnnotationMutation } from 'features/annotations/api/query'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import GeoJSON from 'ol/format/GeoJSON'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import React, { memo, useContext, useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { getServerUrl, QUERY_TYPE } from 'shared/api'
import { usePrevious } from 'shared/lib/hooks'
import { MapContext } from 'shared/lib/map'
import { getResolutionByZoomLevel } from 'shared/lib/metadata'
import styled from 'styled-components'
import { AnnotationType, IAnnotation } from 'types/IAnnotations'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, viewerSlice } from 'viewer/container'
import { useAnnotationAuthorValidation } from 'viewer/map/layers/objects-counting/lib/hooks/useAnnotationAuthorValidation'
import {
  DEFAULT_COUNTING_AREA,
  SCREEN_RECORD_ZOOM_LVL,
  useObjectsCountingContext,
} from 'viewer/map/layers/objects-counting/ui/ObjectsCountingContext'

import CountingMethods from './CountingMethods'
import ObjectsCard from './ObjectsCard'
import MitosisReducedCount from './ObjectsReducedCount'
import ZoomHelp from './ZoomHelp'

/** Props for ObjectsContainer component */
type Props = {
  /** id текущего вьювера */
  viewerId: TViewerId
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 400px;
  height: calc(100% - 41px);
  max-height: calc(100% - 41px);
  display: flex;
  flex-direction: column;
`

const ObjectsContainer: React.FC<Props> = memo(({ viewerId }) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const isAuthorValid = useAnnotationAuthorValidation()
  const viewerDispatch = useViewerDispatch(viewerId)
  const { caseId, slideId, source } = useViewerIdSlideState(viewerId)
  const workspaceId = useCurrentWorkspaceId()
  const token = useTypedSelector((state) => state.user.token)

  const { map } = useContext(MapContext)
  const { data, onSaveAnnotation } = useObjectsCountingContext()
  const { data: currentSlide } = useSlideQuery({ caseId, slideId, source })
  const zoomLvl = getResolutionByZoomLevel(map?.getView()?.getResolution() || 1, currentSlide)
  const { objectsCountingMethod } = useTypedSelector((state) => state.viewers[viewerId].viewer)
  const { countingObjectType, isObjectsDeleting, whetherToOpenObjectsContext } = useTypedSelector(
    (state) => state.viewerPage,
  )
  const countingObjectTypeRef = useRef(countingObjectType)
  const isObjectsDeletingRef = useRef<boolean>(false)
  const whetherToOpenObjectsContextRef = useRef<boolean>(true)

  const prevCaseId = usePrevious(caseId)
  const prevSlideId = usePrevious(slideId)

  const { mutateAsync: addAnnotation } = useAddAnnotationMutation({ caseId: prevCaseId, slideId: prevSlideId })
  const { mutateAsync: editAnnotation } = useChangeAnnotationMutation({ caseId: prevCaseId, slideId: prevSlideId })

  useEffect(() => {
    countingObjectTypeRef.current = countingObjectType
  }, [countingObjectType])

  useEffect(() => {
    isObjectsDeletingRef.current = !!isObjectsDeleting
  }, [isObjectsDeleting])

  useEffect(() => {
    whetherToOpenObjectsContextRef.current = !!whetherToOpenObjectsContext
  }, [whetherToOpenObjectsContext])

  const beforeUnload = (evt?: BeforeUnloadEvent) => {
    if (!isAuthorValid) return
    const data = onSaveAnnotation()
    if (!data.fFeatures.length || isObjectsDeletingRef.current) return
    const fGeoJSON = new GeoJSON().writeFeatures(data.fFeatures)
    const payload = {
      caption: queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, data.objectId])?.caption,
      data: {
        formattedFeature: fGeoJSON,
        type: 'ANNOTATION',
      },
      info: {
        mitosis: data?.mitosis || 0,
      },
      metric: data.area || DEFAULT_COUNTING_AREA,
      type: countingObjectTypeRef.current as AnnotationType,
      zindex: 0,
    }
    dispatch(annotationsSlice.actions.resetAnnotationFilter())
    if (data?.objectId) {
      queryClient.setQueryData([QUERY_TYPE.ANNOTATION, data.objectId], payload)
    }

    if (evt) {
      const SERVER_URL = getServerUrl()
      const url = `${SERVER_URL}/workspace/${workspaceId}/case/${caseId}/slide/${slideId}/annotations${
        data.objectId ? `/${data.objectId}` : ''
      }`
      const headers = {
        Authorization: `Bearer ` + token,
        'Content-Type': 'application/json',
      }
      fetch(url, {
        body: JSON.stringify(payload),
        headers,
        keepalive: true,
        method: 'POST',
      })
    } else {
      data.objectId
        ? editAnnotation({ ...payload, slideAnnotationId: data.objectId })
        : addAnnotation(payload).then((res) => {
            countingObjectTypeRef.current === 'OBJECTS' &&
              window.location.href.includes('/viewer') &&
              whetherToOpenObjectsContextRef.current &&
              dispatch(
                annotationsSlice.actions.setObjectsMitosisContextMenuVisible({
                  objectId: res?.slideAnnotationId,
                  slideId,
                  visible: true,
                }),
              )
          })
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload)
    return () => {
      beforeUnload()
      viewerDispatch(viewerSlice.actions.selectObjectId())
      dispatch(viewerPageSlice.actions.setCountingObjectType())
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [])

  return (
    <Wrapper>
      <CountingMethods viewerId={viewerId} zoomLvl={zoomLvl} />
      <ObjectsCard viewerId={viewerId} currentSlide={currentSlide} />
      {objectsCountingMethod === 'screenRecord' && zoomLvl < SCREEN_RECORD_ZOOM_LVL && <ZoomHelp />}
      {Object.keys(data).length ? <MitosisReducedCount /> : null}
    </Wrapper>
  )
})

export default ObjectsContainer
