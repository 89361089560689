import generatePicker, { PickerDateProps } from 'antd/es/date-picker/generatePicker'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { format, parse } from 'date-fns'
import IMask from 'imask'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT_FOR_DISPLAY } from 'shared/lib/date'
import { getDatePickerLocale } from 'shared/lib/date/getDatePickerLocale'
import styled, { createGlobalStyle } from 'styled-components/macro'

import { IconElement } from './IconElement'

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig)

const StyledDatePicker = styled(DatePicker)`
  padding: 4px 2px 4px 8px;

  &.ant-picker {
    background: var(--color-bg-3);
    color: var(--color-text-1);
    width: 100%;
  }

  .ant-picker-input > input {
    line-height: 16px;
    color: var(--color-text-1);
  }

  .ant-picker-input > input::placeholder {
    color: var(--color-text-3);
  }

  .ant-picker-clear {
    background: var(--color-bg-3);
    color: var(--color-text-3);
    padding: 0 2px;
  }

  .ant-picker-suffix {
    color: var(--color-text-3);
  }
`

export const GlobalAntPickerStyle = createGlobalStyle`
  .ant-picker-header > button {
    color: var(--color-text-1);
  }

  .ant-picker-dropdown-range{
    ${() => modalBackground}
    ${() => modalShadow}
  }

  .ant-picker-cell {
    padding: 0px;
    color: var(--color-text-3);
  }

  .ant-picker-content th, .ant-picker-content td {
    font-weight: 400;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
    background: var(--color-purple-light);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before{
    background: var(--color-purple-light);
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before {
    background: var(--color-purple-light);
  }

  .ant-picker-content th, .ant-picker-cell-in-view {
    color: var(--color-text-1);
  }

  .ant-picker-header-view {
    font-weight: 400;
  } 

  .ant-picker-cell-disabled::before {
    background-color: var(--color-bg-2);
  }

  .ant-picker-cell-disabled > div {
    color: var(--color-text-3);
    font-weight: 400;
    opacity: 0.5;
  }

  .ant-picker-today-btn{
    color: var(--color-purple-light);
    font-weight: 400;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before{
    color: var(--color-purple-light);
  }
  .ant-picker-date-panel {
    width: auto;
  }
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after, .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after{
    background: var(--color-purple-light);
  }
  .ant-picker-date-panel .ant-picker-content th {
    max-width: 32px;
    min-width: 20px;
    width: 32px;
    height: 24px;
  }
  .ant-picker-date-panel .ant-picker-content {
    width: 232px;
  }
  .ant-picker-date-panel .ant-picker-body{
    padding: 16px 8px;
  }
  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range .ant-picker-cell-inner{
    color: var(--color-white);
  }

  .ant-picker-panel-container{
    background: var(--color-bg-2);
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner{
    background: var(--color-bg-3);
  }
  .ant-picker-header button {
    line-height: 32px;
  }
  .ant-picker-header-view {
    line-height: 32px;
  }
  .ant-picker-content thead th {
    line-height: 20px;
    height: 20px;
  }
  .ant-picker-cell-inner {
    width: 32px;
    border-radius: 0 !important;
  }
`

const MASKED = IMask.createMask({
  blocks: {
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    dd: { from: 1, mask: IMask.MaskedRange, to: 31 },
    yyyy: { from: 1800, mask: IMask.MaskedRange, to: 9999 },
  },
  format: (date: Date) => format(date, DATE_FORMAT_FOR_DISPLAY),
  mask: Date,
  parse: (date: string) => parse(date, DATE_FORMAT_FOR_DISPLAY, new Date()),
  pattern: DATE_FORMAT_FOR_DISPLAY,
})

export type ElementDatePickerProps = Omit<PickerDateProps<Date>, 'format' | 'picker' | 'onKeyDown'>

export const MaskedDatePickerElement = (props: ElementDatePickerProps) => {
  const dateStrRef = useRef<string>()
  const { i18n, t } = useTranslation()
  const dateLocale = getDatePickerLocale(i18n.language)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement
    const maskedValue = MASKED.resolve(input.value)
    input.value = maskedValue
    dateStrRef.current = maskedValue + event.key
  }

  const handleBlur = () => {
    if (!dateStrRef.current) return
    const maskedValue = MASKED.resolve(dateStrRef.current)
    if (maskedValue.split('').length === 10 && props.onChange) {
      const parsedDate = parse(maskedValue, DATE_FORMAT_FOR_DISPLAY, new Date()) || null
      props.onChange(parsedDate, dateStrRef.current)
    }
  }

  return (
    <StyledDatePicker
      format={DATE_FORMAT_FOR_DISPLAY}
      placeholder={t('Не выбрано')}
      suffixIcon={<IconElement name="calendar" size="md" />}
      locale={dateLocale}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      picker="date"
      {...props}
    />
  )
}
