import { without } from 'lodash'
import {
  AUTHORITIES_KEY,
  EXPIRATION_TIME_KEY,
  INVITE_TOKENS_KEY,
  TASKS_SLIDE_DATA,
  TOKEN_KEY,
  USER_KEY,
  WORKSPACE_ID_KEY,
} from 'shared/lib/common/storageKeys'
import { ILocalStorageTasksSlide } from 'types/IMarkupSlide'
import IUser from 'types/IUser'
import IUserData from 'types/IUserData'
import IUserRole from 'types/IUserRole'

export const getUserDataFromStorage = () => {
  const token = localStorage.getItem(TOKEN_KEY)
  const expirationTime = localStorage.getItem(EXPIRATION_TIME_KEY)
  const user = localStorage.getItem(USER_KEY)
  const authorities = localStorage.getItem(AUTHORITIES_KEY)
  const authoritiesArray = authorities ? JSON.parse(authorities) : []
  const userObject = user ? JSON.parse(user) : {}

  return {
    authorities: authoritiesArray || undefined,
    expirationTime: Number(expirationTime) || undefined,
    token: token || undefined,
    user: {
      ...userObject,
      userId: userObject.userId || userObject.id,
    } as IUser,
  }
}

export const setUserDataToStorage = (userData: IUserData) => {
  localStorage.setItem(TOKEN_KEY, userData.token || '')
  localStorage.setItem(EXPIRATION_TIME_KEY, userData?.expirationTime?.toString() || '')
  localStorage.setItem(USER_KEY, JSON.stringify(userData.user) || '')
  localStorage.setItem(AUTHORITIES_KEY, JSON.stringify(userData.authorities) || '')
}

export const removeUserDataFromStorage = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem(EXPIRATION_TIME_KEY)
  localStorage.removeItem(USER_KEY)
  localStorage.removeItem(AUTHORITIES_KEY)
  localStorage.removeItem(WORKSPACE_ID_KEY)
}

export const getTokenFromStorage = () => localStorage.getItem(TOKEN_KEY)

export const setInviteTokenToStorage = (inviteToken: string) => {
  const tokensArray = getInviteTokensFromStorage()
  tokensArray.push(inviteToken)
  localStorage.setItem(INVITE_TOKENS_KEY, JSON.stringify(tokensArray))
}
export const getInviteTokensFromStorage = (): string[] => {
  const inviteTokens = localStorage.getItem(INVITE_TOKENS_KEY)
  return inviteTokens && Array.isArray(JSON.parse(inviteTokens)) ? JSON.parse(inviteTokens) : []
}

export const removeInviteTokenFromStorage = (inviteToken: string) => {
  const tokensArray = getInviteTokensFromStorage()
  localStorage.setItem(INVITE_TOKENS_KEY, JSON.stringify(without(tokensArray, inviteToken)))
}

export const removeAllInviteTokensFromStorage = () => {
  localStorage.removeItem(INVITE_TOKENS_KEY)
}

export const getTasksSlideData = () => localStorage.getItem(TASKS_SLIDE_DATA)

export const setTasksSlideData = (data?: ILocalStorageTasksSlide[]) => {
  localStorage.setItem(TASKS_SLIDE_DATA, JSON.stringify(data))
}
export const isGuestHandler = (authorities?: IUserRole[]) =>
  authorities?.includes(IUserRole.ROLE_USER) ? false : authorities?.includes(IUserRole.ROLE_GUEST)

export const addToLocalStorage = <T>(key: string, value: T): void => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getFromLocalStorage = <T>(key: string): T | null => {
  const storage = localStorage.getItem(key)
  return storage ? JSON.parse(storage) : null
}
