import db from 'app/indexedDB'
import { useLiveQuery } from 'dexie-react-hooks'
import { TDIctICD10 } from 'features/dictionaries'
import { useCallback } from 'react'
import i18next from 'shared/lib/i18n/i18n'
import { DictionaryItem } from 'types/IDictionary'

import Icd10SelectElement from './ui/Icd10SelectElement'

const t = i18next.t

type Props = {
  onChange?: (icd10?: DictionaryItem) => void
  value?: DictionaryItem
  filterByVisible?: boolean
  placeholder?: string
  multiline?: boolean
}

const Icd10SelectContainer = ({
  filterByVisible = true,
  multiline,
  onChange,
  placeholder = t('Поиск по названию или коду…'),
  value,
  ...props
}: Props) => {
  const dictionary = useLiveQuery(async () => {
    //@ts-ignore
    const dpt = await db.dictionaries.where('id').equals('ICD_10').toArray()
    if (!dpt.length) return null
    return dpt[0].data
  }) as TDIctICD10

  const { data, updatedAt } = { ...dictionary }

  const onIcd10Change = useCallback(
    (id: number) => {
      if (data) {
        const icd10 = findIcd10(data.group2Icd10Codes, id)
        if (onChange && icd10) {
          onChange(icd10)
        }
      }
    },
    [updatedAt],
  )

  return (
    <Icd10SelectElement
      groups2Icd10Codes={data?.group2Icd10Codes || {}}
      onChange={onIcd10Change}
      data-testid={'icd10input-select'}
      style={{
        width: '100%',
      }}
      icd10={value}
      onDelete={() => onChange && onChange()}
      filterByVisible={filterByVisible}
      {...props}
      placeholder={placeholder}
      multiline={multiline}
    />
  )
}

const findIcd10 = (group2Icd10Codes: Record<string, DictionaryItem[]>, id: number) =>
  Object.values(group2Icd10Codes)
    .flatMap((it) => it)
    .find((icd10) => icd10.id === id)

export default Icd10SelectContainer
