import { Layout } from 'antd'
import { FC, ReactNode } from 'react'
import styled from 'styled-components/macro'

const { Content, Header, Sider } = Layout

const StyledHeader = styled(Header)`
  background-color: var(--color-bg-1);
  height: auto;
  border-bottom: 1px solid var(--color-border-1);
  padding: 0;
  line-height: 24px;
`

const StyledSider = styled(Sider)`
  background-color: var(--color-bg-1);
  border-right: 1px solid var(--color-border-1);
`
const StyledContent = styled(Content)`
  overflow: hidden;
  background-color: var(--color-bg-2);
`

const StyledLayout = styled(Layout)`
  height: 100%;
`

type Props = {
  header?: ReactNode
  sider?: ReactNode
}

const PrimaryLayout: FC<Props> = ({ children, header, sider }) => (
  <StyledLayout>
    <StyledHeader>{header}</StyledHeader>
    <Layout>
      {sider && <StyledSider width={224}>{sider}</StyledSider>}
      <StyledContent>{children}</StyledContent>
    </Layout>
  </StyledLayout>
)

export default PrimaryLayout
