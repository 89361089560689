import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'

import { REPORT_IVANOVA, REPORT_PETROVA, useInitMockReports } from './dashboardMockReports'
import KT_CASE_IVANOVA from './KT_CASE_IVANOVA'
import KT_CASE_PETROVA from './KT_CASE_PETROVA'
import MAMO_CASE_IVANOVA from './MAMO_CASE_IVANOVA'
import UZI_CASE_PETROVA from './UZI_CASE_PETROVA'

export const mockCasesPetrova = [KT_CASE_PETROVA, UZI_CASE_PETROVA]
export const mockCasesIvanova = [MAMO_CASE_IVANOVA, KT_CASE_IVANOVA]

export const dashboardMockCases = [...mockCasesPetrova, ...mockCasesIvanova]

export const useInitMockCases = () => {
  const queryClient = useQueryClient()
  useInitMockReports()
  useEffect(() => {
    dashboardMockCases.forEach((caseRecord) => {
      queryClient.setQueryData([QUERY_TYPE.CASE, caseRecord.caseId], () => caseRecord)
      caseRecord.slides.forEach((slide) => {
        queryClient.setQueryData([QUERY_TYPE.SLIDE, slide.slideId], () => slide)
      })
    })
    mockCasesPetrova.forEach((caseRecord) => {
      queryClient.setQueryData([QUERY_TYPE.REPORT, { caseId: caseRecord.caseId }], () => [
        REPORT_PETROVA.medicalReportId,
      ])
    })
    mockCasesIvanova.forEach((caseRecord) => {
      queryClient.setQueryData([QUERY_TYPE.REPORT, { caseId: caseRecord.caseId }], () => [
        REPORT_IVANOVA.medicalReportId,
      ])
    })
  }, [])
}
