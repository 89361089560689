import React, { useMemo } from 'react'
import styled from 'styled-components'

const MarkedText = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
  font-weight: 600;
`

interface IHighlighted {
  /**
   * Исходный текст для поиска
   */
  text?: string | null | number
  /**
   * Текстовое значение для поиска
   */
  search?: string
  /**
   * Цвет для выделения поискового фрагмента
   */
  color?: string
}

export default function Highlighted({ color = 'var(--color-text-1)', search = '', text }: IHighlighted): JSX.Element {
  const re = useMemo(() => {
    const SPECIAL_CHAR_RE = /([.?*+^$[\]\\(){}|-])/g
    const escapedSearch = search.replace(SPECIAL_CHAR_RE, '\\$1')
    return new RegExp(`(${escapedSearch})`, 'i')
  }, [search])

  return !text ? (
    <></>
  ) : (
    <div>
      {search === ''
        ? text
        : String(text)
            .split(re)
            .filter((part) => part !== '')
            .map((part, i) =>
              re.test(part) ? (
                <MarkedText key={part + i} color={color}>
                  {part}
                </MarkedText>
              ) : (
                part
              ),
            )}
    </div>
  )
}
