import { notification } from 'antd'
import { NotificationPlacement } from 'antd/lib/notification'
import SumpWarningCopy from 'pages/sump/ui/SumpWarningCopy'
import { Dispatch } from 'react'
import { IWorkspace } from 'types/IWorkspaces'
import ViewerWorkspaceSelectContainer from 'viewer/help/ui/ViewerWorkspaceSelectContainer'

import DistributionNotification from './ui/DistributionNotification'
import ErrorNotification from './ui/ErrorNotification'
import InfoNotification from './ui/InfoNotification'
import OnCaseRemoveNotification, { OnCaseRemoveNotificationProps } from './ui/OnCaseRemoveNotification'
import OnLinkingSlidesNotification, { OnLinkingSlidesNotificationProps } from './ui/OnLinkingSlidesNotification'
import OnOtherPatientCaseNotification, {
  OnOtherPatientCaseNotificationProps,
} from './ui/OnOtherPatientCaseNotification'
import OnSlideDuplicateNotification, { OnSlideDuplicateNotificationProps } from './ui/OnSlideDuplicateNotification'
import OnSlideRemoveNotification, { OnSlideRemoveNotificationProps } from './ui/OnSlideRemoveNotification'
import OnSumpSlideRemoveNotification from './ui/OnSumpSlideRemoveNotification'
import PasteNotification from './ui/PasteNotification'
import RedirectToWorkspaceNotification from './ui/RedirectToWorkspaceNotification'
import s from './ui/style.module.css'

type OpenOnSlideDeleteNotificationPayload = {
  key: string
} & OnSlideRemoveNotificationProps

const openOnSlideDeleteNotification = (payload: OpenOnSlideDeleteNotificationPayload) => {
  const { key, ...rest } = payload
  notification.open({
    duration: 0,
    key,
    message: <OnSlideRemoveNotification {...rest} />,
  })
}

type OpenOnSlideDuplicateNotificationPayload = {
  key: string
} & OnSlideDuplicateNotificationProps

const openOnSlideDuplicateNotification = (payload: OpenOnSlideDuplicateNotificationPayload) => {
  const { key, ...rest } = payload
  notification.open({
    duration: 0,
    key,
    message: <OnSlideDuplicateNotification {...rest} />,
  })
}

type OpenOnCaseDeleteNotificationPayload = {
  key: string
} & OnCaseRemoveNotificationProps

const openOnCaseRemoveNotification = (payload: OpenOnCaseDeleteNotificationPayload) => {
  const { key, ...rest } = payload
  notification.open({
    duration: 0,
    key,
    message: <OnCaseRemoveNotification {...rest} />,
  })
}

type openOnBarCodeCopyPayload = {
  key: string
}

const openOnBarCodeCopyNotification = (payload: openOnBarCodeCopyPayload) => {
  const { key, ...rest } = payload
  notification.open({
    duration: 4000,
    key,
    message: <SumpWarningCopy />,
  })
}

type OpenOnSumpSlideRemoveNotificationPayload = {
  key: string
}
const openOnSumpSlideRemoveNotification = (payload: OpenOnSumpSlideRemoveNotificationPayload) => {
  const { key, ...rest } = payload
  notification.open({
    duration: 4000,
    key: key,
    message: <OnSumpSlideRemoveNotification {...rest} />,
  })
}

type OpenOnLinkingSlidesNotificationPayload = {
  key: string
} & OnLinkingSlidesNotificationProps

const openOnLinkingSlidesNotification = (payload: OpenOnLinkingSlidesNotificationPayload) => {
  const { key, ...rest } = payload
  notification.open({
    duration: 4000,
    key: key,
    message: <OnLinkingSlidesNotification {...rest} />,
  })
}

type OpenOnOtherPatientCaseNotificationPayload = {
  key: string
} & OnOtherPatientCaseNotificationProps

const openOnOtherPatientCaseNotification = (payload: OpenOnOtherPatientCaseNotificationPayload) => {
  const { key, ...props } = payload
  notification.open({
    className: s.notification,
    duration: 0,
    key,
    message: (
      <OnOtherPatientCaseNotification
        {...props}
        onReturnToOriginCase={() => {
          props.onReturnToOriginCase()
          notification.close(key)
        }}
      />
    ),
    onClose: () => localStorage.removeItem('ORIGIN_CASE_PATH'),
    placement: 'bottom',
  })
}

type ErrorPayload = {
  key?: string
  message?: string
  onClose?: () => void
  theme?: any
  placement?: NotificationPlacement
}

const error = ({ key, message }: ErrorPayload) => {
  notification.open({
    key,
    message: <ErrorNotification message={message} />,
  })
}
const info = ({ key, message, ...props }: ErrorPayload) => {
  notification.open({
    key,
    message: <InfoNotification message={message} />,
    ...props,
  })
}

const pasteNotification = ({ key, onClose, theme }: ErrorPayload) => {
  notification.open({
    duration: 0,
    key,

    message: <PasteNotification theme={theme} />,

    onClose,
    //@ts-ignore
    placement: 'bottom',
    style: {
      background: 'var(--color-bg-2)',
      borderRadius: '5px',
      boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 20px rgba(0, 0, 0, 0.4), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.05)',
      display: 'flex',
      padding: '16px',
      width: '200px',
    },
  })
}
type WorkspaceNotificationProps = {
  key: string
  workspaces?: IWorkspace[]
  dispatch: Dispatch<any>
  callback: () => void
}

const selectWorkspaceNotification = ({ callback, dispatch, key, workspaces }: WorkspaceNotificationProps) => {
  notification.open({
    duration: 0,
    key,
    message: <ViewerWorkspaceSelectContainer workspaces={workspaces} dispatch={dispatch} callback={callback} />,
    placement: 'bottom',
    style: {
      background: 'var(--color-bg-2)',
      borderRadius: '5px',
      boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 20px rgba(0, 0, 0, 0.4),
      inset 0px 0px 0px 1px rgba(255, 255, 255, 0.05)`,
      padding: '8px',
      width: '338px',
    },
  })
}
type redirectToWorkspaceProps = {
  key: string
  workspaceName?: string
}

const redirectToWorkspace = (payload: redirectToWorkspaceProps) => {
  const { key, ...rest } = payload
  notification.open({
    key: key,
    message: <RedirectToWorkspaceNotification {...rest} />,
  })
}

type dictributionCasesProps = {
  doctorName: string
  selectedCasesCount: number
}

const dictributionCases = ({ doctorName, selectedCasesCount }: dictributionCasesProps) => {
  notification.open({
    message: <DistributionNotification doctorName={doctorName} selectedCasesCount={selectedCasesCount} />,
  })
}

export const notices = {
  close: notification.close,
  dictributionCases,
  error,
  info,
  openOnBarCodeCopyNotification,
  openOnCaseRemoveNotification,
  openOnLinkingSlidesNotification,
  openOnOtherPatientCaseNotification,
  openOnSlideDeleteNotification,
  openOnSlideDuplicateNotification,
  openOnSumpSlideRemoveNotification,
  pasteNotification,
  redirectToWorkspace,
  selectWorkspaceNotification,
}
