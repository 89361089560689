import FileUploadContainer from 'features/new-preview-panel/panel-preview-components/FileUploadContainer'
import PanelPreviewContainer from 'features/new-preview-panel/panel-preview-components/PanelPreviewContainer'
import WarningPreview from 'features/new-preview-panel/WarningPreview'
import { useSlidesList } from 'features/thumbnails/hooks'
import React, { memo } from 'react'

const ImagesTabContent = memo(({ caseId, declinementComment }: { declinementComment?: string; caseId: number }) => {
  const { blockDictionary, filesList, macroSlidesIds, onlyEmptyBlocks, processingSlides } = useSlidesList(caseId)

  const allBlocks = Object.values(blockDictionary).flat()
  const combinedBlocks = [...allBlocks, ...onlyEmptyBlocks, ...macroSlidesIds].flat()

  return (
    <div>
      {declinementComment && <WarningPreview title={declinementComment} />}
      <div style={{ display: 'flex', flexWrap: 'wrap', margin: `0 16px` }}>
        {(!!filesList.length || !!processingSlides.length) && (
          <FileUploadContainer filesList={filesList} processingSlides={processingSlides} />
        )}
        <PanelPreviewContainer blocks={combinedBlocks} caseId={caseId} />
      </div>
    </div>
  )
})

export default ImagesTabContent
