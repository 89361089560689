import { notification } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { useTaskQuery } from 'entities/tasks/api/query'
import AnnotationsTypePanelContainer, {
  RulerAnnotationButton,
} from 'features/annotations/ui/AnnotationsTypePanelContainer'
import { useCaseQuery } from 'features/cases/api/query'
import { useUserStatusContext } from 'features/multiplayer/lib'
import { ActiveUsersContainer } from 'features/multiplayer/ui'
import { OpenNotificationsButton } from 'features/notifications'
import { SharePopover } from 'features/share'
import { SimplifyButton } from 'features/simplifcation'
import { SearchCase } from 'pages/cases/list/SearchCase'
import { selectUrlCaseId, selectUrlSlideId, viewerPageSlice } from 'pages/viewer'
import {
  LocationState,
  useOpenViewers,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import {
  selectTasksViewerUrlTaskId,
  SideRightPanelType,
  ViewerLeftPanelType,
  ViewerRightPanelType,
} from 'pages/viewer/model/viewerPageSlice'
import React, { useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { QUERY_TYPE } from 'shared/api'
import { formatDateForDisplay } from 'shared/lib/date'
import { isGuestHandler } from 'shared/lib/local-storage'
import { getBooleanOrDefaultAccess } from 'shared/lib/workspaces'
import {
  ButtonElement,
  DropdownElement,
  IconElement,
  IIconElementProps,
  SpaceElement,
  TextElement,
  TitleElement,
  TooltipElement,
} from 'shared/ui/kit'
import { LanguageSelect } from 'shared/ui/kit/ui/LanguageSelect'
import { MainToolbarButton } from 'shared/ui/kit/ui/ToolbarButton'
import { VerticalSeparator } from 'shared/ui/kit/ui/VerticalSeparator'
import styled from 'styled-components/macro'
import ICase, { ICaseRelation } from 'types/ICase'
import ISlide from 'types/ISlide'
import IUserRole from 'types/IUserRole'
import { SettingsNameEnum } from 'types/IWorkspaces'
import { useViewerDispatch } from 'viewer/container'
import { viewerSlice } from 'viewer/container/model/viewerSlice'

import CoregistrationPanelContainer from './CoregistrationPanelContainer'
import { FastMoveContainer } from './FastMoveContainer'
import MarkupToolbarContainer from './MarkupToolbarContainer'
import { SetupMenu } from './SetupMenu'
import { ToolsPanelContainer } from './ToolsPanelContainer'

const MENU = 'MENU'

/**
 * Описание пропса
 * @typedef {Object} Props
 * @property {string} [className] - Описание класса компонента
 */

type Props = {
  className?: string
}

const ToolbarWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 8px;
  animation: ani 1s linear forwards;
`
const LeftItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 1px;
`
const PatientInfo = styled.div`
  display: flex;
  gap: 8px;

  .ant-typography {
    line-height: 14px;
  }
`
const PatientName = styled(TextElement)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
const CenterItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: ani 1s linear forwards;
`
const RightItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const CaseInfo = styled.div`
  overflow: hidden;
  flex-shrink: 2;
`

const CaseName = styled(TitleElement)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const ShareButton = styled(ButtonElement)`
  font-size: 13px;
  font-weight: 600;
  border-radius: 5px;
  height: 32px;
  padding: 4px 16px;
`

const TopToolbarContainer = ({ className }: Props) => {
  const queryClient = useQueryClient()
  const { activeViewerId: viewerId, openViewerIds } = useOpenViewers()
  const dispatch = useDispatch()
  const viewerDispatch = useViewerDispatch(viewerId)
  const urlCaseId = useSelector(selectUrlCaseId)
  const urlSlideId = useSelector(selectUrlSlideId)
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const { viewerMode } = useViewerIdSlideState(viewerId)
  const { data: urlCase } = useCaseQuery({ caseId: urlCaseId, source: 'PLATFORM' })
  const { data: task } = useTaskQuery(taskId)
  const authorities = useTypedSelector((state) => state.user.authorities)
  const isTaskAvailable = authorities?.includes(IUserRole.ROLE_MARKUP_USER)
  const isTestUser = authorities?.includes(IUserRole.ROLE_TEST_USER)
  const isGuest = isGuestHandler(authorities)
  const isRestricted = urlCase?.relation === ICaseRelation.RESTRICTED
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const isVisibleSearchMorphology = useTypedSelector((state) => state.viewerPage.tools.SEARCH_MORPHOLOGY.isVisible)
  const slide = queryClient.getQueryData<ISlide>([QUERY_TYPE.SLIDE, urlSlideId])
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, urlCaseId])
  const [activeSearch, setActiveSearch] = useState<boolean>(false)
  const [isOpen, setOpen] = useState<boolean>(false)
  const leftPanel = useTypedSelector((state) => state.viewerPage.leftPanelType)
  const rightPanel = useTypedSelector((state) => state.viewerPage.rightPanelType)
  const { slideGroupType } = useViewerIdSlideState(viewerId)
  const isMacro = (slide?.groupType || slideGroupType) === 'MACRO'
  const MITOSIS = useTypedSelector((state) => state.viewerPage.tools.MITOSIS)
  const countingObjectType = useTypedSelector((state) => state.viewerPage.countingObjectType)
  const { isFastTravel } = useViewerPageProvided()
  const { changeViewerSlide } = useViewerPageProvided()
  const initialBbox = useTypedSelector((state) => state.search.mainSelectedBbox)
  const isSearch = leftPanel === 'SEARCH'
  const isMitosisCountingModeOn = MITOSIS.isVisible && countingObjectType === 'MITOSIS'
  const isObjectsCountingModeOn = MITOSIS.isVisible && countingObjectType === 'OBJECTS'
  const isDisabled = task?.status === 'PAUSED' || caseRecord?.permissionLevel === 'READ_ONLY'
  const isDisabledByMitosis =
    task?.status === 'PAUSED' || caseRecord?.permissionLevel === 'READ_ONLY' || isMitosisCountingModeOn
  const { targetUserId, unsubscribeFromUser } = useUserStatusContext()
  const location = useLocation<LocationState>()
  const toggleOpen = () => setOpen(!isOpen)
  const menuButtonRef = useRef(null)
  const menu = <SetupMenu toggleOpen={toggleOpen} menuButtonRef={menuButtonRef} />
  /** Флаг, указывающий на таск-вьювер. */
  const isTaskViewer = !!useTypedSelector(selectTasksViewerUrlTaskId)

  const handleOpenObjectsCounts = () => {
    unsubscribeFromUser()
    dispatch(viewerSlice.actions.selectObjectId())
    dispatch(viewerPageSlice.actions.openObjectsCounting())
    dispatch(viewerPageSlice.actions.setCountingObjectType('OBJECTS'))
  }

  const handleMitosisHotkey = () => {
    unsubscribeFromUser()
    if (MITOSIS.isVisible) {
      dispatch(viewerPageSlice.actions.toggleTool('MITOSIS'))
    } else {
      handleOpenObjectsCounts()
    }
  }

  const handleBackNavigate = () => {
    if (isVisibleSearchMorphology) {
      viewerDispatch(viewerPageSlice.actions.toggleTool('SEARCH_MORPHOLOGY'))
    }
    localStorage.removeItem('ORIGIN_CASE_PATH')
    notification.close('otherCase')

    if (isTaskViewer) {
      dispatch(push(`/tasks/${taskId}`, { from: undefined }))
      return
    }
    const previousUrl = location?.state?.from
    if (previousUrl) {
      // 1 сценарий, когда перешли во вьювер из панели превью списка кейсов
      dispatch(push(previousUrl, { from: undefined }))
    } else {
      // 2 сценарий, когда перешли во вьювер из рандомного источника
      dispatch(push('/'))
    }
  }

  useHotkeys(
    'B',
    () => {
      !taskId && handleMitosisHotkey()
    },
    [MITOSIS.isVisible, targetUserId],
  )

  if (!urlCase && !task) {
    return <div className={className} />
  }

  type LeftPanelButtonProps = {
    /** название инструмента */
    title: string
    /** тип панели */
    type: ViewerLeftPanelType | ViewerRightPanelType
    /** назавние иконки */
    iconName: IIconElementProps['name']
    /** горячая клавиша */
    hotkey?: string
    /** сторона панели для инструментов */
    side?: string
    /** флаг, определяющий доступ к кнопке */
    disable?: boolean
    /** флаг, определяющий открыта ли меню */
    isOpenMenu?: boolean
  }

  const SidePanelButton = ({ disable, hotkey, iconName, isOpenMenu, side, title, type }: LeftPanelButtonProps) => {
    const {
      caseId = 0,
      slideId = 0,
      source = 'PLATFORM',
    } = useTypedSelector((state) => state.search.mainSelectedSimilarRegion) || {}
    const isButtonActive = (isOpen && type === MENU) || leftPanel === type || rightPanel === type
    const tooltipTitle =
      disable && type === MENU ? '' : disable ? t('Инструмент недоступен') : !hotkey ? title : `${title} (${hotkey})`
    const sidePanelHandler = (type: ViewerLeftPanelType | ViewerRightPanelType) => {
      const typeL = type as ViewerLeftPanelType
      const typeR = type as ViewerRightPanelType
      side === 'left' && dispatch(viewerPageSlice.actions.toggleViewerLeftPanel(typeL))
      side === 'right' && dispatch(viewerPageSlice.actions.toggleViewerRightPanel(typeR))
      if (typeL === 'SLIDES') {
        if (caseId && slideId) {
          viewerDispatch(viewerSlice.actions.setSelectedBbox(initialBbox))
          urlSlideId !== slideId &&
            changeViewerSlide(viewerId, {
              caseId,
              slideGroupType: 'MICRO',
              slideId,
              source,
              viewerMode: 'DEFAULT',
            })
        }
        dispatch(viewerPageSlice.actions.closeTool('SEARCH_MORPHOLOGY'))
      }
      if (type === MENU) {
        toggleOpen()
      }
    }

    useHotkeys(
      hotkey || '',
      () => {
        !disable && !isFastTravel && sidePanelHandler(type)
      },
      { enabled: !!hotkey },
    )
    return (
      <TooltipElement placement={isOpenMenu ? 'rightBottom' : 'bottom'} title={tooltipTitle}>
        <MainToolbarButton
          data-testid={`${iconName.toLowerCase()}-btn`}
          active={isButtonActive}
          onClick={() => !disable && sidePanelHandler(type)}
          icon={<IconElement size={type === MENU ? 'md' : undefined} name={iconName} />}
          typeButton={type}
          disable={disable}
          disabled={disable}
        />
      </TooltipElement>
    )
  }
  const { t } = useTranslation()
  return (
    <ToolbarWrapper className={className}>
      <LeftItem>
        <div style={{ display: 'flex' }}>
          <MainToolbarButton
            data-testid={'arrow-back-btn'}
            icon={<IconElement name="arrowBack" />}
            onClick={handleBackNavigate}
          />
        </div>
        <VerticalSeparator style={{ flexShrink: 0, marginRight: 6 }} />
        <SidePanelButton title={t('Слайды')} type={'SLIDES'} iconName={'imageList'} hotkey={'C'} side={'left'} />
        <DropdownElement placement={'bottom'} visible={isOpen} overlay={menu} trigger={['click']}>
          <div ref={menuButtonRef}>
            <SidePanelButton
              isOpenMenu={isOpen}
              title={t('Настройки отображения превью')}
              type={MENU}
              iconName={'dropDown'}
              side={'menu'}
              disable={isSearch}
            />
          </div>
        </DropdownElement>
        {getBooleanOrDefaultAccess(SettingsNameEnum.EnableAiSearch, true, currentWorkspace) && (
          <SidePanelButton
            title={t('Поиск по морфологии')}
            data-testid={'search-similar-btn'}
            type={'SEARCH'}
            iconName={'searchAtlas'}
            hotkey={'F'}
            side={'left'}
            disable={isMacro}
          />
        )}
        <VerticalSeparator style={{ flexShrink: 0, marginLeft: 6, marginRight: 12 }} />
        {!activeSearch && (
          <CaseInfo>
            <CaseName data-testid={'default-viewer-casename'} level={3}>
              {urlCase?.name}
            </CaseName>
            <PatientInfo>
              {urlCase?.patient ? (
                <PatientName>{urlCase?.patient.fullname || t('Имя пациента скрыто')}</PatientName>
              ) : (
                <PatientName>{t('Имя пациента не указано')}</PatientName>
              )}
              {urlCase?.patient && (
                <TextElement type="secondary">{formatDateForDisplay(urlCase?.patient.birthDate)}</TextElement>
              )}
            </PatientInfo>
          </CaseInfo>
        )}
      </LeftItem>

      <CenterItems>
        <FastMoveContainer />
        <VerticalSeparator />
        <RulerAnnotationButton disabled={isDisabled || isMacro || MITOSIS.isVisible} />
        <VerticalSeparator />
        <AnnotationsTypePanelContainer disabled={isDisabled || MITOSIS.isVisible} />
        {!taskId && (
          <TooltipElement title={isDisabledByMitosis ? t('Инструмент недоступен') : t('Ручной подсчет клеток (B)')}>
            <MainToolbarButton
              active={isObjectsCountingModeOn}
              onClick={handleOpenObjectsCounts}
              icon={<IconElement name={'objects'} />}
              style={{ margin: 0, marginRight: 12 }}
              disable={isDisabledByMitosis}
              disabled={isDisabledByMitosis}
            />
          </TooltipElement>
        )}
        {taskId && <SimplifyButton />}
        <VerticalSeparator />
        <ToolsPanelContainer disable={isMacro} />
        {openViewerIds.length > 1 &&
          getBooleanOrDefaultAccess(SettingsNameEnum.EnableAiSlideCoreg, true, currentWorkspace) && (
            <>
              <VerticalSeparator />
              <CoregistrationPanelContainer />
            </>
          )}
      </CenterItems>

      <RightItems>
        {isTestUser && <LanguageSelect />}
        <SpaceElement size={12} />
        {urlCaseId && <ActiveUsersContainer />}
        <SpaceElement size={12} />
        {!taskId && !isGuest && !isRestricted && (
          <SharePopover caseId={urlCase?.caseId || 0}>
            <ShareButton disabled={isDisabled} type="primary" style={{ marginRight: 6 }}>
              {t('Поделиться')}
            </ShareButton>
          </SharePopover>
        )}
        {isTaskAvailable && taskId && <MarkupToolbarContainer />}
        <SidePanelButton
          title={t('О Случае')}
          type={SideRightPanelType.CASE}
          iconName={'Case'}
          hotkey={'I'}
          side={'right'}
        />
        <SidePanelButton
          title={t('Аннотации')}
          type={SideRightPanelType.ANNOTATIONS}
          iconName={'annotationsList'}
          hotkey={'A'}
          side={'right'}
        />
        <SidePanelButton
          title={t('Комментарии')}
          type={SideRightPanelType.COMMENTS}
          iconName={'feedBack'}
          hotkey={'K'}
          side={'right'}
          disable={!!taskId || viewerMode === 'SEARCH'}
        />
        {activeSearch && <SearchCase setActive={setActiveSearch} active={activeSearch} />}
        <VerticalSeparator style={{ margin: `0 6px` }} />
        <MainToolbarButton
          active={activeSearch}
          onClick={() => {
            setActiveSearch(!activeSearch)
          }}
          icon={<IconElement name="searchCase" />}
        />
        <TooltipElement title={t('Уведомления')} placement="bottom">
          <div style={{ height: 24, margin: '0 4px' }}>
            <OpenNotificationsButton />
          </div>
        </TooltipElement>
      </RightItems>
    </ToolbarWrapper>
  )
}

export default TopToolbarContainer
