import { useTranslation } from 'react-i18next'
import { declensionWord } from 'shared/lib/text'
import styled from 'styled-components'

const TextWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`

const Wrapper = styled.div`
  padding: 6px;
`
export type OnLinkingSlidesNotificationProps = {
  count: number
  caseName?: string
}

const OnLinkingSlidesNotification = ({ caseName, count }: OnLinkingSlidesNotificationProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <TextWrapper>
        {count} {declensionWord(count, [t('изображение'), t('изображения'), t('изображений')])}{' '}
        {count === 1 ? t('привязано') : t('привязаны')} <br /> {t('к случаю')} {caseName}
      </TextWrapper>
    </Wrapper>
  )
}

export default OnLinkingSlidesNotification
