import { TCaseManagementFilterParams } from 'features/cases-management/types/TCasePagination'
import React, { createContext, FC, ReactNode, useContext, useState } from 'react'

/** Количество элементов в странице */
const DEFAULT_PAGE_SIZE = 20
type TCaseManagementContext = {
  /** Фильтры */
  filterParams: TCaseManagementFilterParams
  setFilterParams: React.Dispatch<React.SetStateAction<TCaseManagementFilterParams>>
  filterHeight: number
  setFilterHeight: React.Dispatch<React.SetStateAction<number>>
  /** Обновить высоту панели превью */
  setPanelPreviewHeight: React.Dispatch<React.SetStateAction<number>>
  /** Обновить видимость панели превью */
  setPanelPreviewVisible: React.Dispatch<React.SetStateAction<boolean>>
  /** Высота панели превью кейса */
  panelPreviewHeight: number
  /** Видимость панели превью кейса */
  isPanelPreviewVisible: boolean
  /** Видимое количество строк */
  visibleRowsCount: number
  /** Обновить видимое количество строк */
  setVisibleRowsCount: React.Dispatch<React.SetStateAction<number>>
}

const initialCaseManagementContextState: TCaseManagementContext = {
  filterHeight: 0,
  filterParams: {},
  isPanelPreviewVisible: false,
  panelPreviewHeight: 250,
  setFilterHeight: () => {},
  setFilterParams: () => {},
  setPanelPreviewHeight: () => {},
  setPanelPreviewVisible: () => {},
  setVisibleRowsCount: () => {},
  visibleRowsCount: 20,
}

const CaseManagementContext = createContext<TCaseManagementContext>(initialCaseManagementContextState)

export const useCaseManagementContext = () => useContext(CaseManagementContext)

const CaseManagementProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [filterParams, setFilterParams] = useState<TCaseManagementFilterParams>(
    initialCaseManagementContextState.filterParams,
  )
  const [filterHeight, setFilterHeight] = useState<number>(initialCaseManagementContextState.filterHeight)
  const [panelPreviewHeight, setPanelPreviewHeight] = useState<number>(
    initialCaseManagementContextState.panelPreviewHeight,
  )
  const [isPanelPreviewVisible, setPanelPreviewVisible] = useState<boolean>(
    initialCaseManagementContextState.isPanelPreviewVisible,
  )
  const [visibleRowsCount, setVisibleRowsCount] = useState(DEFAULT_PAGE_SIZE)

  return (
    <CaseManagementContext.Provider
      value={{
        filterHeight,
        filterParams,
        isPanelPreviewVisible,
        panelPreviewHeight,
        setFilterHeight,
        setFilterParams,
        setPanelPreviewHeight,
        setPanelPreviewVisible,
        setVisibleRowsCount,
        visibleRowsCount,
      }}
    >
      {children}
    </CaseManagementContext.Provider>
  )
}

export default CaseManagementProvider
