import { createGlobalStyle, css } from 'styled-components/macro'

import IBMPlexSansBold from './fonts/IBMPlexSans-Bold.ttf'
import IBMPlexSansMedium from './fonts/IBMPlexSans-Medium.ttf'
import IBMPlexSansRegular from './fonts/IBMPlexSans-Regular.ttf'
import IBMPlexSansSemiBold from './fonts/IBMPlexSans-SemiBold.ttf'

type TMode = 'light' | 'dark'

const common = css`
  --color-purple: #4e63d2;
  --color-blue: #4099f7;
  --color-red: #dc524c;
  --color-green: #7bb86f;
  --color-yellow: #f8cd76;
  --color-emerald: #52a899;
  --color-black: #000000;
  --color-white: #ffffff;
`

const dark = css`
  --color-bg-1: #000000;
  --color-bg-2: #1c1c1e;
  --color-bg-3: rgba(255, 255, 255, 0.09);
  --color-bg-3-full: #2c2b30;
  --color-bg-4: #706f76;

  --color-text-1: #ffffff;
  --color-text-2: #eeeeee;
  --color-text-3: #99989f;

  --color-purple-light: #7285e9;

  --color-border-1: rgba(255, 255, 255, 0.16);

  --color-modal: rgba(28, 28, 30, 0.85);
  --color-modal-outline: rgba(255, 255, 255, 0.1);
`

const light = css`
  --color-bg-1: #f3f4f6;
  --color-bg-2: #ffffff;
  --color-bg-3: rgba(0, 0, 0, 0.07);
  --color-bg-3-full: #f0ebed;
  --color-bg-4: #bcb9c1;

  --color-text-1: #000000;
  --color-text-2: #333333;
  --color-text-3: #747a87;

  --color-purple-light: #4e63d2;

  --color-border-1: rgba(0, 0, 0, 0.1);

  --color-modal: rgba(255, 255, 255, 0.9);
  --color-modal-outline: rgba(0, 0, 0, 0.1);
`

export const modalBackground = css<{
  opacity?: number
}>`
  background: var(--color-modal);
  backdrop-filter: ${({ opacity }) => (opacity ? undefined : `blur(30px) saturate(4)`)};
`
export const modalShadow = css`
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%), 0 0 0 1px var(--color-modal-outline);
`

const scrollbars = css`
  /* firefox */

  scrollbar-width: thin;
  scrollbar-color: var(--color-bg-4) var(--color-bg-2);

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: var(--color-bg-3);
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: var(--color-bg-3);
    border-radius: 5px;
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(112, 111, 118, 0.7);
  }

  ::-webkit-resizer {
    //background: transparent;
  }
`

const fonts = css`
  @font-face {
    font-family: 'IBMPlexSans';
    src: local('IBMPlexSansRegular'), url(${IBMPlexSansRegular}) format('truetype');
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'IBMPlexSans';
    src: local('IBMPlexSansMedium'), url(${IBMPlexSansMedium}) format('truetype');
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: 'IBMPlexSans';
    src: local('IBMPlexSansSemiBold'), url(${IBMPlexSansSemiBold}) format('truetype');
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: 'IBMPlexSans';
    src: local('IBMPlexSansBold'), url(${IBMPlexSansBold}) format('truetype');
    font-style: normal;
    font-weight: 700;
  }
`

const cursorStyle = css`
  .cursor-delete {
    cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASCSURBVHgB7VdtTJtVFD5vP2iFwqCALVEZyvwA5mAJiT9mDPNjczqJcYPEkRiTRZ380D+KiS4DF7+ikMBiUl0yNXXNQpxgjGaAcwEGLpI5thkiDsqYwqAbqWUW+t3rc+jbygilpaD+cE/y5N7ee973Pvecc899SxSCJPM/gRLcDx4AH66oqFD+22KSwQajwSjy71gn0L8EmlkMqAIV/6Qg3q0GzISAJz82HaKtW7atMRpyir1e7zM22+TLmCsEM8Ax0E2rDCkvL087Ojq6Fv3TFnOzzmAwRiYhgM6dP0u9vSep91QPD3WDn4Od4AgoZCYMZUlJCUGAGv18eKGooKAoMqnT6Sg/fx1tLnuIdjxVQQX3FK3VJCWVQ9hLXp/3QZhkpqamXoC3XJQgwtmfDu4s3lBysOGDppgPTcIze17cTc4ZJ4dlmxBiQJKkhDzBScYPcmz74W4nXqrTpegWNcYcmc2fUstXR2colKgmcByLS5RgKJRyyw9ztq/XZ+jvmh+GMFpav6B9dXvp/M9nv1Yqlfuw6+MYvgpeA/2UIMICJLl/C+K5GSfhOqMXqndTx3dtA8Fg4FUs3KLRaEb8fv8kpqZBH60ACrkNGgwGj1qt7hi5aOUwRAzaO46R1Tp8At3qQCBwCu2w2+2+jJaNArW1tZJcvMJMuGYkgbeDZ/bXvi2Ot3eJw+ZmgWMp4PKdWq02F3PJ8mKK9vb2m10u11vwyKiQAYHn4EGz1Wq9c9lCsBP2RjbYsPWRR+cEbEGL38dAfmGybKNyOBzPe+0Ox8g7TeLMY7vEibT8OfZtekJcPnxUBINBx+zs7Cv0d4jjBqf/Jpz/ud2jz3HeDmbOW/w116XfxQ/rH4gsvJA8xzY2m21PLBGKBb+9SLAJp9PZX/vmG/z7E3CAQvGmnp6eu1F4Xu9/vIrcv41HfSnPsU2GOuk9k8nEpVWKV0DA4/HwYn1IvAkkZTP6duzeZ7FY1MXFxXttR1rTllp8vojxj8xrKisr+T5Rxy0gOzt7Fu1BcAcEXCkrK5upq6sTw8PDWvzeMGb6jOLFHz0/Ejz2dFZWloaWAZVer09LB9DXymPKlJQUA2d6OM4+xzURDT7HdMSOgecNi2w2tNgiY3673c5h4LgF5DEJmb2YbbxQU5Q8iPbS4MIBnHlCAZpIv/++HAdcezJ3I8WC7t5CQkJfoCXuCQXFB36Bb2ho6BsIoHhxW/WzND09/SuF7gqxEgFB5IW7pqbGnPNclVObe2vMB9gmq7LcWV9fzzeme6UChNFodLe1tY11/XT6wMZvLbSUCJ5jm76+vkONjY2/LCVgOVDgOKWiLejq6voQNd85YfkS5Xd7JOO5LF98t0nMXrnqbG1tfR+2fK+n0Sp+1HJZ5eNZWFVVtWtwcPD7qampyGWEhLN1d3cfKS0tLafQx6xevryiYtnKUJhUnZ2dybgdM3EqMjGUolKpNPg+YBd7wT8xZ8ecHX0uav5VFSCDc4fP9k0oUBquEXxMKfRx4qFQzFlMzLivNDYSXf+3TtAqfKrfwP8LfwHUGig4tkONYAAAAABJRU5ErkJggg==')
        1 1,
      auto;
  }

  .cursor-add {
    cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAS6SURBVHgB7VdtTJtVFD5vP2gHhbEClk0dKNONsqzEELeFLGF+AEvMom7gBwk/XFScif5xmOgcyGZiJhgxmmYkc1knMaCCMSYDnIbPoGZx7AOCg2JBBhRJabGUtrS9Ppe+VEpASkH94Z7kyf0457733HvOPfe+RH4IIv8TSMEy8EPwkby8POm/bUwkWJGoSWQp925jqA+CBm4MKAMl/6RBfLUKMA4GPH5Gf5Zysg9sTNRs1rnd7kKzeexVyLTgJnAYdNI6Q0hOTlaaTKYk1C9XG2pUGk1iQAgD6Oq1LuroaKOOznbe1QpeAJvBAZCJDBvS9PR0ggFy1FOwC2mpqWkBoUqlopSUbbQ/62E69GQepe5IS1JERByEYa+4Z90PQSUuOjr6JnZrhsLEfPTHgod1u9KrKt6rXHHQGHam6KUjZJ+2c7ccYIx1C4IQ1k7wIOMDuW+vYLvt+KhKFaVaUhkyMhjOUd1XX0yTP1D14C1MLlCYrpCKJR/Mo32nepP6/oVumEdd/ed0ovQ4Xbve9bVUKj2BVV9C9+/gFOihMDFvgCDW74Q/9+MkBCm9ePQINX3b0O3zeY9h4jqFQjHg8XjGILKBs7QGSMTSp9FoXHK5vGngVyN3Q0ChsekiGY3936N61Ov1dqLsdzqdIyi5krekpEQQk9c8w84ZEeA94M9lJe+wS40t7FNDDcOxZNjyw0qlcitkkeJkksbGxjtmZmZOYUdMTAQMvIodNBiNxvtWbQhWwncjAazIeTR3zoBslGhfBPkHI0UdmdVqfWHSYbFWNJ9keeez2d1lG+aYU7Wb1XYZmM/nszocjtfoLxeHDB7+mTj/c6tHnfv5MTBuweSvD02a2N7K7YGJF5PLfoOO2WwuWskIyaK2GwE2arfbr5S8/SZvfwJ2k9/f1N7evh2J542nDDk0bBsKDLpRPEI3jo0E2lyWD50IlexdvV7PU6sQqgFel8vFJ/sJgTeKoKxB3YLVz1ZXV8t1Ot3xL69XxyycnCNGEUsxytigPq5z7rJ+Y35+Pr9P5BSqAQkJCQ6UVeAhGDCelZU1XVpayvr7+5Vo7zr748cUKjpNbYQdeyY+Pl5Bq4BMrVbHxAKoK8U+aVRUlIZH+ryfbc5JthxsM5MBPQ6M1yyxWP9kS/R5LBYLdwP3m1fsExDZS+mGCjktEwfLfdS3uANnnpCARvck7dv8w2Ab7Ty9JSAbessxV249GRk0Ji1RRwjom/Q394SEQgP/wGxfX983e5P2Uah47sGXyWaz/UL+u4KtxQAf4sJZXFxsKHygyH5XbFKQcMplpSmnNaiP6zyR9rS9vLyc35jO5QwIOV1qtdqInp6eLQ0NDc9r9+xALsilYevgkrp88trCBhrqvlWZmZn5AbpGQRetERIcp2iUqS0tLR8h59truy6w3DO7AxGffz6Hvd9yio3bxu319fWnocvv9Rhax0ctT6v8eGoLCgqe7e3t/W5iYiJwGSHgzK2trZ9lZGQcJP9jVi1eXsti1ZYhMcmam5sjcTvG4VTEoStKJpMp8D7gPnaDf0BmgcyCOj8ennU1QAQPXn62NyBBKXiO4MeU/I8T7munaMyKz7S1+kag4N86RuvwVL+N/xf+BGXkLraKRXbCAAAAAElFTkSuQmCC')
        1 1,
      auto;
  }
`

const GlobalStyles = createGlobalStyle<{ mode: TMode }>`
  ${scrollbars}
  ${fonts}
  ${cursorStyle}
  
  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: 'IBMPlexSans', sans-serif; 
    background-color: var(--color-bg-1);

    ${common}
    ${(props) => (props.mode === 'dark' ? dark : light)}

  }

  #root {
    height: 100%;
    overflow-y: hidden;
  }
  
  .ant-dropdown-menu-submenu-popup ul{
    background-color: transparent;
  }

  .ant-dropdown-menu-item:hover {
    color: var(--color-text-1)
  }
`

export default GlobalStyles
