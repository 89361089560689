import * as Sentry from '@sentry/react'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useMemo } from 'react'
import { QueryCache, QueryClient, QueryClientProvider, setLogger } from 'react-query'

// Sentry logger
setLogger({
  error: (error) => {
    Sentry.captureException(error)
  },
  log: (message) => {
    Sentry.captureMessage(message)
  },
  warn: (message) => {
    Sentry.captureMessage(message)
  },
})

const CustomQueryClientProvider: React.FC = ({ children }) => {
  const token = useTypedSelector((state) => state.user.token)
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            // mutation options
          },
          queries: {
            cacheTime: Infinity,
            refetchOnWindowFocus: false,
            retry: 0,
          },
        },
        queryCache: new QueryCache(),
      }),
    [token],
  )

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/*<ReactQueryDevtools initialIsOpen={false} />*/}
    </QueryClientProvider>
  )
}

export default CustomQueryClientProvider
