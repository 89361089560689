import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { customHistory } from 'app/providers/RouterProvider'
import { sentryReduxEnhancer } from 'app/sentry'
import { routerMiddleware } from 'connected-react-router'
import {
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { migrations } from './migrations'
import rootReducer from './rootReducer'

const persistConfig = {
  blacklist: ['viewer', 'viewerPage', 'viewerCoregistration'],
  key: 'store',
  migrate: createMigrate(migrations, { debug: true }),
  storage,
  version: 20,
  whitelist: ['slideMapView'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer(customHistory))

export const store = configureStore({
  enhancers: [sentryReduxEnhancer],
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    routerMiddleware(customHistory),
  ],
  reducer: persistedReducer,
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
