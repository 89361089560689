import { CaseReference } from 'features/cases/api/service'
import i18next from 'shared/lib/i18n/i18n'
import ICase from 'types/ICase'

const t = i18next.t

export const isAllSlides = (caseResult?: ICase, caseReferencesSlides?: CaseReference[]) => {
  if (!caseResult || !caseReferencesSlides) {
    return true
  }

  const numSlidesInCase = caseResult.slides.filter((slide) => slide.groupType === 'MICRO').length

  return numSlidesInCase >= caseReferencesSlides.length
}

export const getSlideSummary = (caseResult?: ICase, caseReferencesSlides?: CaseReference[]) => {
  const sumSlideInCase = caseResult?.slides.filter((slide) => slide.groupType === 'MICRO').length
  const sumReferenceSlides = caseReferencesSlides?.length

  return sumReferenceSlides ? `${sumSlideInCase} ${t('из')} ${sumReferenceSlides}` : sumSlideInCase
}
