import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PDFPageProxy } from 'react-pdf'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { ButtonElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const StyledPage = styled(Page)`
  display: flex;
  justify-content: center;

  /* For pdf */
  .react-pdf__Page {
    margin-top: 10px;
  }
  .react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
  }

  .react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
`

const PdfControls = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 8px;
  right: 24px;
  bottom: 24px;
`
const StyledButton = styled(ButtonElement)`
  &.ant-btn-circle.ant-btn-lg {
    ${() => modalBackground}
    ${() => modalShadow}
    color: var(--color-text-1)
  }
`

type Props = {
  file: string
}

export const PdfViewer = ({ file }: Props) => {
  const [pdfWidth, setPdfWidth] = useState<number>()
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [page, setPage] = useState<PDFPageProxy>()
  const containerRef = useRef<HTMLDivElement>(null)
  const [fitTo, setFitTo] = useState<'page' | 'width'>('page')
  const [globalScale, setGlobalScale] = useState(1)
  const { t } = useTranslation()

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset: any) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  const scale = useMemo(() => {
    if (!page || !containerRef.current) {
      return globalScale
    }

    const pageScale = 1
    const rotated = page._pageInfo.rotate === 90 || page._pageInfo.rotate === 270
    const pageWidth = rotated ? page.originalHeight : page.originalWidth
    const pageHeight = rotated ? page.originalWidth : page.originalHeight
    const scaleX = (window.innerWidth - 40) / pageWidth
    const scaleY = (window.innerHeight - 110) / pageHeight
    if (fitTo === 'page') {
      return globalScale * Math.min(scaleX, scaleY)
    }
    if (fitTo === 'width') {
      return globalScale * scaleX
    }
    return globalScale * pageScale
  }, [page, globalScale, fitTo])

  const onZoomToFit = () => {
    setFitTo(fitTo === 'page' ? 'width' : 'page')
    setGlobalScale(1)
  }

  return (
    <div ref={containerRef}>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <StyledPage
          pageNumber={pageNumber}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          width={pdfWidth}
          scale={scale}
          onLoadSuccess={(p) => setPage(p)}
        />
      </Document>
      <div style={{ display: 'flex', gap: '8px', justifyContent: 'center', padding: '16px 0' }}>
        <p style={{ margin: 0 }}>
          {t('Страница')} {pageNumber || (numPages ? 1 : '--')} {t('из')} {numPages || '--'}
        </p>
        <StyledButton type="primary" disabled={pageNumber <= 1} onClick={previousPage}>
          {'<'}
        </StyledButton>
        <StyledButton type="primary" disabled={pageNumber >= (numPages || 0)} onClick={nextPage}>
          {'>'}
        </StyledButton>
      </div>
      <PdfControls>
        <StyledButton className="zoom-to-fit" onClick={onZoomToFit} type="primary" shape="circle" size="large">
          <span aria-hidden>&#9713;</span>
        </StyledButton>
        <StyledButton
          className="zoom-in"
          title="Zoom In"
          type="primary"
          shape="circle"
          size="large"
          onClick={() => setGlobalScale((scale) => scale * 1.1)}
        >
          <span aria-hidden>+</span>
        </StyledButton>
        <StyledButton
          className="zoom-out"
          title="Zoom Out"
          type="primary"
          shape="circle"
          size="large"
          onClick={() => setGlobalScale((scale) => scale * 0.9)}
        >
          <span aria-hidden>-</span>
        </StyledButton>
      </PdfControls>
    </div>
  )
}
